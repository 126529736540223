import { Component, ElementRef, ViewChild } from '@angular/core';
import { ISTable } from '../../../shared/models/inside-trades';
import { InsideTradesService } from '../../../shared/service/inside-trades.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { CompanyDetailsService } from '../../../shared/service/company-details.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FilterdataService } from '../../../shared/service/filterdata.service';
import * as $ from 'jquery';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { FdaCalenarService } from 'src/app/modules/shared/service/fda-calendar.service';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { SubscriptionService } from 'src/app/modules/shared/service/subscription.service';

@Component({
  selector: 'app-inside-trades',
  templateUrl: './inside-trades.component.html',
  styleUrls: ['./inside-trades.component.scss'],
})
export class InsideTradesComponent {
  @ViewChild('dataContainer') dataContainer!: ElementRef;
  isCollapsed: boolean = true;
  showButtons = true;
  varprice: string | undefined;
  ISTable: ISTable[] = [];
  searchQuery: string = '';
  displayStyleLo = 'none';
  isLoading = true;
  isFilterBtn = false;
  isFilterBtnResert = false;
  isFilterBtnSearch = false;
  BuySells = false;
  currentPage = 1;
  isFirstPageLoaded = false;
  isPropertyTrue!: boolean;
  loadData!: boolean;
  cssClass = '';
  selectedCompany: any;
  screeners: string = 'Screener';
  customizes: string = 'Customize';
  showAlls: string = 'Show All';
  myForm!: FormGroup;
  isCompanyDetailsTriggered: boolean = false;
  basePath: string = 'https://s3-symbol-logo.tradingview.com/';
  searchingOrFiltering: boolean = true;
  list!: any[];
  ticker = true;
  price = true;
  thirtydaypricechange = true;
  transactor_name = true;
  relationship = true;
  buy_sell = true;
  traded_share = true;
  traded_price = true;
  traded_value = true;
  traded_date = true;
  current_holding = true;
  form_4 = true;
  industry = true;
  modalDisplayStyle!: boolean;
  scrollend = true;
  socket = io(environment.socketUrl);
  resdata: any;
  select_all = true;
  filterActive = false;
  showPrice = false;
  showDate = false;
  showTransactionPrice = false;
  VarPrice = false;
  filterOpened = false;
  filterArray: any[] = [];
  dateEnd!: any;
  dateStart!: any;
  selectlist!: any[];
  Traded_shares = false;
  showloginWarning = false;
  toggle: boolean = true;
  selectedPriceRange: string | null = null;
  selectedTransactionPriceRange: string | null = null;
  selectedbuysellRange: string | null = null;
  selectedTradePriceRange: string | null = null;
  isDropdownOpen: { [key: string]: boolean } = {};
  selectedChangePriceRange: string | null = null;
  selectedDateRange: any = null;
  disableDeselect: boolean = false;
  searchValuequery: string = '';

  isCustomChangePriceRangeSelected: boolean = false;
  isCustomtrancationdateRangeSelected: boolean = false;
  isCustomRangeSelected: boolean = false;
  isCustomtrancationpriceRangeSelected: boolean = false;

  showpriceDropDown!: boolean;
  showchangepriceDropDown!: boolean;
  istransactiondateDropdownOpen!: boolean;
  isbuysellDropdownOpen!: boolean;
  istransactionpriceDropdownOpen!: boolean;

  minCustomValue: any;
  maxCustomValue: any;
  maxCustomChange: any;
  minCustomChange: any;
  minCustomDate: any;
  maxCustomeDate: any;
  minCustomTprice: any;
  maxCustomTprice: any;
  apitype = 'defualt';
  FilterObj: any;
  sortNumber = 1;
  sortKeyVal = '';
  arrowclass = 'fa-arrow-up';
  isIndustrydownOpen!: boolean;
  selectedIndustryRange: string | null = null;
  MedicalDeviceData!: any[];
  BiotechnologyData!: any[];
  DiagnosticsData!: any[];
  DrugData!: any[];
  totalDatalength = 0;
  selectedDataColor!: string;
  selectedtradepriceColor!: string;
  selectedchangeColor!: string;
  selectedmarketColor!: string;
  selectedTradeValueColor!: string;
  selectedDateColor!: string;
  selectedindustryColor!: string;
  selectedbuy_sellColor!: string;
  selectedTradeValue!: string;
  selectedValue: string | null = null;
  selectedTradeprice: string | null = null;
  selectedValueinchange: string | null = null;
  selectedValueinmarket_cap: string | null = null;
  selectedValueinTradeValue: string | null = null;
  selectedValueinindustry: string | null = null;
  selectedValueinbuy_sell: string | null = null;
  selectedValueinDate: string | null = null;
  isFilter = false;
  isSort = false;
  forResetfilters = false;
  arrowname: string = 'fa-arrow-up';
  arrowticker: string = 'fa-arrow-up';
  arrowprice: string = 'fa-arrow-up';
  arrowtraname: string = 'fa-arrow-up';
  arrowtshares: string = 'fa-arrow-up';
  arrowtprice: string = 'fa-arrow-up';
  arrowtvalue: string = 'fa-arrow-up';
  arrowtdate: string = 'fa-arrow-up';
  arrowcholdings: string = 'fa-arrow-up';
  suggection: any;
  portfoliomatchTicker: any[] = [];
  portfolioTicker: any;
  portfolioPrice: any;
  portfoliocost: number | undefined;
  portfolioquntity: number | undefined;
  portfoliototal: any;
  clientUrl = environment.clientUrl;
  minCustomTradeValue!: number;
  maxCustomTradeValue!: number;
  selectedTradeValueRange: string | null = null;
  lockedArray: any[] = [];
  btnLoading: any;
  productId: number | undefined;
  downloadExcelTooltipValue: string = '';
  userToken: any = localStorage.getItem('accessToken');
  credits: number | undefined = 0;

  nm: any = {
    TICKER: 'symbol',
    PRICE: 'closeprice',
    INDUSTRY: 'Industry',
    'TRANSACTOR NAME': 'name',
    'TRADED SHARES': 'change',
    // 'BUY/SALE': 'buy_sell',
    'TRADE PRICE': 'transactionPrice',
    'TRADE VALUE': 'traded_value',
    'TRADE DATE': 'transactionDate',
    'CURRENT HOLDINGS': 'share',
    'SEC SUBMISSION': 'form_4',
    CHANGE: 'varprice',
    NAME: 'companyname',
    'BUY/SELL': 'buy_sell',
  };
  constructor(
    private Insideservice: InsideTradesService,
    private formBuilder: FormBuilder,
    private compamyDetails: CompanyDetailsService,
    private toaster: ToastrService,
    private router: Router,
    private filter: FilterdataService,
    private fdacalendarservice: FdaCalenarService,
    private decryptionService: DecryptionService,
    private subscriptionService: SubscriptionService
  ) {
    this.list = [
      { name: 'TICKER', checked: true },
      { name: 'PRICE', checked: true },
      { name: '30 DAYS PRICE CHANGE', checked: true },
      { name: 'TRANSACTOR NAME', checked: true },
      // { name: 'BUY/SELL', checked: true },
      { name: 'TRADED SHARES', checked: true },
      { name: 'TRADE PRICE', checked: true },
      { name: 'TRADE VALUE', checked: true },
      { name: 'TRADE DATE', checked: true },
      { name: 'CURRENT HOLDINGS', checked: true },
      { name: 'INDUSTRY', checked: true },
      { name: 'SEC SUBMISSION', checked: true },
    ];

    this.BiotechnologyData = [
      'Biotechnology',
      'Tobacco',
      'Real Estate Services',
      'Chemicals',
      ' N/A',
      'Farm Products',
      'Food Distribution',
      'Packaged Foods',
    ];

    this.MedicalDeviceData = [
      'Medical Devices',
      'Medical Instruments & Supplies',
      'Electronic Components',
      'Scientific & Technical Instruments',
      'Health Information Services',
      'Medical Care Facilities',
      'Furnishings',
      'Fixtures & Appliances',
      'Medical Distribution',
    ];

    this.DiagnosticsData = ['Diagnostics & Research'];

    this.DrugData = [
      'Drug Manufacturers—General',
      'Drug Manufacturers—Specialty & Generic',
      'Household & Personal Products,Pharmaceutical Retailers',
    ];
  }

  ngOnInit() {
    this.myForm = this.formBuilder.group({
      addform: this.formBuilder.array([]),
    });

    this.isTableData();
    this.ISTableScoket();
    this.returnLogin();
  }

  sde() {
    let sl = [
      'companyname',
      'varprice',
      'buy_sell',
      ...this.list
        .filter((item) => item.checked)
        .map((item) => this.nm[item.name] || item.name.toLowerCase()),
    ];

    function formatValue(value: any) {
      const isNegative = value < 0;
      const av = Math.abs(value);
      let fv: any;
      if (av >= 1_000_000_000) {
        fv = (av / 1_000_000_000).toFixed(2) + ' B';
      } else if (av >= 1_000_000) {
        fv = (av / 1_000_000).toFixed(2) + ' M';
      } else if (av >= 1_000) {
        fv = (av / 1_000).toFixed(2) + ' K';
      } else {
        fv = av || '-';
      }
      return isNegative ? `-${fv}` : fv;
    }

    function formatValue2(value: any) {
      if (
        (typeof value === 'number' || Number.isFinite(value)) &&
        value !== 0
      ) {
        return `${parseFloat(value.toFixed(2))} %`;
      }
      return '-';
    }

    return () => {
      return this.ISTable.map((item: any) =>
        sl.reduce((acc: any, key: any) => {
          const mappedKey =
            Object.keys(this.nm).find((k) => this.nm[k] === key) || key;
          if (key in item) {
            let value = item[key];

            if (key === 'change' || key === 'traded_value' || key === 'share')
              value = formatValue(value);

            if (key === 'varprice') value = formatValue2(value);

            if (key === 'name') value = `${item.name} (${item.relationship})`;

            acc[mappedKey] = value;
          }
          return acc;
        }, {})
      );
    };
  }

  exportToExcel() {
    this.btnLoading = true;

    if(this.userToken && this.productId) {
      const dataLength = {
        datalength: this.ISTable.length,
      }
      this.subscriptionService.checkAvailableCredits(dataLength).subscribe({
        next: (res: any) => {
          this.setExportToExcel();
          this.toaster.success("Excel file downloaded successfully");
          this.btnLoading = false;
          this.credits = this.credits! - this.ISTable.length;
          this.downloadExcelTooltipValue = this.credits > 0 ? 'Available credits: ' + this.credits : 'No credits available';
        },
        error: (error) => {
          this.toaster.error(error.error.message);
          this.btnLoading = false;
        }
      })
    } else if (this.userToken && !this.productId) {
      this.router.navigate(['subscription']);
    }
    else {
      this.router.navigate(['login']);
    }
  }

  setExportToExcel() {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

    const metadata = [
      ['Title', 'Inside Trades Records'],
      [
        'Description',
        'This report provides a detailed listing of Inside Trades records, encompassing critical information about trade activities conducted by insiders within the organization.',
      ],
      ['Source', 'Biopharmawatch.com'],
      ['URL', 'https://biopharmawatch.com/inside-trades'],
      ['Generated on', new Date().toLocaleDateString()],
      [],
    ];

    XLSX.utils.sheet_add_aoa(worksheet, metadata, { origin: 'A1' });

    XLSX.utils.sheet_add_json(worksheet, this.sde()(), {
      origin: 'A8',
      skipHeader: false,
    });

    const workbook: XLSX.WorkBook = {
      Sheets: { Data: worksheet },
      SheetNames: ['Data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, 'Inside_trades');
    setTimeout(() => {
      this.btnLoading = false;
    }, 700);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(data, `${fileName}${new Date()}.xlsx`);
  }

  scrollLeft() {
    this.dataContainer.nativeElement.scrollLeft -= 300;
  }

  scrollRight() {
    this.dataContainer.nativeElement.scrollLeft += 300;
  }

  checkScrollable() {
    const container = this.dataContainer.nativeElement;
    if (container.scrollWidth > container.clientWidth) {
      this.showButtons = true;
    } else {
      this.showButtons = false;
    }
  }

  newForm(): FormGroup {
    return this.formBuilder.group({
      query: ['', Validators.required],
    });
  }

  shareCheckedList(item: any[]) {}

  shareIndividualCheckedList(item: {}) {
    let value = Object.values(item)[1];

    switch (value) {
      case 'TICKER':
        this.ticker = !this.ticker;
        break;
      case 'PRICE':
        this.price = !this.price;
        break;
      case '30 DAYS PRICE CHANGE':
        this.thirtydaypricechange = !this.thirtydaypricechange;
        break;
      case 'TRANSACTOR NAME':
        this.transactor_name = !this.transactor_name;
        break;
      case 'RELATIONSHIP':
        this.relationship = !this.relationship;
        break;
      case 'BUY/SELL':
        this.buy_sell = !this.buy_sell;
        break;
      case 'TRADED SHARES':
        this.traded_share = !this.traded_share;
        break;
      case 'TRADE DATE':
        this.traded_date = !this.traded_date;
        break;
      case 'TRADE PRICE':
        this.traded_price = !this.traded_price;
        break;
      case 'TRADE VALUE':
        this.traded_value = !this.traded_value;
        break;
      case 'CURRENT HOLDINGS':
        this.current_holding = !this.current_holding;
        break;
      case 'SEC SUBMISSION':
        this.form_4 = !this.form_4;
        break;
      case 'INDUSTRY':
        this.industry = !this.industry;
        break;
      default:
        break;
    }
  }

  closePopup() {
    this.displayStyleLo = 'none';
  }

  loginpage() {
    this.router.navigate(['login']);
  }

  returnLogin() {
    let loign = localStorage.getItem('loggedInUser');
    if (!loign) {
      this.showloginWarning = true;
    }
  }

  isTableData() {
    this.Insideservice.getInsideTable(this.currentPage).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.totalDatalength = ParseDecryptedData[0].total.count;

        ParseDecryptedData.find((x: any) => x.portfolio)?.portfolio.forEach(
          (element: any) => {
            this.portfoliomatchTicker.push(element.ticker);
          }
        );

        this.productId = ParseDecryptedData.find(
          (x: any) => x.productId
        )?.productId;

        this.credits = ParseDecryptedData.find(
          (x: any) => x.credits
        )?.credits;

        this.setDownloadExcelTooltip();

        this.isLoading = false;
        this.isFilterBtnResert = false;
        this.isFilterBtn = false;
        this.currentPage = 2;

        // for (let i = 0; i < 2; i++) {
        //   this.lockedArray.push(ParseDecryptedData[0].data);
        //   this.lockedArray = this.lockedArray.flat();
        // }

        // if (!this.productId) {
        //   ParseDecryptedData[0].data = this.lockedArray;
        // }

        ParseDecryptedData[0].data.forEach((data: any) => {
          if (data.transactionPrice === 0) {
            data.transactionPrice = 'Not Available';
          }
          this.ISTable.push(data);
        });

        setTimeout(() => {
          this.checkScrollable();
        });
      },
      error: (error: any) => {
        this.isLoading = false;
        this.isFilterBtn = false;
        this.isFilterBtnResert = false;
      },
    });
  }

  // onScroll(event: Event) {

  //   if (!this.searchingOrFiltering) {
  //     return;
  //   }

  //   const element = event.target as HTMLElement;
  //   const scrollPosition = element.scrollTop + element.clientHeight;
  //   const totalHeight = element.scrollHeight;

  //   if (scrollPosition >= totalHeight - 1) {
  //     if (localStorage.getItem('loggedInUser')) {
  //       this.loadData = true;
  //       if (this.resdata.length == 0) {
  //         this.loadData = false;
  //       }
  //       this.currentPage++;
  //       this.isTableData();
  //     }
  //     else {
  //       if (!this.scrollend) {
  //         this.loadData = false
  //         let href = this.router.url;
  //         localStorage.setItem("route", href)
  //         this.modalDisplayStyle = true;
  //       }
  //     }
  //   }
  // }

  GetChildData(data: any) {
    this.modalDisplayStyle = data;
  }

  companyDetails(ticker: any) {
    this.compamyDetails.getCompanyTable(ticker).subscribe({
      next: (res: any) => {
        this.router.navigate(['company', ticker]);
      },
    });
    this.compamyDetails.getCompanyTicker(ticker);
  }

  clearSearch() {
    this.searchQuery = '';
  }

  saveFilters() {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
    let filtered = this.filterArray.filter((el) => Object.keys(el).length);
    if (filtered.length <= 0) {
      this.toaster.warning('Enter Valid Inputs.');
      return;
    }
    this.isFilter = true;
    this.forResetfilters = true;

    var newObject = filtered.reduce((result, currentObject) => {
      for (var key in currentObject) {
        if (currentObject.hasOwnProperty(key)) {
          result[key] = currentObject[key];
        }
      }
      return result;
    }, {});

    this.apitype = 'filter';
    this.refresh();
    this.FilterObj = newObject;
    this.isLoading = true;
    this.isFilterBtn = true;
    this.filter.itfilterColum(this.FilterObj, this.currentPage).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.totalDatalength = ParseDecryptedData[0].total.count;

        this.isLoading = false;
        this.isFilterBtn = false;
        this.currentPage = 2;

        this.ISTable = ParseDecryptedData[0].data as ISTable[];
        this.searchingOrFiltering = false;
      },
      error: (err) => {
        this.isLoading = false;
        this.isFilterBtn = false;
        this.resetFilters();

        this.toaster.error(err.error.message);
      },
    });
  }
  loadMoreData() {
    this.isLoading = true;
    this.loadData = true;

    if (this.apitype == 'filter') {
      this.filter.itfilterColum(this.FilterObj, this.currentPage).subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);
          const ParseDecryptedData = JSON.parse(decryptData);
          ParseDecryptedData[0].data.forEach((element: any) => {
            this.ISTable.push(element);
          });
          this.isLoading = false;
          this.loadData = false;
          this.currentPage++;
        },
        error: (err) => {
          this.loadData = false;
          this.isLoading = true;
          this.toaster.warning('All Record displayed');
          this.apitype = 'defualt';
        },
      });
    }

    if (this.apitype == 'defualt') {
      this.Insideservice.getInsideTable(this.currentPage).subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);
          const ParseDecryptedData = JSON.parse(decryptData);
          // this.portfoliomatchTicker = res.portfolioSymbols;

          ParseDecryptedData[0].data.forEach((element: any) => {
            this.ISTable.push(element);
          });

          this.loadData = false;
          this.isLoading = false;
          this.currentPage++;
        },
        error: (err) => {
          this.loadData = false;
          this.isLoading = true;
          this.toaster.warning('All Record displayed');
          this.apitype = 'defualt';
        },
      });
    }

    if (this.apitype == 'sort') {
      this.filter
        .insidesort(this.sortKeyVal, this.sortNumber, this.currentPage)
        .subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res.result);
            const ParseDecryptedData = JSON.parse(decryptData);
            ParseDecryptedData[0].data.forEach((element: any) => {
              this.ISTable.push(element);
            });
            this.loadData = false;
            this.isLoading = false;
            this.currentPage++;
          },
          error: (err) => {
            this.loadData = false;
            this.isLoading = true;
            this.toaster.warning('All Record displayed');
            this.apitype = 'defualt';
          },
        });
    }

    if (this.isSort) {
      this.filter
        .itsortwithFilter(
          this.sortKeyVal,
          this.sortNumber,
          this.currentPage,
          this.FilterObj
        )
        .subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res.result);
            const ParseDecryptedData = JSON.parse(decryptData);
            this.totalDatalength = ParseDecryptedData[0].total.count;
            this.loadData = false;
            this.isLoading = false;
            this.currentPage++;
            ParseDecryptedData[0].data.forEach((element: any) => {
              this.ISTable.push(element);
            });
          },
          error: (err) => {
            this.loadData = false;
            this.isLoading = true;
            this.toaster.warning('All Record displayed');
            this.apitype = 'defualt';
          },
        });
    }
  }

  onScroll(event: any) {
    const element = event.target as HTMLElement;
    const scrollPosition = element.scrollTop + element.clientHeight;
    const totalHeight = element.scrollHeight;
    if (this.productId) {
      if (scrollPosition >= totalHeight - 1 && !this.isLoading) {
        if (this.currentPage != 1) {
          this.loadMoreData();
        }
      }
    } else {
      let href = this.router.url;
      localStorage.setItem('route', href);
      this.modalDisplayStyle = true;
    }
  }
  assending(sortKey: string) {
    if (!this.productId) {
      this.modalDisplayStyle = true;
    } else {
      this.apitype = 'sort';

      this.sortNumber = this.sortKeyVal === sortKey ? this.sortNumber * -1 : 1;
      this.sortKeyVal = sortKey;

      this.refresh();

      switch (sortKey) {
        case 'companyname':
          this.arrowname =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'symbol':
          this.arrowticker =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'closeprice':
          this.arrowprice =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'name':
          this.arrowtraname =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'change':
          this.arrowtshares =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'transactionPrice':
          this.arrowtprice =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'traded_value':
          this.arrowtvalue =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'transactionDate':
          this.arrowtdate =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'share':
          this.arrowcholdings =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        default:
          break;
      }

      if (this.isFilter === true) {
        this.apitype = 'filtersort';
        this.isSort = true;
        this.filter
          .itsortwithFilter(
            this.sortKeyVal,
            this.sortNumber,
            this.currentPage,
            this.FilterObj
          )
          .subscribe({
            next: (res: any) => {
              const decryptData = this.decryptionService.decrypt(res.result);
              const ParseDecryptedData = JSON.parse(decryptData);
              this.totalDatalength = ParseDecryptedData[0].total.count;

              ParseDecryptedData[0].data.forEach((element: any) => {
                this.ISTable.push(element);
                this.currentPage = 2;
              });
            },
            error: (err) => {
              this.isLoading = false;
              this.isFilterBtn = false;
              this.refresh();
              this.isTableData();
              this.toaster.error(err.error.message);
            },
          });
      } else {
        this.filter
          .insidesort(this.sortKeyVal, this.sortNumber, this.currentPage)
          .subscribe({
            next: (res: any) => {
              const decryptData = this.decryptionService.decrypt(res.result);
              const ParseDecryptedData = JSON.parse(decryptData);
              this.totalDatalength = ParseDecryptedData[0].total.count;

              ParseDecryptedData[0].data.forEach((element: any) => {
                this.ISTable.push(element);
                this.currentPage = 2;
              });
            },
            error: (err) => {
              this.isLoading = false;
              this.isFilterBtn = false;
              this.refresh();
              this.isTableData();
              this.toaster.error(err.error.message);
            },
          });
      }
    }
  }
  resetFilters() {
    this.searchingOrFiltering = true;
    this.isFilterBtnResert = true;
    this.apitype = 'defualt';
    this.isFilter = false;
    this.isSort = false;
    this.refresh();
    this.selectedata();
    const formArray = this.myForm.get('addform') as FormArray;
    formArray.clear();
    this.ISTable = [];
    this.isTableData();

    this.clearSelectedPrice();
    this.clearSelectedbuysell();
    this.clearSelectedChangePrice();
    this.clearSelectedDate();
    this.clearSelectedTransactionPrice();
    this.clearSelectedTradeValue();
    // if (this.forResetfilters) {
    //   this.clearSelectedPrice();
    //   this.clearSelectedbuysell();
    //   this.clearSelectedChangePrice();
    //   this.clearSelectedDate();
    //   this.clearSelectedTransactionPrice();
    //   this.clearSelectedTradeValue();
    // }
  }

  refresh() {
    this.ISTable = [];
    this.lockedArray = [];
    this.loadData = false;
    this.isLoading = false;
    this.currentPage = 1;
    this.arrowname = 'fa-arrow-up';
    this.arrowticker = 'fa-arrow-up';
    this.arrowprice = 'fa-arrow-up';
    this.arrowtraname = 'fa-arrow-up';
    this.arrowtshares = 'fa-arrow-up';
    this.arrowtprice = 'fa-arrow-up';
    this.arrowtvalue = 'fa-arrow-up';
    this.arrowtdate = 'fa-arrow-up';
    this.arrowcholdings = 'fa-arrow-up';
  }

  selectedata() {
    this.myForm.get('screener')?.setValue(this.screeners);
    this.myForm.get('customize')?.setValue(this.customizes);
    this.myForm.get('showAll')?.setValue(this.showAlls);
  }

  ISTableScoket() {
    this.socket.on('tblcompaniesScoket', (res: any) => {
      if (res.operationType == 'update') {
        var Data = {
          ...res.documentKey,
          ...res.updateDescription.updatedFields,
        };
        this.ISTable.filter((IStableObj: any) => {
          if (IStableObj.symbol === Data.symbol) {
            IStableObj.closeprice = Data.close
              ? Data.close
              : IStableObj.closeprice;
          }
          if (IStableObj.symbol === Data.symbol) {
            IStableObj.varprice = Data.change
              ? Data.change
              : IStableObj.varprice;
          }
        });
      } else {
      }
    });
    this.socket.connect();
  }

  Select_All_checkbox() {
    this.toggle = !this.toggle;
    if (this.toggle) {
      this.ticker = true;
      this.list.forEach((item) => (item.checked = true));
      this.price = true;
      this.thirtydaypricechange = true;
      this.industry = true;
      this.traded_date = true;
      this.traded_price = true;
      this.traded_value = true;
      this.traded_share = true;
      this.transactor_name = true;
      this.relationship = true;
      this.buy_sell = true;
      this.current_holding = true;
      this.form_4 = true;
    } else {
      this.ticker = false;
      this.list.forEach((item) => (item.checked = false));
      this.price = false;
      this.thirtydaypricechange = false;
      this.industry = false;
      this.traded_date = false;
      this.traded_price = false;
      this.traded_value = false;
      this.traded_share = false;
      this.transactor_name = false;
      this.relationship = false;
      this.buy_sell = false;
      this.current_holding = false;
      this.form_4 = false;
    }
  }

  // pushFilterDateStart(data: any) {

  //   this.filterArray.push({ start_transactionDate: data.target.value })
  // }
  // pushFilterDateEnd(data: any) {

  //   this.filterArray.push({ end_transactionDate: data.target.value })
  // }

  pushFilterTraded_shares(data: any) {
    this.filterArray.push({ start_traded_shares: Number(data.target.value) });
  }

  EndpushFilterTraded_shares(data: any) {
    this.filterArray.push({ end_traded_shares: Number(data.target.value) });
  }

  popFilterPrice() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].startPrice;
      delete this.filterArray[key].endPrice;
    }
    this.showPrice = false;
  }
  popFilterTransactionPrice() {
    this.showTransactionPrice = false;
  }
  popFilterVarPrice() {
    this.VarPrice = false;
  }
  popFilterTraded_shares() {
    this.Traded_shares = false;
  }

  openFilter() {
    this.filterOpened = true;
  }

  selectedFilter(val: any) {
    if (val.target.value == 'Date') {
      this.showDate = true;
    }
    if (val.target.value == 'Price') {
      this.showPrice = true;
    }
    if (val.target.value == 'TransactionPrice') {
      this.showTransactionPrice = true;
    }
    if (val.target.value == 'VarPrice') {
      this.VarPrice = true;
    }
    if (val.target.value == 'BuySell') {
      this.BuySells = true;
    }
    if (val.target.value == 'Traded_shares') {
      this.Traded_shares = true;
    }
    this.filterOpened = false;
  }
  popFilterStage() {
    this.BuySells = false;
  }

  iconchange(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  selectPriceRange(selectedRange: any) {
    if (selectedRange === 'customRange') {
      this.selectedPriceRange = selectedRange;
      this.isCustomRangeSelected = true;

      this.showpriceDropDown = false;
      this.isDropdownOpen['customRange'] = true;
    } else {
      if (this.selectedPriceRange === selectedRange) {
        this.selectedPriceRange = null;
      } else {
        this.selectedPriceRange = selectedRange;
      }
      this.isCustomRangeSelected = false;
    }
  }
  updateCustomTradePriceRange() {
    if (this.minCustomTprice && this.maxCustomTprice) {
      this.selectedTradePriceRange = `${this.minCustomTprice}$ to ${this.maxCustomTprice}$`;
      this.selectedtradepriceColor = 'selected-button';
    } else {
      this.selectedTradePriceRange = null;
      this.selectedtradepriceColor = '';
    }
  }
  pushFilterminPriceless(data: any) {
    this.filterArray.push({ close: 'less_than_1' });
    this.selectPriceRange(data);
  }

  pushFilterminPrice1t02(data: any) {
    this.filterArray.push({ startPrice: 1 }, { endPrice: 2 });
    this.selectPriceRange(data);
  }

  pushFilterminPrice2t05(data: any) {
    this.filterArray.push({ startPrice: 2 }, { endPrice: 5 });
    this.selectPriceRange(data);
  }
  pushFilterminPrice5t50(data: any) {
    this.filterArray.push({ startPrice: 5 }, { endPrice: 50 });
    this.selectPriceRange(data);
  }
  pushFilterminPrice50t100(data: any) {
    this.filterArray.push({ startPrice: 50 }, { endPrice: 100 });
    this.selectPriceRange(data);
  }

  isSelected(selectedRange: any) {
    return this.selectedPriceRange === selectedRange;
  }

  TransactionPriceSelected(selectedRange: any) {
    return this.selectedTransactionPriceRange === selectedRange;
  }
  buysellSelected(selectedRange: any) {
    return this.selectedbuysellRange === selectedRange;
  }

  toggleDropdown(key: string): void {
    this.isDropdownOpen[key] = !this.isDropdownOpen[key];
  }

  //pricechnagefilter method

  pushFilterminChangePriceless(data: any) {
    this.filterArray.push({ varprice: 'less_than_1' });
    this.selectChangePriceRange(data);
  }

  pushFilterminChangePrice1t02(data: any) {
    this.filterArray.push({ start_varprice: 1 }, { end_varprice: 2 });
    this.selectChangePriceRange(data);
  }

  pushFilterminChangePrice2t05(data: any) {
    this.filterArray.push({ start_varprice: 2 }, { end_varprice: 5 });
    this.selectChangePriceRange(data);
  }
  pushFilterminChangePrice5t50(data: any) {
    this.filterArray.push({ start_varprice: 5 }, { end_varprice: 50 });
    this.selectChangePriceRange(data);
  }
  pushFilterminChangePrice50t100(data: any) {
    this.filterArray.push({ start_varprice: 50 }, { end_varprice: 100 });
    this.selectChangePriceRange(data);
  }

  pushFiltervarPrice(data: any) {
    this.filterArray.push({ start_varprice: Number(data.target.value) });
  }
  EndpushFiltervarPrice(data: any) {
    this.filterArray.push({ end_varprice: Number(data.target.value) });
  }

  updateCustomPriceRange() {
    if (this.minCustomValue && this.maxCustomValue) {
      this.selectedPriceRange = `${this.minCustomValue}$ to ${this.maxCustomValue}$`;
      this.selectedDataColor = 'selected-button';
    } else {
      this.selectedPriceRange = null;
      this.selectedDataColor = '';
    }
  }

  updateChangePriceCustomRange() {
    if (this.minCustomChange && this.maxCustomChange) {
      this.selectedChangePriceRange = `${this.minCustomChange}% to ${this.maxCustomChange}%`;
      this.selectedchangeColor = 'selected-button';
    } else {
      this.selectedChangePriceRange = null;
      this.selectedchangeColor = '';
    }
  }

  ChangeSelected(selectedRange: any) {
    return this.selectedChangePriceRange === selectedRange;
  }

  selectChangePriceRange(selectedRange: string) {
    if (selectedRange === 'customRangechange') {
      this.selectedChangePriceRange = selectedRange;
      this.isCustomChangePriceRangeSelected = true;

      this.showchangepriceDropDown = false;
      this.isDropdownOpen['customRangechange'] = true;
    } else {
      if (this.selectedChangePriceRange === selectedRange) {
        this.selectedChangePriceRange = null;
      } else {
        this.selectedChangePriceRange = selectedRange;
      }
    }
  }

  //date filter

  Datefilterthisweek(data: any) {
    this.filterArray.push({ week: 'thisWeek' });
    this.selectDateRange(data);
  }

  Datefilterthismonth(data: any) {
    this.filterArray.push({ month: 'thisMonth' });
    this.selectDateRange(data);
  }

  Datefilternextweek(data: any) {
    this.filterArray.push({ week: 'nextWeek' });
    this.selectDateRange(data);
  }
  Datefilternextmonth(data: any) {
    this.filterArray.push({ month: 'nextMonth' });
    this.selectDateRange(data);
  }

  selectDateRange(selectedRange: string) {
    if (selectedRange === 'customRangeDate') {
      this.selectedDateRange = selectedRange;
      this.isCustomtrancationdateRangeSelected = true;

      this.istransactiondateDropdownOpen = false;
      this.isDropdownOpen['customRangeDate'] = true;
    } else {
      if (this.selectedDateRange === selectedRange) {
        this.selectedDateRange = null;
      } else {
        this.selectedDateRange = selectedRange;
      }
    }
  }

  updateDateCustomRange() {
    if (this.minCustomDate !== undefined && this.maxCustomeDate !== undefined) {
      const dateRangeFilter = {
        start_transactionDate: this.minCustomDate,
        end_transactionDate: this.maxCustomeDate,
      };
      this.filterArray.push(dateRangeFilter);
      this.selectedDateRange = `${this.minCustomDate} to ${this.maxCustomeDate}`;
    }
  }

  DateSelected(selectedRange: any) {
    return this.selectedDateRange === selectedRange;
  }

  transactionPriceless(data: any) {
    this.filterArray.push({ transactionPrice: 'less_than_1' });
    this.selectTransactionPriceRange(data);
  }

  transactionPrice1t02(data: any) {
    this.filterArray.push(
      { start_transactionPrice: 1 },
      { end_transactionPrice: 2 }
    );
    this.selectTransactionPriceRange(data);
  }

  transactionPrice2t05(data: any) {
    this.filterArray.push(
      { start_transactionPrice: 2 },
      { end_transactionPrice: 5 }
    );
    this.selectTransactionPriceRange(data);
  }
  transactionPrice5t50(data: any) {
    this.filterArray.push(
      { start_transactionPrice: 5 },
      { end_transactionPrice: 50 }
    );
    this.selectTransactionPriceRange(data);
  }
  transactionPrice50t100(data: any) {
    this.filterArray.push(
      { start_transactionPrice: 50 },
      { end_transactionPrice: 100 }
    );
    this.selectTransactionPriceRange(data);
  }

  selectTransactionPriceRange(selectedRange: string) {
    if (selectedRange === 'customRangeTransaction') {
      this.selectedTransactionPriceRange = selectedRange;
      this.isCustomtrancationpriceRangeSelected = true;

      this.istransactionpriceDropdownOpen = false;
      this.isDropdownOpen['customRangeTransaction'] = true;
    } else {
      if (this.selectedTransactionPriceRange === selectedRange) {
        this.selectedTransactionPriceRange = null;
      } else {
        this.selectedTransactionPriceRange = selectedRange;
      }
    }
  }

  updateTransactionCustomRange() {
    if (
      this.minCustomValue !== undefined &&
      this.maxCustomValue !== undefined
    ) {
      this.selectedTransactionPriceRange = `${this.minCustomValue}$ to ${this.maxCustomValue}$`;
    }
  }

  //buy_sell

  pushFilterbuySell(data: any) {
    this.filterArray.push({ buy_sell: data.target.value });
  }
  pushFilterbuy(data: any) {
    this.filterArray.push({ buy_sell: 'buy' });
    this.selectbuysellRange(data);
  }
  pushFilterSell(data: any) {
    this.filterArray.push({ buy_sell: 'sell' });
    this.selectbuysellRange(data);
  }

  selectbuysellRange(selectedRange: string) {
    if (this.selectedbuysellRange === selectedRange) {
      this.selectedbuysellRange = null;
    } else {
      this.selectedbuysellRange = selectedRange;
    }
  }

  pricefilterclick() {
    if (this.isDropdownOpen['customRange'] === true) {
      this.showpriceDropDown = true;
      this.isCustomRangeSelected = false;
    } else {
      this.showpriceDropDown = true;
    }
    this.isCustomtrancationdateRangeSelected = false;
    this.isCustomChangePriceRangeSelected = false;
    this.isCustomtrancationpriceRangeSelected = false;
  }

  changefilterclick() {
    if (this.isDropdownOpen['customRangechange'] === true) {
      this.showchangepriceDropDown = true;
      this.isCustomChangePriceRangeSelected = false;
    } else {
      this.showchangepriceDropDown = true;
    }
    this.isCustomtrancationdateRangeSelected = false;
    this.isCustomRangeSelected = false;
    this.isCustomtrancationpriceRangeSelected = false;
  }

  tranpricefilterclick() {
    if (this.isDropdownOpen['customRangeTransaction'] === true) {
      this.istransactionpriceDropdownOpen = true;
      this.isCustomtrancationpriceRangeSelected = false;
    } else {
      this.istransactionpriceDropdownOpen = true;
    }
    this.isCustomtrancationdateRangeSelected = false;
    this.isCustomRangeSelected = false;
    this.isCustomChangePriceRangeSelected = false;
  }

  trandatefilterclick() {
    if (this.isDropdownOpen['customRangeDate'] === true) {
      this.istransactiondateDropdownOpen = true;
      this.isCustomtrancationdateRangeSelected = false;
    } else {
      this.istransactiondateDropdownOpen = true;
    }
    this.isCustomRangeSelected = false;
    this.isCustomChangePriceRangeSelected = false;
    this.isCustomtrancationpriceRangeSelected = false;
  }

  buysellclick() {
    this.isbuysellDropdownOpen = true;
    this.isCustomRangeSelected = false;
    this.isCustomChangePriceRangeSelected = false;
    this.isCustomtrancationpriceRangeSelected = false;
    this.isCustomtrancationdateRangeSelected = false;
  }

  Industryfilterclick() {
    this.isIndustrydownOpen = true;
    // this.isMarketcapDropdownOpen = false;
    // this.isCustomDateRangeSelected = false;
    this.isCustomRangeSelected = false;
    this.isCustomChangePriceRangeSelected = false;
    // this.isstageDropdownOpen = false;
  }

  IndustrySelected(selectedRange: any) {
    return this.selectedIndustryRange === selectedRange;
  }

  selectIndustryRange(selectedRange: string) {
    if (this.selectedIndustryRange === selectedRange) {
      this.selectedIndustryRange = null;
    } else {
      this.selectedIndustryRange = selectedRange;
    }
  }
  Biotechnology(data: any) {
    this.filterArray.push({ Industry: this.BiotechnologyData });
    this.selectIndustryRange(data);
  }

  MedicalDevice(data: any) {
    this.filterArray.push({ Industry: this.MedicalDeviceData });
    this.selectIndustryRange(data);
  }

  Diagnostics(data: any) {
    this.filterArray.push({ Industry: this.DiagnosticsData });
    this.selectIndustryRange(data);
  }
  DrugManufacturers(data: any) {
    this.filterArray.push({ Industry: this.DrugData });
    this.selectIndustryRange(data);
  }

  selectedData(data: string) {
    switch (data) {
      case 'less_than_1 $': {
        this.selectedPriceRange = null;
        this.selectedValue = 'less_than_1 $';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ close: 'less_than_1' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].startPrice;
          delete this.filterArray[key].endPrice;
        }
        this.saveFilters();
        break;
      }
      case '1$ to 2$': {
        this.selectedPriceRange = null;
        this.selectedValue = '1$ to 2$';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ startPrice: 1 }, { endPrice: 2 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].close;
        }
        this.saveFilters();
        break;
      }
      case '2$ to 5$': {
        this.selectedPriceRange = null;
        this.selectedValue = '2$ to 5$';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ startPrice: 2 }, { endPrice: 5 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].close;
        }
        this.saveFilters();
        break;
      }
      case '5$ to 50$': {
        this.selectedPriceRange = null;
        this.selectedValue = '5$ to 50$';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ startPrice: 5 }, { endPrice: 50 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].close;
        }
        this.saveFilters();
        break;
      }
      case '50$ to 100$': {
        this.selectedPriceRange = null;
        this.selectedValue = '50$ to 100$';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ startPrice: 50 }, { endPrice: 100 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].close;
        }
        this.saveFilters();
        break;
      }
      case 'less_than_1 $ v': {
        this.selectedTradePriceRange = null;
        this.selectedTradeprice = 'less_than_1 $ v';
        this.selectedtradepriceColor = 'selected-button';
        this.filterArray.push({ transactionPrice: 'less_than_1' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_transactionPrice;
          delete this.filterArray[key].end_transactionPrice;
        }
        this.saveFilters();
        break;
      }
      case '1$ to 2$ v': {
        this.selectedTradePriceRange = null;
        this.selectedTradeprice = '1$ to 2$ v';
        this.selectedtradepriceColor = 'selected-button';
        this.filterArray.push(
          { start_transactionPrice: 1 },
          { end_transactionPrice: 2 }
        );
        for (let key in this.filterArray) {
          delete this.filterArray[key].transactionPrice;
        }
        this.saveFilters();
        break;
      }
      case '2$ to 5$ v': {
        this.selectedTradePriceRange = null;
        this.selectedTradeprice = '2$ to 5$ v';
        this.selectedtradepriceColor = 'selected-button';
        this.filterArray.push(
          { start_transactionPrice: 2 },
          { end_transactionPrice: 5 }
        );
        for (let key in this.filterArray) {
          delete this.filterArray[key].transactionPrice;
        }
        this.saveFilters();
        break;
      }
      case '5$ to 50$ v': {
        this.selectedTradePriceRange = null;
        this.selectedTradeprice = '5$ to 50$ v';
        this.selectedtradepriceColor = 'selected-button';
        this.filterArray.push(
          { start_transactionPrice: 5 },
          { end_transactionPrice: 50 }
        );
        for (let key in this.filterArray) {
          delete this.filterArray[key].transactionPrice;
        }
        this.saveFilters();
        break;
      }
      case '50$ to 100$ v': {
        this.selectedTradePriceRange = null;
        this.selectedTradeprice = '50$ to 100$ v';
        this.selectedtradepriceColor = 'selected-button';
        this.filterArray.push(
          { start_transactionPrice: 50 },
          { end_transactionPrice: 100 }
        );
        for (let key in this.filterArray) {
          delete this.filterArray[key].transactionPrice;
        }
        this.saveFilters();
        break;
      }
      case 'less_than_1 %': {
        this.selectedChangePriceRange = null;
        this.selectedValueinchange = 'less_than_1 %';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ varprice: 'less_than_1' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_varprice;
          delete this.filterArray[key].end_varprice;
        }
        this.saveFilters();
        break;
      }
      case '1% to 2%': {
        this.selectedChangePriceRange = null;
        this.selectedValueinchange = '1% to 2%';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ start_varprice: 1 }, { end_varprice: 2 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].varprice;
        }
        this.saveFilters();
        break;
      }
      case '2% to 5%': {
        this.selectedChangePriceRange = null;
        this.selectedValueinchange = '2% to 5%';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ start_varprice: 2 }, { end_varprice: 5 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].varprice;
        }
        this.saveFilters();
        break;
      }
      case '5% to 10%': {
        this.selectedChangePriceRange = null;
        this.selectedValueinchange = '5% to 10%';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ start_varprice: 5 }, { end_varprice: 10 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].varprice;
        }
        this.saveFilters();
        break;
      }
      case '10% to 50%': {
        this.selectedChangePriceRange = null;
        this.selectedValueinchange = '10% to 50%';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ start_varprice: 10 }, { end_varprice: 50 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].varprice;
        }
        this.saveFilters();
        break;
      }
      // case 'This Week': {
      //   this.selectedValueinDate = "This Week"
      //   this.selectedDateColor = "selected-button"
      //   this.filterArray.push({ week: "thisWeek" })
      //   for (let key in this.filterArray) {
      //     delete this.filterArray[key].month;
      //   }
      //   break
      // }
      // case 'This Month': {
      //   this.selectedValueinDate = "This Month"
      //   this.selectedDateColor = "selected-button"
      //   this.filterArray.push({ month: "thisMonth" })
      //   for (let key in this.filterArray) {
      //     delete this.filterArray[key].week;
      //   }
      //   break
      // }
      // case 'Next Month': {
      //   this.selectedValueinDate = "Next Month"
      //   this.selectedDateColor = "selected-button"
      //   this.filterArray.push({ month: "nextMonth" })
      //   for (let key in this.filterArray) {
      //     delete this.filterArray[key].week;
      //   }
      //   break
      // }
      // case 'Next Week': {
      //   this.selectedValueinDate = "Next Week"
      //   this.selectedDateColor = "selected-button"
      //   this.filterArray.push({ month: "nextWeek" })
      //   for (let key in this.filterArray) {
      //     delete this.filterArray[key].week;
      //   }
      //   break
      // }
      case '200b_or_more': {
        this.selectedValueinmarket_cap = '200b_or_more';
        this.selectedmarketColor = 'selected-button';
        this.filterArray.push({ market_cap: '200b_or_more' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_market_cap;
          delete this.filterArray[key].end_market_cap;
        }
        this.saveFilters();
        break;
      }
      case '10b_to_200b': {
        this.selectedValueinmarket_cap = '10b_to_200b';
        this.selectedmarketColor = 'selected-button';
        this.filterArray.push({ market_cap: '10b_to_200b' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_market_cap;
          delete this.filterArray[key].end_market_cap;
        }
        this.saveFilters();
        break;
      }
      case '2b_to_10b': {
        this.selectedValueinmarket_cap = '2b_to_10b';
        this.selectedmarketColor = 'selected-button';
        this.filterArray.push({ market_cap: '2b_to_10b' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_market_cap;
          delete this.filterArray[key].end_market_cap;
        }
        this.saveFilters();
        break;
      }
      case '250m_to_2b': {
        this.selectedValueinmarket_cap = '250m_to_2b';
        this.selectedmarketColor = 'selected-button';
        this.filterArray.push({ market_cap: '250m_to_2b' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_market_cap;
          delete this.filterArray[key].end_market_cap;
        }
        this.saveFilters();
        break;
      }

      case '250m_or_less': {
        this.selectedValueinmarket_cap = '250m_or_less';
        this.selectedmarketColor = 'selected-button';
        this.filterArray.push({ market_cap: '250m_or_less' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_market_cap;
          delete this.filterArray[key].end_market_cap;
        }
        this.saveFilters();
        break;
      }

      // trade value filter

      case 'less_than_100k': {
        this.selectedTradeValueRange = null;
        this.selectedValueinTradeValue = 'less_than_100k';
        this.selectedTradeValueColor = 'selected-button';
        this.filterArray.push({ traded_value: 'less_than_100k' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_traded_value;
          delete this.filterArray[key].end_traded_value;
        }
        this.saveFilters();
        break;
      }

      case '10m_or_more': {
        this.selectedTradeValueRange = null;
        this.selectedValueinTradeValue = '10m_or_more';
        this.selectedTradeValueColor = 'selected-button';
        this.filterArray.push({ traded_value: '10m_or_more' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_traded_value;
          delete this.filterArray[key].end_traded_value;
        }
        this.saveFilters();
        break;
      }

      case '1m_to_10m': {
        this.selectedTradeValueRange = null;
        this.selectedValueinTradeValue = '1m_to_10m';
        this.selectedTradeValueColor = 'selected-button';
        this.filterArray.push({ traded_value: '1m_to_10m' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_traded_value;
          delete this.filterArray[key].end_traded_value;
        }
        this.saveFilters();
        break;
      }

      case '100k_to_1m': {
        this.selectedTradeValueRange = null;
        this.selectedValueinTradeValue = '100k_to_1m';
        this.selectedTradeValueColor = 'selected-button';
        this.filterArray.push({ traded_value: '100k_to_1m' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_traded_value;
          delete this.filterArray[key].end_traded_value;
        }
        this.saveFilters();
        break;
      }

      // trade type filter

      case 'buy': {
        this.selectedValueinbuy_sell = 'buy';
        this.selectedbuy_sellColor = 'selected-button';
        this.filterArray.push({ buy_sell: 'buy' });
        this.saveFilters();
        break;
      }
      case 'sell': {
        this.selectedValueinbuy_sell = 'sell';
        this.selectedbuy_sellColor = 'selected-button';
        this.filterArray.push({ buy_sell: 'sell' });
        this.saveFilters();
        break;
      }
      case 'option': {
        this.selectedValueinbuy_sell = 'option';
        this.selectedbuy_sellColor = 'selected-button';
        this.filterArray.push({ buy_sell: 'option' });
        this.saveFilters();
        break;
      }
      case 'Biotechnology': {
        this.selectedindustryColor = 'selected-button';
        this.selectedValueinindustry = 'Biotechnology';
        const Biotechnology = [
          'Biotechnology',
          'Tobacco',
          'Real Estate Services',
          'Chemicals',
          ' N/A',
          'Farm Products',
          'Food Distribution',
          'Packaged Foods',
        ];
        this.filterArray.push({ Industry: Biotechnology });
        this.saveFilters();
        break;
      }
      case 'Medical Device': {
        this.selectedindustryColor = 'selected-button';
        this.selectedValueinindustry = 'Medical Device';
        const Medical_Device = [
          'Medical Devices',
          'Medical Instruments & Supplies',
          'Electronic Components',
          'Scientific & Technical Instruments',
          'Health Information Services',
          'Medical Care Facilities',
          'Furnishings',
          'Fixtures & Appliances',
          'Medical Distribution',
        ];
        this.filterArray.push({ Industry: Medical_Device });
        this.saveFilters();
        break;
      }
      case 'Diagnostics & Research': {
        this.selectedindustryColor = 'selected-button';
        this.selectedValueinindustry = 'Diagnostics & Research';
        const Diagnostics = ['Diagnostics & Research'];
        this.filterArray.push({ Industry: Diagnostics });
        this.saveFilters();
        break;
      }
      case 'Drug Manufacturers': {
        this.selectedindustryColor = 'selected-button';
        this.selectedValueinindustry = 'Drug Manufacturers';

        const Drug_Manufacturers = [
          'Drug Manufacturers—General',
          'Drug Manufacturers—Specialty & Generic',
          'Household & Personal Products,Pharmaceutical Retailers',
        ];
        this.filterArray.push({ Industry: Drug_Manufacturers });
        this.saveFilters();
        break;
      }
    }
  }

  clearSelectedPrice() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].close;
      delete this.filterArray[key].startPrice;
      delete this.filterArray[key].endPrice;
    }

    this.selectedPriceRange = null;
    this.maxCustomValue = 0;
    this.minCustomValue = 0;
    this.selectedDataColor = '';
    this.selectedValue = '';

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedbuysell() {
    this.selectedbuy_sellColor = '';
    this.selectedValueinbuy_sell = '';
    for (let key in this.filterArray) {
      delete this.filterArray[key].buy_sell;

      if (this.isFilter) {
        if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
          this.resetFilters();
        } else {
          this.saveFilters();
        }
      }
      // if (
      //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
      //   this.isFilter
      // ) {
      //   this.resetFilters();
      // }
    }
  }

  clearSelectedChangePrice() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].change;
      delete this.filterArray[key].start_varprice;
      delete this.filterArray[key].end_varprice;
    }

    this.selectedChangePriceRange = null;
    this.minCustomChange = 0;
    this.maxCustomChange = 0;
    this.selectedchangeColor = '';
    this.selectedValueinchange = '';

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedIndustry() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].Industry;
    }
    this.selectedindustryColor = '';
    this.selectedValueinindustry = '';
  }

  clearSelectedDate() {
    this.selectedDateColor = '';

    for (let key in this.filterArray) {
      delete this.filterArray[key].start_transactionDate;
      delete this.filterArray[key].end_transactionDate;
    }

    this.selectedDateRange = null;
    this.minCustomDate = 0;
    this.maxCustomeDate = 0;

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedTransactionPrice() {
    this.selectedtradepriceColor = '';
    this.selectedTradeprice = '';

    for (let key in this.filterArray) {
      delete this.filterArray[key].transactionPrice;
      delete this.filterArray[key].start_transactionPrice;
      delete this.filterArray[key].end_transactionPrice;
    }

    this.selectedTradePriceRange = null;
    this.minCustomTprice = 0;
    this.maxCustomTprice = 0;

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  pushFilterminPrice(data: any) {
    // this.selectedDataColor = 'selected-button';
    this.filterArray.push({ startPrice: Number(data.target.value) });
  }

  pushFiltermaxPrice(data: any) {
    // this.selectedDataColor = 'selected-button';
    this.filterArray.push({ endPrice: Number(data.target.value) });
  }

  pushFilterChange(data: any) {
    // this.selectedchangeColor = 'selected-button';
    this.filterArray.push({ start_varprice: Number(data.target.value) });
  }

  EndpushFilterChange(data: any) {
    // this.selectedchangeColor = 'selected-button';
    this.filterArray.push({ end_varprice: Number(data.target.value) });
  }

  pushFilterDateStart(data: any) {
    this.selectedDateColor = 'selected-button';
    this.filterArray.push({ start_transactionDate: this.minCustomDate });
  }

  pushFilterDateEnd(data: any) {
    this.selectedDateColor = 'selected-button';
    this.filterArray.push({ end_transactionDate: this.maxCustomeDate });
  }

  pushFilterTransactionPrice(data: any) {
    // this.selectedtradepriceColor = 'selected-button';
    this.filterArray.push({
      start_transactionPrice: Number(data.target.value),
    });
  }

  pushFilterEndTransactionPrice(data: any) {
    // this.selectedtradepriceColor = 'selected-button';
    this.filterArray.push({ end_transactionPrice: Number(data.target.value) });
  }

  searchFilters(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;

    if (event.key === 'Escape' && !inputElement.value) {
      this.suggection = [];
      return;
    }

    if (!inputElement.value || !this.searchValuequery) {
      this.suggection = [];
      return;
    }

    this.compamyDetails.searchsuggection(inputElement.value).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);

        const ParseDecryptedData = JSON.parse(decryptData);

        this.suggection = ParseDecryptedData;

        // this.symbol = res[0].symbol
      },
    });
  }

  onSearchInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;

    if (inputValue === '') {
      this.suggection = [];
    }
  }

  searchFiltersonclick(query: any) {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
    if (query == '') {
      this.toaster.warning('Enter Valid Inputs.');
      return;
    }

    let search = {
      query,
    };
    this.suggection = [];
    this.searchValuequery = '';

    // this.isLoading = true;
    this.isFilterBtnSearch = true;
    this.forResetfilters = true;
    // this.isFilter = true

    this.ISTable = [];

    this.filter.itfilterData(search).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);

        const ParseDecryptedData = JSON.parse(decryptData);

        this.isFilterBtnSearch = false;
        this.forResetfilters = false;
        this.totalDatalength = ParseDecryptedData[0].data.length;
        this.ISTable = ParseDecryptedData[0].data as ISTable[];
        this.isLoading = true;
      },
      error: (err) => {
        this.isFilterBtnSearch = false;
        this.forResetfilters = false;
        this.searchValuequery = '';
        this.ISTable = [];
        this.isTableData();
        this.toaster.error(err.error.message);
      },
    });
  }

  onAddSymbol(data: any) {
    this.portfoliomatchTicker.push(data.ticker);
    return;
  }

  addSymboltoPotfoliyo(symbol: any) {
    let loginuser = JSON.parse(localStorage.getItem('loggedInUser') || '{}');

    let obj = {
      email: loginuser.email,
      ticker: symbol,
    };

    if (loginuser.email) {
      this.portfoliomatchTicker = symbol;
      if (obj) {
        this.fdacalendarservice.addSymboltoPotfoliyo(obj).subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res);

            const ParseDecryptedData = JSON.parse(decryptData);
            this.portfoliomatchTicker = ParseDecryptedData.portfolio;
            this.toaster.success('Symbol added to your portfolio');
          },
        });
      }
    } else {
      this.modalDisplayStyle = true;
    }
  }

  removeSymboltoPotfoliyo(symbol: any) {
    let loginuser = JSON.parse(localStorage.getItem('loggedInUser') || '{}');

    let obj = {
      email: loginuser.email,
      ticker: symbol,
    };

    if (obj) {
      this.fdacalendarservice.removeSymboltoPotfoliyo(obj).subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res);

          const ParseDecryptedData = JSON.parse(decryptData);
          this.portfoliomatchTicker = ParseDecryptedData.portfolio;
          this.toaster.success('Symbol removed from your portfolio');
        },
      });
    }
  }

  updateTradeValueCustomRange() {
    if (this.minCustomTradeValue && this.maxCustomTradeValue) {
      this.selectedTradeValueRange = `$${this.minCustomTradeValue}m to $${this.maxCustomTradeValue}m`;
      this.selectedTradeValueColor = 'selected-button';
    } else {
      this.selectedTradeValueRange = null;
      this.selectedTradeValueColor = '';
    }
  }

  pushFilterTradeValue(data: any) {
    for (let key in this.filterArray) {
      delete this.filterArray[key].traded_value;
    }
    // this.selectedTradeValueColor = 'selected-button';
    this.filterArray.push({
      start_traded_value: Number(data.target.value) * 1000000,
    });
  }

  EndpushFilterTradeValue(data: any) {
    // this.selectedTradeValueColor = 'selected-button';
    this.filterArray.push({
      end_traded_value: Number(data.target.value) * 1000000,
    });
  }

  clearSelectedTradeValue() {
    for (let key in this.filterArray) {
      delete this.filterArray[key]?.traded_value;
      delete this.filterArray[key]?.end_traded_value;
      delete this.filterArray[key]?.start_traded_value;
    }

    this.selectedTradeValueRange = null;
    this.maxCustomTradeValue = 0;
    this.minCustomTradeValue = 0;
    this.selectedTradeValueColor = '';
    this.selectedValueinTradeValue = '';

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  setDownloadExcelTooltip(){
    if(this.userToken){
      if(this.credits! > 0){
        this.downloadExcelTooltipValue = 'Available Credits: ' + this.credits;
      } else {
        this.downloadExcelTooltipValue = 'No Credits Available';
      }
    } else {
      this.downloadExcelTooltipValue = 'Please signin to download Excel data';
    }
  }
}
