<section class="CatalystSync-head">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="catalyst-sync mt-3 mb-3">
          <h1 class="main-heading text-white m-0 me-3">CatalystSync™</h1>

          <p class="sub-heading text-center text-white m-0">
            CatalystSync™ is an integrated platform providing a panoramic view
            of clinical trials and pipelines, including phases, completion date,
            working capital, runway, and market cap, designed to streamline
            discovery and investment in the biotech sector. Please use our
            Filters function below to see the power of CatalystSync™
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="container-fluid form-box py-2">
  <div class="row justify-content-center align-items-center">
    <div class="col-lg-3 col-md-10 col-12">
      <form style="height: 30px" class="example d-flex add_m_infilter">
        <input
          [(ngModel)]="searchValuequery"
          autocomplete="off"
          (keyup)="searchFilters($event)"
          (input)="onSearchInput($event)"
          #searchVal
          type="search"
          class="input-box"
          placeholder="Search by Comapny name or Ticker"
          name="search2"
        />
        <button
          (click)="searchFiltersonclick(searchValuequery)"
          class="searchquerybtn"
          type="button"
        >
          @if (isFilterBtnSearch) {
          <i style="color: white" class="fa fa-spin fa-spinner"></i>

          }@else {
          <i class="fa-solid fa-magnifying-glass"></i>
          }
        </button>
      </form>
      @if (suggection?.length > 0) {
      <ul class="list-unstyled m-0">
        @for (suggestion of suggection; track $index) {
        <li class="suggestion-item p-0 cursorpointer">
          <div
            (click)="searchFiltersonclick(suggestion.symbol)"
            class="d-flex filter-bottom justify-content-between px-2"
          >
            <span>
              @if (suggestion.logoid !== '') {
              <img width="25px" [src]="basePath + suggestion.logoid + '.svg'" />
              }@else {
              <span
                class="avatarcss"
                style="
                  width: 25px !important;
                  height: 25px !important;
                  margin: 0 !important;
                "
                >{{ suggestion.name | initialLetter }}</span
              >
              }
            </span>
            <p class="text-start text-dark filter-font-text mb-1 border-1 me-4">
              {{ suggestion.name }}
            </p>
            <p class="text-end text-dark filter-font-text mb-1">
              {{ suggestion.symbol }}
            </p>
          </div>
        </li>
        }
      </ul>
      }
    </div>
    <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
      <div class="dropdown mx-2 d-flex justify-content-center">
        <button
          [ngClass]="selectedDataColor"
          class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
          #priceinput
          role="button"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
        >
          {{
            selectedPriceRange
              ? selectedPriceRange
              : selectedValue
              ? selectedValue
              : "Price"
          }}
          <i class="fa fa-caret-down ms-4"></i>
        </button>

        @if(selectedDataColor){
        <span class="" (click)="clearSelectedPrice()">
          <img
            class="m-1 mb-0 cursorpointer"
            src="assets/images/new-minus.svg"
            alt=""
          />
        </span>
        }

        <ul class="dropdown-menu games-menu">
          <li>
            <p
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('less_than_1 $')"
              [class.selected]="'less_than_1 $' === selectedValue"
            >
              Less than 1 $
            </p>
          </li>
          <li>
            <p
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('1$ to 2$')"
              [class.selected]="'1$ to 2$' === selectedValue"
            >
              1 $ to 2 $
            </p>
          </li>
          <li>
            <p
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('2$ to 5$')"
              [class.selected]="'2$ to 5$' === selectedValue"
            >
              2 $ to 5 $
            </p>
          </li>
          <li>
            <p
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('5$ to 50$')"
              [class.selected]="'5$ to 50$' === selectedValue"
            >
              5 $ to 50 $
            </p>
          </li>
          <li>
            <p
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('50$ to 100$')"
              [class.selected]="'50$ to 100$' === selectedValue"
            >
              50 $ to 100 $
            </p>
          </li>
          <li>
            <button
              class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
              data-bs-toggle="dropdown"
            >
              Customize
            </button>
            <ul class="dropdown-menu games-menu">
              <div class="p-1" aria-labelledby="shareHoldDropdown">
                <input
                  type="number"
                  class="custom-input-set mb-2"
                  placeholder="Min Value"
                  [(ngModel)]="minCustomValue"
                  (input)="updateCustomRange()"
                  (change)="pushFilterminPrice($event)"
                />
                <input
                  type="number"
                  class="custom-input-set"
                  placeholder="Max Value"
                  [(ngModel)]="maxCustomValue"
                  (input)="updateCustomRange()"
                  (change)="pushFiltermaxPrice($event)"
                />
              </div>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
      <div class="dropdown mx-2 d-flex justify-content-center">
        <button
          [ngClass]="selectedchangeColor"
          class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
        >
          <span>
            {{
              selectedChangePriceRange
                ? selectedChangePriceRange
                : selectedValueinchange
                ? selectedValueinchange
                : "Change in Price"
            }}
          </span>
          <i class="fa fa-caret-down ms-4"></i>
        </button>

        @if (selectedchangeColor) {
        <span class="" (click)="clearSelectedChangePrice()">
          <img
            class="m-1 mb-0 cursorpointer"
            src="assets/images/new-minus.svg"
            alt=""
          />
        </span>
        }

        <ul class="dropdown-menu">
          <li>
            <p
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('less_than_1 %')"
              [class.selected]="'less_than_1 %' === selectedValueinchange"
            >
              Less than 1 %
            </p>
          </li>
          <li>
            <p
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('1% to 2%')"
              [class.selected]="'1% to 2%' === selectedValueinchange"
            >
              1 % to 2 %
            </p>
          </li>
          <li>
            <p
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('2% to 5%')"
              [class.selected]="'2% to 5%' === selectedValueinchange"
            >
              2 % to 5 %
            </p>
          </li>
          <li>
            <p
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('5% to 10%')"
              [class.selected]="'5% to 10%' === selectedValueinchange"
            >
              5 % to 10 %
            </p>
          </li>
          <li>
            <p
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('10% to 50%')"
              [class.selected]="'10% to 50%' === selectedValueinchange"
            >
              10 % to 50 %
            </p>
          </li>
          <li>
            <button
              class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
              data-bs-toggle="dropdown"
            >
              Customize
            </button>
            <ul class="dropdown-menu">
              <div class="p-1" aria-labelledby="shareHoldDropdown">
                <input
                  type="number"
                  class="custom-input-set mb-2"
                  placeholder="Min Value"
                  [(ngModel)]="minCustomChange"
                  (input)="updateChangePriceCustomRange()"
                  (change)="pushFilterChange($event)"
                />
                <input
                  type="number"
                  class="custom-input-set"
                  placeholder="Max Value"
                  [(ngModel)]="maxCustomChange"
                  (input)="updateChangePriceCustomRange()"
                  (change)="EndpushFilterChange($event)"
                />
              </div>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
      <div class="dropdown mx-2 d-flex justify-content-center">
        <button
          [ngClass]="selectedmarketColor"
          class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
        >
          <span>
            {{
              selectedMarketCapRange
                ? selectedMarketCapRange
                : selectedValueinmarket_cap
                ? selectedValueinmarket_cap
                : "Market Cap"
            }}
          </span>
          <i class="fa fa-caret-down ms-4"></i>
        </button>

        @if (selectedmarketColor) {
        <span class="" (click)="clearSelectedMarketCap()">
          <img
            class="m-1 mb-0 cursorpointer"
            src="assets/images/new-minus.svg"
            alt=""
          />
        </span>
        }

        <ul class="dropdown-menu">
          <li>
            <p
              [class.selected]="'200b_or_more' === selectedValueinmarket_cap"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('200b_or_more')"
            >
              $ 200 billion or more
            </p>
          </li>
          <li>
            <p
              [class.selected]="'10b_to_200b' === selectedValueinmarket_cap"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('10b_to_200b')"
            >
              $ 10 billion to $ 200 billion
            </p>
          </li>
          <li>
            <p
              [class.selected]="'2b_to_10b' === selectedValueinmarket_cap"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('2b_to_10b')"
            >
              $ 2 billion to $ 10 billion
            </p>
          </li>
          <li>
            <p
              [class.selected]="'250m_to_2b' === selectedValueinmarket_cap"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('250m_to_2b')"
            >
              $ 250 million to $ 2 billion
            </p>
          </li>
          <li>
            <p
              [class.selected]="'250m_or_less' === selectedValueinmarket_cap"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('250m_or_less')"
            >
              Less than $ 250 million
            </p>
          </li>
          <li>
            <button
              class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
              data-bs-toggle="dropdown"
            >
              Customize
            </button>
            <ul class="dropdown-menu">
              <div class="p-1" aria-labelledby="shareHoldDropdown">
                <input
                  type="number"
                  class="custom-input-set mb-2"
                  placeholder="Min Value (Millions)"
                  [(ngModel)]="minCustomMarket"
                  (input)="updateMarketCapCustomRange()"
                  (change)="pushFilterMarket_cap($event)"
                />
                <input
                  type="number"
                  class="custom-input-set"
                  placeholder="Max Value (Millions)"
                  [(ngModel)]="maxCustomMaket"
                  (input)="updateMarketCapCustomRange()"
                  (change)="EndpushFilterMarket_cap($event)"
                />
              </div>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
      <div class="dropdown mx-2 d-flex justify-content-center">
        <button
          [ngClass]="selectedDateColor"
          class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
        >
          <span>
            {{
              selectedDateRange
                ? selectedDateRange
                : selectedValueinDate
                ? selectedValueinDate
                : "Date"
            }}
          </span>
          <i class="fa fa-caret-down ms-4"></i>
        </button>

        @if (selectedDateColor) {
        <span class="" (click)="clearSelectedDate()">
          <img
            class="m-1 mb-0 cursorpointer"
            src="assets/images/new-minus.svg"
            alt=""
          />
        </span>
        }

        <ul class="dropdown-menu">
          <li>
            <p
              [class.selected]="'This Week' === selectedValueinDate"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('This Week')"
            >
              This Week
            </p>
          </li>
          <li>
            <p
              [class.selected]="'This Month' === selectedValueinDate"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('This Month')"
            >
              This Month
            </p>
          </li>
          <li>
            <p
              [class.selected]="'Next Week' === selectedValueinDate"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('Next Week')"
            >
              Next Week
            </p>
          </li>
          <li>
            <p
              [class.selected]="'Next Month' === selectedValueinDate"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('Next Month')"
            >
              Next Month
            </p>
          </li>
          <li>
            <button
              class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
              data-bs-toggle="dropdown"
            >
              Customize
            </button>
            <ul class="dropdown-menu">
              <div class="p-1" aria-labelledby="shareHoldDropdown">
                <input
                  type="date"
                  class="custom-input-set mb-2"
                  placeholder="Min Value (Millions)"
                  [(ngModel)]="minCustomDate"
                  (input)="updateDateCustomRange()"
                  (change)="pushFilterDateStart($event)"
                />
                <input
                  type="date"
                  class="custom-input-set"
                  placeholder="Max Value (Millions)"
                  [(ngModel)]="maxCustomDate"
                  (input)="updateDateCustomRange()"
                  (change)="pushFilterDateEnd($event)"
                />
              </div>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
      <div class="dropdown mx-2 d-flex justify-content-center">
        <button
          [ngClass]="selectedindustryColor"
          class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
        >
          <span>
            {{ selectedValueinindustry ? selectedValueinindustry : "Industry" }}
          </span>
          <i class="fa fa-caret-down ms-4"></i>
        </button>

        @if (selectedindustryColor) {
        <span class="" (click)="clearSelectedIndustry()">
          <img
            class="m-1 mb-0 cursorpointer"
            src="assets/images/new-minus.svg"
            alt=""
          />
        </span>
        }

        <ul class="dropdown-menu">
          <li>
            <p
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              [class.selected]="'Biotechnology' === selectedValueinindustry"
              (click)="selectedData('Biotechnology')"
            >
              Biotechnology
            </p>
          </li>
          <li>
            <p
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              [class.selected]="'Medical Device' === selectedValueinindustry"
              (click)="selectedData('Medical Device')"
            >
              Medical Device
            </p>
          </li>
          <li>
            <p
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              [class.selected]="
                'Diagnostics & Research' === selectedValueinindustry
              "
              (click)="selectedData('Diagnostics & Research')"
            >
              Diagnostics & Research
            </p>
          </li>
          <li>
            <p
              class="dropdown-item filter-font-text text-center m-0"
              [class.selected]="
                'Drug Manufacturers' === selectedValueinindustry
              "
              (click)="selectedData('Drug Manufacturers')"
            >
              Drug Manufacturers
            </p>
          </li>
        </ul>
      </div>
    </div>

    <!-- Specific_Diseases -->
    <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
      <div class="dropdown mx-2 d-flex justify-content-center">
        <button
          [ngClass]="selectedDiseasesColor"
          class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
        >
          <span>
            @if (selectedDiseases) {
            {{
              selectedDiseases.length > 0
                ? selectedDiseases.length + " Selected"
                : "Specific Diseases"
            }}
            } @else { Specific Diseases }
          </span>
          <i class="fa fa-caret-down ms-4"></i>
        </button>

        @if(selectedDiseasesColor){
        <span class="" (click)="clearDiseasesCheckbox()">
          <img
            class="m-1 mb-0 cursorpointer"
            src="assets/images/new-minus.svg"
            alt=""
          />
        </span>
        }

        <ul class="dropdown-menu">
          <div class="form-check form-switch p-2 m-0">
            <label class="form-check-label p-0 m-0" for="selectDiseasesCheckbox"
              >Select</label
            >
            <input
              class="form-check-input"
              type="checkbox"
              id="selectDiseasesCheckbox"
              (change)="toggleDiseasesSelect($event)"
            />
          </div>
          <div class="p-3 pt-1">
            @for (item of diseasesList; track $index) {
            <div>
              <label class="filter-font-text">
                <input
                  type="checkbox"
                  (change)="pushFilterDiseases()"
                  [(ngModel)]="item.checked"
                />
                {{ item.name }}
              </label>
            </div>
            }@empty { No Record Found }
          </div>
        </ul>
      </div>
    </div>

    <!-- Therapeutic_area -->
    <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
      <div class="dropdown mx-2 d-flex justify-content-center">
        <button
          [ngClass]="selectedTherapeuticColor"
          class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
        >
          <span>
            @if (selectedTherapeutic) {
            {{
              selectedTherapeutic.length > 0
                ? selectedTherapeutic.length + " Selected"
                : "Therapeutic Area"
            }}
            } @else { Therapeutic Area }
          </span>
          <i class="fa fa-caret-down ms-4"></i>
        </button>

        @if(selectedTherapeuticColor){
        <span class="" (click)="clearTherapeuticCheckbox()">
          <img
            class="m-1 mb-0 cursorpointer"
            src="assets/images/new-minus.svg"
            alt=""
          />
        </span>
        }

        <ul class="dropdown-menu">
          <div class="form-check form-switch p-2 m-0">
            <label
              class="form-check-label p-0 m-0"
              for="selectTheraputicsCheckbox"
              >Select</label
            >
            <input
              class="form-check-input"
              type="checkbox"
              id="selectTheraputicsCheckbox"
              (change)="toggleTheraputics($event)"
            />
          </div>
          <div class="p-3 pt-1">
            @for (item of therapeuticList; track $index) {
            <div>
              <label class="filter-font-text">
                <input
                  type="checkbox"
                  (change)="pushFilterTherapeutic()"
                  [(ngModel)]="item.checked"
                />
                {{ item.name }}
              </label>
            </div>
            }@empty { No Record Found }
          </div>
        </ul>
      </div>
    </div>

    <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
      <div class="dropdown mx-2 d-flex justify-content-center">
        <button
          [ngClass]="selectedbutn_rateColor"
          class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
        >
          <span>
            {{
              selectedBurnRateRange
                ? selectedBurnRateRange
                : selectedValueinburn_rate
                ? selectedValueinburn_rate
                : "Burn Rate"
            }}
          </span>
          <i class="fa fa-caret-down ms-4"></i>
        </button>

        @if (selectedbutn_rateColor) {
        <span class="" (click)="clearSelectedBurn_rate()">
          <img
            class="m-1 mb-0 cursorpointer"
            src="assets/images/new-minus.svg"
            alt=""
          />
        </span>
        }

        <ul class="dropdown-menu">
          <li>
            <p
              [class.selected]="'-5b_negative' === selectedValueinburn_rate"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('-5b_negative')"
            >
              More than -5 Billion (Negative)
            </p>
          </li>
          <li>
            <p
              [class.selected]="'-500m_-5b' === selectedValueinburn_rate"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('-500m_-5b')"
            >
              -500 Million to -5 Billion
            </p>
          </li>
          <li>
            <p
              [class.selected]="'-10m_-500m' === selectedValueinburn_rate"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('-10m_-500m')"
            >
              -10 Million to -500 Million
            </p>
          </li>
          <li>
            <p
              [class.selected]="'-10m_500m' === selectedValueinburn_rate"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('-10m_500m')"
            >
              -10 Million to 500 Million
            </p>
          </li>
          <li>
            <p
              [class.selected]="'500m_5b' === selectedValueinburn_rate"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('500m_5b')"
            >
              500 Million to 5 Billion
            </p>
          </li>
          <li>
            <p
              [class.selected]="'5b_more' === selectedValueinburn_rate"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('5b_more')"
            >
              More than 5 Billion
            </p>
          </li>
          <li>
            <button
              class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
              data-bs-toggle="dropdown"
            >
              Customize
            </button>
            <ul class="dropdown-menu">
              <div class="p-1" aria-labelledby="shareHoldDropdown">
                <input
                  type="number"
                  class="custom-input-set mb-2"
                  placeholder="Min Value (Millions)"
                  [(ngModel)]="minCustomburn_rate"
                  (input)="updateBurnRateCustomRange()"
                  (change)="pushFilterBurn_rate($event)"
                />
                <input
                  type="number"
                  class="custom-input-set"
                  placeholder="Max Value (Millions)"
                  [(ngModel)]="maxCustomburn_rate"
                  (input)="updateBurnRateCustomRange()"
                  (change)="EndpushFilterBurn_rate($event)"
                />
              </div>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
      <div class="dropdown mx-2 d-flex justify-content-center">
        <button
          [ngClass]="selectedrunwayColor"
          class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
        >
          <span>
            {{
              selectedRunwayRange
                ? selectedRunwayRange
                : selectedValueinrunway
                ? selectedValueinrunway
                : "Runway"
            }}
          </span>
          <i class="fa fa-caret-down ms-4"></i>
        </button>

        @if (selectedrunwayColor) {
        <span class="" (click)="clearSelectedrunway()">
          <img
            class="m-1 mb-0 cursorpointer"
            src="assets/images/new-minus.svg"
            alt=""
          />
        </span>
        }

        <ul class="dropdown-menu">
          <li>
            <p
              [class.selected]="'less_than_6m' === selectedValueinrunway"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('less_than_6m')"
            >
              Less than 6 Months
            </p>
          </li>
          <li>
            <p
              [class.selected]="'1y_to_2y' === selectedValueinrunway"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('1y_to_2y')"
            >
              1 Year to 2 Years
            </p>
          </li>
          <li>
            <p
              [class.selected]="'2_to_5y' === selectedValueinrunway"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('2_to_5y')"
            >
              2 to 5 Years
            </p>
          </li>
          <li>
            <p
              [class.selected]="'5_to_10y' === selectedValueinrunway"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('5_to_10y')"
            >
              5 to 10 Years
            </p>
          </li>
          <li>
            <p
              [class.selected]="'morethan_10y' === selectedValueinrunway"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('morethan_10y')"
            >
              More than 10 Years
            </p>
          </li>
          <li>
            <button
              class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
              data-bs-toggle="dropdown"
            >
              Customize
            </button>
            <ul class="dropdown-menu">
              <div class="p-1" aria-labelledby="shareHoldDropdown">
                <input
                  type="number"
                  class="custom-input-set mb-2"
                  placeholder="Min Value (Months)"
                  [(ngModel)]="minCustomrunway"
                  (input)="updateRunwayCustomRange()"
                  (change)="pushFilterrunway($event)"
                />
                <input
                  type="number"
                  class="custom-input-set"
                  placeholder="Max Value (Months)"
                  [(ngModel)]="maxCustomrunway"
                  (input)="updateRunwayCustomRange()"
                  (change)="EndpushFilterrunway($event)"
                />
              </div>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
      <div class="dropdown mx-2 d-flex justify-content-center">
        <button
          [ngClass]="selectedlive_cashColor"
          class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
        >
          <span>
            {{
              selectedLiveCashRange
                ? selectedLiveCashRange
                : selectedValueinlive_cash
                ? selectedValueinlive_cash
                : "Live Cash"
            }}
          </span>
          <i class="fa fa-caret-down ms-4"></i>
        </button>

        @if (selectedlive_cashColor) {
        <span class="" (click)="clearSelectedLive_cash()">
          <img
            class="m-1 mb-0 cursorpointer"
            src="assets/images/new-minus.svg"
            alt=""
          />
        </span>
        }

        <ul class="dropdown-menu">
          <li>
            <p
              [class.selected]="'200b_more L' === selectedValueinlive_cash"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('200b_more L')"
            >
              $200 Billion or More
            </p>
          </li>
          <li>
            <p
              [class.selected]="'10b_200b L' === selectedValueinlive_cash"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('10b_200b L')"
            >
              $10 Billion to $200 Billion
            </p>
          </li>
          <li>
            <p
              [class.selected]="'2b_10b L' === selectedValueinlive_cash"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('2b_10b L')"
            >
              $2 Billion to $10 Billion
            </p>
          </li>
          <li>
            <p
              [class.selected]="'250m_2b L' === selectedValueinlive_cash"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('250m_2b L')"
            >
              $250 Million to $2 Billion
            </p>
          </li>
          <li>
            <p
              [class.selected]="'less_than_250m L' === selectedValueinlive_cash"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('less_than_250m L')"
            >
              Less than $250 Million
            </p>
          </li>
          <li>
            <button
              class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
              data-bs-toggle="dropdown"
            >
              Customize
            </button>
            <ul class="dropdown-menu">
              <div class="p-1" aria-labelledby="shareHoldDropdown">
                <input
                  type="number"
                  class="custom-input-set mb-2"
                  placeholder="Min Value (Millions)"
                  [(ngModel)]="minCustomlive_cash"
                  (input)="updateLiveCashCustomRange()"
                  (change)="pushFilterlive_cash($event)"
                />
                <input
                  type="number"
                  class="custom-input-set"
                  placeholder="Max Value (Millions)"
                  [(ngModel)]="maxCustomlive_cash"
                  (input)="updateLiveCashCustomRange()"
                  (change)="EndpushFilterlive_cash($event)"
                />
              </div>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
      <div class="dropdown mx-2 d-flex justify-content-center">
        <button
          [ngClass]="selectedvolumeColor"
          class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
        >
          <span>
            {{
              selectedVolumeRange
                ? selectedVolumeRange
                : selectedValueinvolume
                ? selectedValueinvolume
                : "Volume"
            }}
          </span>
          <i class="fa fa-caret-down ms-4"></i>
        </button>

        @if (selectedvolumeColor) {
        <span class="" (click)="clearSelectedvolume()">
          <img
            class="m-1 mb-0 cursorpointer"
            src="assets/images/new-minus.svg"
            alt=""
          />
        </span>
        }

        <ul class="dropdown-menu">
          <li>
            <p
              [class.selected]="'less_than_0.1m' === selectedValueinvolume"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('less_than_0.1m')"
            >
              Less than 0.1 Million
            </p>
          </li>
          <li>
            <p
              [class.selected]="'0.1m_10m' === selectedValueinvolume"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('0.1m_10m')"
            >
              0.1 Million to 10 Million
            </p>
          </li>
          <li>
            <p
              [class.selected]="'10m_100m' === selectedValueinvolume"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('10m_100m')"
            >
              10 Million to 100 Million
            </p>
          </li>
          <li>
            <p
              [class.selected]="'100m_200m' === selectedValueinvolume"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('100m_200m')"
            >
              100 Million to 200 Million
            </p>
          </li>
          <li>
            <p
              [class.selected]="'more_than_200m' === selectedValueinvolume"
              class="dropdown-item filter-font-text text-center filter-bottom m-0"
              (click)="selectedData('more_than_200m')"
            >
              More than 200 Million
            </p>
          </li>
          <li>
            <button
              class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
              data-bs-toggle="dropdown"
            >
              Customize
            </button>
            <ul class="dropdown-menu">
              <div class="p-1" aria-labelledby="shareHoldDropdown">
                <input
                  type="number"
                  class="custom-input-set mb-2"
                  placeholder="Min Value (Millions)"
                  [(ngModel)]="minCustomrvolume"
                  (input)="updateVolumeCustomRange()"
                  (change)="pushFiltervolume($event)"
                />
                <input
                  type="number"
                  class="custom-input-set"
                  placeholder="Max Value (Millions)"
                  [(ngModel)]="maxCustomrvolume"
                  (input)="updateVolumeCustomRange()"
                  (change)="EndpushFiltervolume($event)"
                />
              </div>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
      <div class="dropdown mx-2 d-flex justify-content-center">
        <button
          [ngClass]="selectedStageColor"
          class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
        >
          <span>
            @if (selectedStages) {
            {{
              selectedStages.length > 0
                ? selectedStages.length + " Selected"
                : "Stage"
            }}
            } @else { Stage }
          </span>
          <i class="fa fa-caret-down ms-4"></i>
        </button>

        @if (selectedStageColor) {
        <span class="" (click)="clearSelectedCheckbox()">
          <img
            class="m-1 mb-0 cursorpointer"
            src="assets/images/new-minus.svg"
            alt=""
          />
        </span>
        }

        <ul class="dropdown-menu">
          <div class="form-check form-switch p-2 m-0">
            <label class="form-check-label p-0 m-0" for="selectAllCheckbox"
              >Select</label
            >
            <input
              class="form-check-input"
              type="checkbox"
              id="selectAllCheckbox"
              (change)="toggleSelectAll($event)"
            />
          </div>
          <div class="p-3 pt-1">
            @for (item of selectlist; track $index) {
            <div>
              <label class="filter-font-text">
                <input
                  type="checkbox"
                  (change)="pushFilterstage()"
                  [(ngModel)]="item.checked"
                />
                {{ item.name }}
              </label>
            </div>
            }
          </div>
        </ul>
      </div>
    </div>
  </div>
  <div id="add" class="text-center mt-1 d-flex justify-content-center">
    <button
      type=""
      class="form-save-button d-flex justify-content-center align-items-center text-nowrap me-3 mt-2 mb-1"
      (click)="saveFilters()"
    >
      @if (!isFilterBtn) {
      <p class="mb-0">APPLY FILTERS</p>
      }@else {
      <i class="fa fa-spin fa-spinner"></i>
      }
    </button>
    <button
      type=""
      class="form-reset-button d-flex justify-content-center align-items-center text-nowrap me-3 mt-2 mb-1"
      (click)="resetFilters()"
    >
      @if (!isFilterBtnResert) {
      <p class="mb-0">RESET FILTERS</p>
      }@else {
      <i class="fa fa-spin fa-spinner"></i>
      }
    </button>
  </div>
</div>

@if (showButtons) {

<div class="scrollbtns d-flex justify-content-between">
  <img
    id="left-button"
    (click)="scrollLeft()"
    class="button"
    src="assets/images/left-blue-arrow.svg"
    alt=""
  />
  <img
    id="right-button"
    (click)="scrollRight()"
    class="button"
    src="assets/images/right-blue-arrow.svg"
    alt=""
  />
</div>

}

<div class="d-flex justify-content-between align-items-center mx-1">
  <p class="text-start tblcompanyLength my-1 d-flex align-items-center">
    <i class="fa-solid fa-circle-info mx-2"></i>
    Showing {{ catalysTable.length }} Catalyst Out of {{ totalDatalength }}.
    Click on the tickers for more details!
  </p>
  <div class="d-flex">
    <app-multiselect
      [list]="list"
      (select_all)="Select_All_checkbox()"
      (shareCheckedList)="shareCheckedList($event)"
      (shareIndividualCheckedList)="shareIndividualCheckedList($event)"
    >
    </app-multiselect>
    @if(totalDatalength>0 && !btnLoading){
      <button
        (click)="exportToExcel()"
        class="download-button"
        [ngbTooltip]="downloadExcelTooltipValue"
      >
        <i class="fa-solid fa-download"></i>
      </button>
    }@else {
      <div class="download-loader download-button"></div>
    }
  </div>
</div>
<div style="position: relative">
  <div
    class="table-responsive scrollable-table"
    id="dataContainer"
    #dataContainer
    (scroll)="onScroll($event)"
    [ngClass]="{ tablelocked: !productId }"
  >
    <table class="table align-middle mb-0 bg-white">
      <thead class="text-center">
        <tr class="text-uppercase table-header text-nowrap">
          <th class="table-name">
            <div class="d-flex justify-content-center cursor-pointer">
              NAME<i
                (click)="assending('LeadSponsorName')"
                [ngClass]="arrowname"
                class="fa-solid cursorpointer mx-1"
              ></i>
            </div>
          </th>

          @if (ticker) {
          <th>
            <div class="d-flex justify-content-center cursor-pointer">
              Ticker<i
                (click)="assending('ticker')"
                [ngClass]="arrowticker"
                class="fa-solid cursorpointer mx-1"
              ></i>
            </div>
          </th>
          } @if(price){
          <th>
            <div class="d-flex justify-content-center cursor-pointer">
              Price<i
                (click)="assending('company.close')"
                [ngClass]="arrowprice"
                class="fa-solid cursorpointer mx-1"
              ></i>
            </div>
          </th>
          } @if (thirtydaypricechange) {
          <th
            class="text-wrap"
            placement="bottom"
            container="body"
            ngbTooltip="30 Days Of Price Change"
          >
            30 DAYS PRICE CHANGE
          </th>
          } @if (industry) {
          <th>Industry</th>
          } @if (title) {
          <th>
            <div class="d-flex justify-content-center cursor-pointer">
              Title<i
                (click)="assending('BriefTitle')"
                [ngClass]="arrowtitle"
                class="fa-solid cursorpointer mx-1"
              ></i>
            </div>
          </th>
          } @if (CompletionDate) {
          <th class="text-wrap">
            <div class="d-flex justify-content-center cursor-pointer">
              Completion Date<i
                (click)="assending('parsedCompletionDate')"
                [ngClass]="arrowcompletionDate"
                class="fa-solid cursorpointer mx-1"
              ></i>
            </div>
          </th>
          } @if (Condition) {
          <th>
            <div class="d-flex justify-content-center cursor-pointer">
              Condition<i
                (click)="assending('Condition')"
                [ngClass]="arrowcondition"
                class="fa-solid cursorpointer mx-1"
              ></i>
            </div>
          </th>
          } @if (Pipeline) {
          <th>
            <div class="d-flex justify-content-center cursor-pointer">
              Pipeline<i
                (click)="assending('InterventionName')"
                [ngClass]="arrowpipeline"
                class="fa-solid cursorpointer mx-1"
              ></i>
            </div>
          </th>
          } @if (Phase) {
          <th>
            <div class="d-flex justify-content-center cursor-pointer">
              Phase<i
                (click)="assending('Phase')"
                [ngClass]="arrowphase"
                class="fa-solid cursorpointer mx-1"
              ></i>
            </div>
          </th>
          } @if (marketcap) {
          <th
            class="text-nowrap"
            placement="bottom"
            container="body"
            ngbTooltip="Market Capital"
          >
            <div class="d-flex justify-content-center cursor-pointer">
              Market cap<i
                (click)="assending('company.market_cap_basic')"
                [ngClass]="arrowmarket_cap"
                class="fa-solid cursorpointer mx-1"
              ></i>
            </div>
          </th>
          } @if (live_cash) {
          <th>
            <div class="d-flex justify-content-center cursor-pointer">
              Live-Cash<i
                (click)="assending('company.Cash_and_Cash_Equivalents')"
                [ngClass]="arrowlive_cash"
                class="fa-solid cursorpointer mx-1"
              ></i>
            </div>
          </th>
          } @if (volume) {
          <th>
            <div class="d-flex justify-content-center cursor-pointer">
              volume<i
                (click)="assending('company.volume')"
                [ngClass]="arrowvolume"
                class="fa-solid cursorpointer mx-1"
              ></i>
            </div>
          </th>
          } @if (burn_rate) {
          <th
            class="text-nowrap"
            placement="bottom"
            container="body"
            ngbTooltip="BURN RATE (MONTHLY)"
          >
            <div class="d-flex justify-content-center cursor-pointer">
              Burn-Rate<i
                (click)="assending('company.Monthly_Burn_Rate')"
                [ngClass]="arrowburn_rate"
                class="fa-solid cursorpointer mx-1"
              ></i>
            </div>
          </th>
          } @if (runway) {
          <th placement="left" container="body" ngbTooltip="Runway (Months)">
            <div class="d-flex justify-content-center cursor-pointer">
              Runway<i
                (click)="assending('company.Runway_(Months)')"
                [ngClass]="arrowrunway"
                class="fa-solid cursorpointer mx-1"
              ></i>
            </div>
          </th>
          }

          <!-- <th *ngIf="Drugname">Drugname</th> -->
        </tr>
      </thead>
      <tbody class="text-center">
        <!-- <tr *ngIf="isLoading" class="loading-border">
          <td colspan="15">
            <div class="table-loading-container">
              <span class="loader"></span>
            </div>
          </td>
        </tr> -->
        @if (catalysTable.length == 0) {
        <tr style="box-shadow: none">
          <td colspan="17">
            <ngx-skeleton-loader
              [theme]="{
                height: '50px',
                'border-radius': '4px',
                'margin-top': '20px'
              }"
              count="11"
            >
            </ngx-skeleton-loader>
          </td>
        </tr>
        }@else { @for (cs of catalysTable; track $index) {
        <tr class="table-tr-font">
          <td scope="row">
            <div class="mt-2 mb-2 text-center">
              <div
                class="text-center company-hover"
                (click)="companyDetails(cs.ticker)"
              >
                @if (cs.logoid !== '') {
                <img
                  width="25px"
                  [src]="basePath + cs.logoid + '.svg'"
                  class="mb-1"
                />
                }@else {
                <span class="avatarcssForTable">{{
                  cs.LeadSponsorName | initialLetter
                }}</span>
                }

                <p class="text-center text-data table-font-size">
                  <a
                    href="{{ clientUrl }}/company/{{ cs.ticker | uppercase }}"
                    class="link-ticker"
                  >
                    {{ cs.LeadSponsorName.substring(0, 50) }} @if
                    (cs.LeadSponsorName.length > 50 ) {...}
                  </a>
                  @if (cs.LeadSponsorName.length > 50 ) {
                  <span
                    triggers="click:blur"
                    style="cursor: pointer"
                    class="text-danger"
                    container="body"
                    ngbTooltip="{{ cs.LeadSponsorName }}"
                  >
                    Read More+
                  </span>
                  }
                </p>
              </div>
            </div>
          </td>
          <td scope="row" class="d-none">{{ $index + 1 }}</td>

          @if (ticker) {
          <td class="table-wide-cell">
            <div class="d-grid justify-content-center">
              <p class="table-astec-font table-font-size">
                <a
                  href="{{ clientUrl }}/company/{{ cs.ticker | uppercase }}"
                  class="link-ticker"
                >
                  {{ cs.ticker }}
                </a>
              </p>

              @if (cs.ticker) { @if (portfoliomatchTicker) { @if
              (portfoliomatchTicker.includes(cs.ticker)) {
              <i
                placement="bottom"
                container="body"
                ngbTooltip="Remove from Portfolio"
                (click)="removeSymboltoPotfoliyo(cs.ticker)"
                style="
                  opacity: unset;
                  font-size: 20px;
                  color: var(--clr-primary);
                "
                class="fa-solid fa-circle-minus my-2 cursorpointer"
              ></i>
              }@else {
              <i
                placement="bottom"
                container="body"
                ngbTooltip="Add to Portfolio"
                data-bs-toggle="modal"
                (click)="
                  this.portfolioTicker = cs.ticker;
                  this.portfolioPrice = cs.close;
                  portfoliocost = undefined;
                  portfolioquntity = undefined
                "
                data-bs-target="#exampleModal"
                style="
                  opacity: unset;
                  font-size: 20px;
                  color: var(--clr-primary);
                "
                class="fa-solid fa-circle-plus my-2 cursorpointer"
              ></i>
              } } @if (!portfoliomatchTicker) {
              <i
                placement="bottom"
                container="body"
                ngbTooltip="Add to Portfolio"
                data-bs-toggle="modal"
                (click)="
                  this.portfolioTicker = cs.ticker;
                  this.portfolioPrice = cs.close;
                  portfoliocost = undefined;
                  portfolioquntity = undefined
                "
                data-bs-target="#exampleModal"
                style="
                  opacity: unset;
                  font-size: 20px;
                  color: var(--clr-primary);
                "
                class="fa-solid fa-circle-plus my-2 cursorpointer"
              ></i>
              } }
            </div>
          </td>
          } @if(price){
          <td class="table-font-size">
            {{ cs.close | number : "1.0-2" }}
            <p
              class="table-price table-font-size"
              [style.color]="cs.change.toString() | riseAndFallColor"
            >
              {{ cs.change | number : "1.0-2" }}%
            </p>
          </td>
          } @if (thirtydaypricechange) {
          <td>
            @if (cs.thirtyDayChart) {
            <app-thirty-day-trend
              class="d-flex justify-content-center"
              [chartData]="cs.thirtyDayChart"
              [x]="$index"
            ></app-thirty-day-trend>
            }@else {
            <p>-</p>
            }
          </td>
          } @if (industry) {
          <td class="table-font-size">
            @if (cs.Industry) {
            <p>{{ cs.Industry }}</p>
            }@else {
            <p>-</p>
            }
          </td>
          } @if (title) {
          <td>
            <a
              href="{{ clientUrl }}/company/{{ cs.ticker }}?section=drugpipe"
              class="text-dark"
              ><p style="width: 300px" class="m-0 table-font-size">
                {{ cs.BriefTitle | firstthirtyword }}
              </p></a
            >
            @if (cs.BriefTitle) {
            <div>
              @if (cs.BriefTitle.length > 80) {
              <p
                triggers="click:blur"
                style="cursor: pointer"
                class="text-danger"
                container="body"
                ngbTooltip="{{ cs.BriefTitle }}"
              >
                Read More+
              </p>
              }
            </div>
            }
          </td>
          } @if (CompletionDate) {
          <td class="table-font-size" class="table-font-size table-text-blue">
            <a
              class="table-text-blue"
              href="{{ clientUrl }}/company/{{ cs.ticker }}?section=drugpipe"
              >{{ cs.CompletionDate }}</a
            >
          </td>
          } @if (Condition) {
          <td class="table-font-size">
            <a
              class="text-dark"
              href="{{ clientUrl }}/company/{{ cs.ticker }}?section=drugpipe"
            >
              {{ cs.Condition | conditionlength }}</a
            >
            @if (cs.Condition) {
            <div>
              @if (cs.Condition.length > 80) {
              <p
                triggers="click:blur"
                style="cursor: pointer"
                class="text-danger"
                container="body"
                ngbTooltip="{{ cs.Condition }}"
              >
                Read More+
              </p>
              }
            </div>
            }
          </td>
          } @if (Pipeline) {
          <td class="table-font-size" style="white-space: wrap">
            <a
              class="text-dark"
              href="{{ clientUrl }}/company/{{ cs.ticker }}?section=drugpipe"
            >
              <p class="text-dark" style="word-wrap: break-word; width: 200px">
                {{ cs.InterventionName | conditionlength }}
              </p></a
            >
            @if (cs.InterventionName) {
            <div>
              @if (cs.InterventionName.length > 80) {
              <p
                triggers="click:blur"
                style="cursor: pointer"
                class="text-danger"
                container="body"
                ngbTooltip="{{ cs.InterventionName }}"
              >
                Read More+
              </p>
              }
            </div>
            }
          </td>
          } @if (Phase) {
          <td class="table-font-size text-nowrap">
            {{ cs.Phase }}

            @if (cs.Phase) {
            <app-stage-progress stage="{{ cs.Phase }}"></app-stage-progress>
            }@else {
            <p>-</p>
            }
          </td>
          } @if (marketcap) {
          <td class="table-font-size">
            {{ cs.market_cap_basic | dollarsCoversion }}
          </td>
          } @if (live_cash) {
          <td class="table-wide-cell text-nowrap">
            @if (cs.Cash_and_Cash_Equivalents != null || 0 || '') {
            <p
              [style.color]="
                cs.Cash_and_Cash_Equivalents?.toString() | riseAndFallColor
              "
              class="table-font-size"
            >
              {{ cs.Cash_and_Cash_Equivalents | dollarsCoversion }}
            </p>
            }@else {
            <p>-</p>
            }
          </td>
          } @if (volume) {
          <td class="table-wide-cell text-nowrap">
            @if (cs.volume != null || 0 || '') {
            <p
              [style.color]="cs.volume?.toString() | riseAndFallColor"
              class="table-font-size"
            >
              {{ cs.volume | dollarsCoversion }}
            </p>
            }@else {
            <p>-</p>
            }
          </td>
          } @if (burn_rate) {
          <td class="table-wide-cell text-nowrap">
            @if(cs.Monthly_Burn_Rate != null || 0 || ''){
            <p
              [style.color]="
                cs.Monthly_Burn_Rate?.toString() | riseAndFallColor
              "
              class="table-font-size"
            >
              {{ cs.Monthly_Burn_Rate | dollarsCoversion }}
            </p>
            }@else {
            <p>-</p>
            }
          </td>
          } @if (runway) {
          <td>
            @if (cs.Runway_Months != null || 0 || '') {
            <p
              [style.color]="cs.Runway_Months?.toString() | riseAndFallColor"
              class="table-wide-cell text-nowrap"
              class="table-font-size"
            >
              {{ cs.Runway_Months.toString() | infinityNumber }}
            </p>
            }@else {
            <p>-</p>
            }
          </td>
          }
        </tr>
        }@empty { No Record Found } }
      </tbody>
      <tfoot>
        @if (loadData) {
        <td colspan="20">
          <ngx-skeleton-loader
            [theme]="{
                  height: '50px',
                  'border-radius': '4px',
                  'margin-top': '20px',
                }"
            count="1"
          >
          </ngx-skeleton-loader>
        </td>
        }
      </tfoot>
    </table>
  </div>
  <div
    style="
      position: absolute;
      bottom: 0;
      background-color: #f1f7fde6;
      z-index: 1;
    "
  >
    @if (!productId) { @if (catalysTable.length > 0) {
    <app-locksec></app-locksec>
    } }
  </div>
</div>
<app-loginpop-up
  [displayStyle]="modalDisplayStyle"
  (myout)="GetChildData($event)"
></app-loginpop-up>

<app-portfoliomodal
  [portfolioTicker]="portfolioTicker"
  [portfolioPrice]="portfolioPrice"
  [portfoliocost]="portfoliocost"
  [portfolioquntity]="portfolioquntity"
  (addSymbol)="onAddSymbol($event)"
></app-portfoliomodal>
