import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FilterdataService } from 'src/app/modules/shared/service/filterdata.service';
import { AdminFdaServiceService } from '../../services/admin-fda.service.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-fda-calender-admin',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule],
  templateUrl: './fda-calender-admin.component.html',
  styleUrl: './fda-calender-admin.component.scss',
})
export class FdaCalenderAdminComponent {
  admin: any;
  treatment: any;
  description: any;
  catalyst: any;
  stage: any;
  catdate: any;
  name: any;
  ticker: any;
  drugname: any;
  catalystId: any;
  searchValuequery: any;
  form!: FormGroup;
  dataLength: any = 1;
  runScriptLoader: boolean = false;
  scriptRunAt: any;

  // arr!: FormArray;
  showtable: boolean = false;

  constructor(
    private adminservies: AdminFdaServiceService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private filter: FilterdataService
  ) {
    this.form = this.fb.group({
      items: this.fb.array([]),
    });

    // Initialize the form with one empty item
    this.addItem();
  }

  ngOnInit(): void {
    this.fdaData();
    this.getRunTime();
  }

  fdaData() {
    this.admin = [];
    this.adminservies.getFdaadminData().subscribe({
      next: (data) => {
        this.admin = data;
      },
      error: (error) => {
        this.toaster.error('Something went wrong!', error);
      },
    });
  }

  editData(data: any) {
    this.catalystId = data._id;
    this.ticker = data.ticker; // disable
    this.catalyst = data.catalyst;
    this.catdate = data.catdate;
    this.description = data.description;
    this.drugname = data.drugname;
    this.name = data.name; // disable
    this.stage = data.stage;
    this.treatment = data.treatment;
  }

  updateChanges() {
    const updatedData = {
      id: this.catalystId,
      ticker: this.ticker,
      name: this.name,
      catalyst: this.catalyst,
      catdate: this.catdate,
      description: this.description,
      drugname: this.drugname,
      stage: this.stage,
      treatment: this.treatment,
    };
    this.adminservies.editFdaadminData(updatedData).subscribe({
      next: (res: any) => {
        this.toaster.success(res);
        this.fdaData();
      },
    });
  }

  deleteRecord(id: any) {
    let dataid = {
      id: id,
    };
    // return
    let conform = confirm('Are you sure you want to delete this record?');
    if (!conform) {
      return;
    }
    this.adminservies.deleteFdaadminData(dataid).subscribe({
      next: (res: any) => {
        this.toaster.success(res);
        this.admin = this.admin.filter((item: { _id: any }) => item._id !== id);
      },
    });
  }

  liveThisRecord(id: any) {
    let dataid = {
      id: id,
    };
    // return
    let conform = confirm(
      'Are you sure you want to make this record live? Once live, this record will be deleted.'
    );
    if (!conform) {
      return;
    }
    this.adminservies.InsertFdadata(dataid).subscribe({
      next: (res: any) => {
        this.toaster.success(res);
        this.admin = this.admin.filter((item: { _id: any }) => item._id !== id);
      },
    });
  }

  wipeDataClick() {
    let conform;
    do {
      conform = prompt('Please enter the secret code to delete all records:');
      if (conform === null) {
        alert('Action cancelled.');
        return;
      }
      if (conform !== 'CONFIRM') {
        alert('Incorrect code. Please try again.');
      }
    } while (conform !== 'CONFIRM');

    this.adminservies.delFdaAdmin().subscribe({
      next: (res: any) => {
        this.toaster.success(res);
        this.admin = [];
      },
    });
  }

  RunScript() {
    this.runScriptLoader = true;
    let conform;
    do {
      conform = prompt('Please enter the secret code to run script:');
      if (conform === null) {
        alert('Action cancelled.');
        this.runScriptLoader = false;
        return;
      }
      if (conform !== 'CONFIRM') {
        alert('Incorrect code. Please try again.');
      }
    } while (conform !== 'CONFIRM');

    this.adminservies.runScript().subscribe({
      next: (res: any) => {
        this.runScriptLoader = false;
        this.toaster.success(res);
        this.fdaData();
        this.scriptRunAt = Date.now();
      },
    });
  }

  getRunTime() {
    this.adminservies.scriptRunTime().subscribe({
      next: (res: any) => {
        this.scriptRunAt = res.date;
      },
    });
  }

  createItem() {
    return this.fb.group({
      lessons: this.fb.array([]),
    });
  }

  addItem() {
    const itemFormGroup = this.fb.group({
      ticker: [''],
      name: [''],
      catalyst: [''],
      catdate: [''],
      description: [''],
      drugname: [''],
      stage: [''],
      treatment: [''],
    });
    this.items.push(itemFormGroup);
  }
  removeItem(index: number) {
    this.items.removeAt(index);
  }
  onSubmit() {
    this.adminservies.addFdaadminData(this.form.value.items).subscribe({
      next: (res: any) => {
        this.toaster.success(res);
        this.admin.unshift(...this.form.value.items);
      },
      error: (err: any) => {
        (this.toaster as any).error('Error to Add Data');
      },
    });
  }

  get items() {
    return this.form.get('items') as FormArray;
  }
  showtablerow() {
    this.showtable = !this.showtable;
  }

  copyToClipboard(data: any) {
    navigator.clipboard.writeText(data).then(
      () => {
        this.toaster.success('Copied to clipboard');
      },
      (err) => {
        this.toaster.error('Something went wrong!', err);
      }
    );
  }
}
