import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  addadminfda,
  editadminfda,
  getadminfda,
  removeadminfda,
  insertFdaData,
  delFdaadmin,
  runScript,
  scriptRunTime,
} from '../../shared/const';

@Injectable({
  providedIn: 'root',
})
export class AdminFdaServiceService {
  constructor(private http: HttpClient) {}

  getFdaadminData() {
    return this.http.get<any[]>(`${getadminfda}`);
  }
  editFdaadminData(data: any) {
    return this.http.post<any[]>(`${editadminfda}`, data);
  }
  deleteFdaadminData(id: object) {
    return this.http.post<any[]>(`${removeadminfda}`, id);
  }
  addFdaadminData(id: object) {
    return this.http.post<any[]>(`${addadminfda}`, id);
  }
  InsertFdadata(id: object) {
    return this.http.post<any[]>(`${insertFdaData}`, id);
  }
  delFdaAdmin() {
    return this.http.delete<any[]>(`${delFdaadmin}`);
  }
  runScript() {
    return this.http.post<any[]>(`${runScript}`, {});
  }
  scriptRunTime() {
    return this.http.get<any[]>(`${scriptRunTime}`);
  }
}
