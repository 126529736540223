import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { footerEmail, unsubscribed } from '../../shared/const';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  constructor(private http: HttpClient) {}

  footerEmail(data: any) {
    return this.http.post<any>(`${footerEmail}`, data);
  }

  ubsubscribeApi(data: any) {
    return this.http.post<any>(`${unsubscribed}`, data);
  }
}
