<div
  class="d-flex justify-content-between py-2 px-4 align-items-center fda-header bg-light"
>
  <div class="d-flex col">
    <p class="m-0 header-txt">FDA calendar Admin</p>
    <pre *ngIf="admin.length" style="font-size: smaller">{{
      admin.length
    }}</pre>
  </div>
  <!-- <p class="m-0">
    <span style="color: #000">{{ admin.length }}</span> records
  </p> -->
  @if(scriptRunAt && !showtable ){
  <p class="m-0 mx-3">
    {{ scriptRunAt | date : "d MMM, h:mm a" }}
    <button class="btn btn-sync" (click)="getRunTime()">
      <i class="fa-solid fa-arrows-rotate"></i>
    </button>
  </p>
  }
  <div>
    @if(runScriptLoader){
    <button class="btn">
      <div class="download-loader download-button"></div>
    </button>
    }@else { @if(!showtable){
    <button class="btn buttons-header" (click)="RunScript()">
      <p class="m-0">Run Script</p>
    </button>
    } }
    <button (click)="showtablerow()" class="btn mx-2 buttons-header">
      @if (showtable) {
      <p class="m-0">Show Table</p>

      }@else {

      <p class="m-0">Insert Records</p>
      }
    </button>
    @if(!showtable && admin.length){
    <button class="btn buttons-header" (click)="wipeDataClick()">
      <p class="m-0">Wipe data</p>
    </button>
    }
  </div>
</div>

@if (showtable) {
<div class="container mt-4">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <table class="table">
      <thead>
        <tr>
          <th>Ticker</th>
          <th>Name</th>
          <th>Catalyst</th>
          <th>Catalyst Date</th>
          <th>Drug Name</th>
          <th>Stage</th>
          <th>Treatment</th>
          <th>Description</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody formArrayName="items">
        @for (item of items.controls; track $index) {
        <tr [formGroupName]="$index">
          <td>
            <input type="text" formControlName="ticker" class="form-control" />
          </td>
          <td>
            <input type="text" formControlName="name" class="form-control" />
          </td>
          <td>
            <input
              type="text"
              formControlName="catalyst"
              class="form-control"
            />
          </td>
          <td>
            <input type="text" formControlName="catdate" class="form-control" />
          </td>
          <td>
            <input
              type="text"
              formControlName="drugname"
              class="form-control"
            />
          </td>
          <td>
            <input type="text" formControlName="stage" class="form-control" />
          </td>
          <td>
            <input
              type="text"
              formControlName="treatment"
              class="form-control"
            />
          </td>
          <td>
            <textarea
              formControlName="description"
              class="form-control"
            ></textarea>
          </td>
          <td>
            <button
              type="button"
              (click)="removeItem($index)"
              class="btn btn-danger"
            >
              Remove
            </button>
          </td>
        </tr>
        }
      </tbody>
    </table>

    <p class="m-0 text-end" style="color: grey; font-size: small">
      Note: An empty record should be recognized as a valid record as well.
    </p>
    <button type="button" (click)="addItem()" class="btn btn-primary me-3">
      Add Item
    </button>
    <button type="submit" class="btn btn-success">Submit</button>
  </form>
</div>

}@else {
<table class="table" style="max-width: 100vw !important">
  <thead class="text-uppercase">
    <tr>
      <th scope="col" class="txt-center bg-light"></th>
      <th scope="col" class="txt-center bg-light">ticker</th>
      <th scope="col" class="txt-center bg-light">link</th>
      <th scope="col" class="txt-center bg-light">catdate</th>
      <th scope="col" class="txt-center bg-light">drugname</th>
      <th scope="col" class="txt-center bg-light">stage</th>
      <th scope="col" class="txt-center bg-light">catalyst</th>
      <th scope="col" class="txt-center bg-light">treatment</th>
      <th scope="col" class="txt-center bg-light">description</th>
      <th scope="col" class="txt-center bg-light"></th>
      <th scope="col" class="txt-center bg-light"></th>
      <th scope="col" class="txt-center bg-light"></th>
    </tr>
  </thead>
  <tbody>
    @for (item of admin; track $index) {
    <tr>
      <th scope="row">{{ $index + 1 }}</th>
      <td class="txt-center">{{ item.ticker }}</td>
      <td class="txt-center">
        @if(item.link){
        <a class="btn" href="{{ item.link }}" target="_blank">
          <i class="fa-solid fa-arrow-up-right-from-square"></i> </a
        >}@else {
        <i class="fa-solid fa-minus"></i>
        }
      </td>
      <td class="txt-center">{{ item.catdate }}</td>
      <td class="txt-center">{{ item.drugname }}</td>
      <td class="txt-center">{{ item.stage }}</td>
      <td class="txt-center">{{ item.catalyst }}</td>
      <td class="txt-center">{{ item.treatment }}</td>
      <td class="txt-center">{{ item.description }}</td>
      <td class="txt-center">
        <button
          class="btn buttons-tbl btn-edit"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          (click)="editData(item)"
        >
          <i class="fa-regular fa-pen-to-square"></i>
        </button>
      </td>
      <td class="txt-center">
        <button
          class="btn buttons-tbl btn-delete"
          (click)="deleteRecord(item._id)"
        >
          <i class="fa-solid fa-trash"></i>
        </button>
      </td>
      <td>
        <button
          class="btn buttons-tbl btn-done"
          (click)="liveThisRecord(item._id)"
        >
          <i class="fa-solid fa-check"></i>
        </button>
      </td>
    </tr>
    }
  </tbody>
</table>
}

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">FDA-ADMIN</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <form>
          <div class="mb-3">
            <label for="ticker" class="form-label">Id</label>
            <input
              type="text"
              class="form-control"
              disabled
              id="catId"
              [(ngModel)]="catalystId"
              name="catId"
            />
          </div>

          <div class="mb-3">
            <label for="ticker" class="form-label">Ticker</label>
            <input
              type="text"
              class="form-control"
              disabled
              id="ticker"
              [(ngModel)]="ticker"
              name="ticker"
            />
          </div>

          <div class="mb-3">
            <label for="name" class="form-label">Name</label>
            <input
              type="text"
              class="form-control"
              disabled
              id="name"
              [(ngModel)]="name"
              name="name"
            />
          </div>
          <div class="mb-3">
            <label for="catdate" class="form-label">Catdate</label>
            <input
              type="text"
              class="form-control"
              id="catdate"
              [(ngModel)]="catdate"
              name="catdate"
            />
          </div>
          <div class="mb-3">
            <label for="drugname" class="form-label">Drugname</label>
            <input
              type="text"
              class="form-control"
              id="drugname"
              [(ngModel)]="drugname"
              name="drugname"
            />
          </div>
          <div class="mb-3">
            <label for="stage" class="form-label">Stage</label>
            <input
              type="text"
              class="form-control"
              id="stage"
              [(ngModel)]="stage"
              name="stage"
            />
          </div>
          <div class="mb-3">
            <label for="catalyst" class="form-label">Catalyst</label>
            <input
              type="text"
              class="form-control"
              id="catalyst"
              [(ngModel)]="catalyst"
              name="catalyst"
            />
          </div>
          <div class="mb-3">
            <label for="treatment" class="form-label">Treatment</label>
            <input
              type="text"
              class="form-control"
              id="treatment"
              [(ngModel)]="treatment"
              name="treatment"
            />
          </div>
          <div class="mb-3">
            <label for="description" class="form-label">Description</label>
            <!-- <input type="text" class="form-control" id="description" name="description"> -->
            <textarea
              class="form-control"
              id="description"
              name="description"
              [(ngModel)]="description"
              cols="30"
              rows="10"
            ></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-bs-dismiss="modal"
          (click)="updateChanges()"
        >
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>
