import { Component, HostListener } from '@angular/core';
import { HedgefundsService } from '../../../shared/service/hedgefunds.service';
import { ToastrService } from 'ngx-toastr';
import { FilterdataService } from '../../../shared/service/filterdata.service';
import { CompanyDetailsService } from '../../../shared/service/company-details.service';
import { Router } from '@angular/router';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { SubscriptionService } from 'src/app/modules/shared/service/subscription.service';

@Component({
  selector: 'app-allstockshold',
  templateUrl: './allstockshold.component.html',
  styleUrls: ['./allstockshold.component.scss'],
})
export class AllstocksholdComponent {
  // @HostListener('window:scroll', ['$event'])
  allstocksTbl: any;
  productId: any;
  lockedArray: any[] = [];
  isFilterBtnSearch = false;
  showAvgpriceDropDown!: boolean;
  showShareholdDropDown!: boolean;
  isMarketDropdownOpen!: boolean;
  isFilterBtn = false;
  isFilterBtnResert = false;
  minCustomValue: number | undefined;
  maxCustomValue: number | undefined;
  filterArray: any[] = [];
  selectedAvgPriceRange: string | null = null;
  selectedShareholdRange: string | null = null;
  selectedMarketRange: string | null = null;
  private currentPage = 1;
  private FilterCurrrentpage = 1;
  private currentSortPage = 1;
  isLoading = false;
  arrowclass = 'fa-arrow-up';
  FilterObj: any;
  sortNumber = 1;
  searchValuequery: string = '';
  sortKeyVal = '';
  isFilteron = false;
  isSorton = false;
  apitype = 'defualt';
  showloginWarning = false;
  selectedvaluecolor = '';
  selectedmarketcolor = '';
  modalDisplayStyle!: boolean;
  minCustomAvg: any;
  maxCustomAvg: any;
  minCustomMarket: any;
  maxCustomMarket: any;
  isSort = false;
  isFilter = false;
  totalDatalength = 0;
  arrowrank = 'fa-arrow-up';
  arrowavg_price = 'fa-arrow-up';
  arrowshare_held = 'fa-arrow-up';
  arrowmarket_cap = 'fa-arrow-up';
  loadData!: boolean;
  forResetfilters = false;
  suggection: any;
  basePath: string = 'https://s3-symbol-logo.tradingview.com/';
  clientUrl = environment.clientUrl;
  marketCapVal: any = '';
  btnLoading: any;
  downloadExcelTooltipValue: string = '';
  userToken: any = localStorage.getItem('accessToken');
  credits: number | undefined = 0;

  constructor(
    private hedgeService: HedgefundsService,
    private toaster: ToastrService,
    private filter: FilterdataService,
    private compamyDetails: CompanyDetailsService,
    private router: Router,
    private decryptionService: DecryptionService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.AllStocksTable();
    this.returnLogin();
  }

  exportToExcel() {
    this.btnLoading = true;

    if(this.userToken && this.productId) {
      const dataLength = {
        datalength: this.allstocksTbl.length,
      }
      this.subscriptionService.checkAvailableCredits(dataLength).subscribe({
        next: (res: any) => {
          this.setExportToExcel();
          this.toaster.success("Excel file downloaded successfully");
          this.btnLoading = false;
          this.credits = this.credits! - this.allstocksTbl.length;
          this.downloadExcelTooltipValue = this.credits > 0 ? 'Available credits: ' + this.credits : 'No credits available';
        },
        error: (error) => {
          this.toaster.error(error.error.message);
          this.btnLoading = false;
        }
      })
    } else if (this.userToken && !this.productId) {
      this.router.navigate(['subscription']);
    }
    else {
      this.router.navigate(['login']);
    }
  }

  setExportToExcel(){

    function formatValue(value: any) {
      const isNegative = value < 0;
      const av = Math.abs(value);
      let fv: any;
      if (av >= 1_000_000_000) {
        fv = (av / 1_000_000_000).toFixed(2) + ' B';
      } else if (av >= 1_000_000) {
        fv = (av / 1_000_000).toFixed(2) + ' M';
      } else if (av >= 1_000) {
        fv = (av / 1_000).toFixed(2) + ' K';
      } else {
        fv = av || '-';
      }
      return isNegative ? `-${fv}` : fv;
    }
    const data = this.allstocksTbl.map((item: any) => ({
      'COMPANY NAME': item.combinedData.name,
      STOCK: item.symbol,
      'HEDGE FUNDS': item.hedgeFunds,
      'SHARES HELD': formatValue(item.current_shares),
      'MARKET VALUE': formatValue(item.current_mv),
      RANK: item.rank,
    }));

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

    const metadata = [
      ['Title', 'All Stocks Holdings Records'],
      [
        'Description',
        'This report provides a complete listing of all stocks holdings records, detailing each asset’s current position, quantity, value, and associated financial data.',
      ],
      ['Source', 'biopharmawatch.com'],
      ['URL', 'https://biopharmawatch.com/allstockshold'],
      ['Generated on', new Date().toLocaleDateString()],
      [],
    ];
    XLSX.utils.sheet_add_aoa(worksheet, metadata, { origin: 'A1' });

    XLSX.utils.sheet_add_json(worksheet, data, {
      origin: 'A8',
      skipHeader: false,
    });

    const workbook: XLSX.WorkBook = {
      Sheets: { Data: worksheet },
      SheetNames: ['Data'],
    };

    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    this.saveAsExcelFile(excelBuffer, 'Allstockshold');

    setTimeout(() => {
      this.btnLoading = false;
    }, 500);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(data, `${fileName}_${new Date().toISOString()}.xlsx`);
  }

  returnLogin() {
    let loign = localStorage.getItem('loggedInUser');
    if (!loign) {
      this.showloginWarning = true;
    }
  }

  AllStocksTable() {
    this.hedgeService.AllstocksData(this.currentPage).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);
        const ParseDecryptedData = JSON.parse(decryptData);

        this.productId = ParseDecryptedData.find(
          (x: any) => x.productId
        )?.productId;

        this.credits = ParseDecryptedData.find(
          (x: any) => x.credits
        )?.credits;

        this.setDownloadExcelTooltip();

        this.allstocksTbl = ParseDecryptedData[0].data;
        // for (let i = 0; i < 3; i++) {
        //   this.lockedArray.push(this.allstocksTbl);
        //   this.lockedArray = this.lockedArray.flat();
        // }

        // if (!this.productId) {
        //   this.allstocksTbl = this.lockedArray;
        // }

        this.totalDatalength = ParseDecryptedData[0].total[0].count;

        this.isFilterBtnResert = false;
        this.currentPage = 2;
      },
    });
  }

  selectedCap(data: string) {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }

    if (this.marketCapVal === data) {
      this.marketCapVal = '';
    } else {
      switch (data) {
        case 'small-cap': {
          this.marketCapVal = 'small-cap';
          break;
        }
        case 'mid-cap': {
          this.marketCapVal = 'mid-cap';
          break;
        }
        case 'micro-cap': {
          this.marketCapVal = 'micro-cap';
          break;
        }
        case 'large-cap': {
          this.marketCapVal = 'large-cap';
          break;
        }
        case 'mega-cap': {
          this.marketCapVal = 'mega-cap';
          break;
        }
      }
      this.saveFilters();
    }
  }

  // searchFilters(query: any) {
  //   if (query == '') {
  //     this.toaster.warning('Enter Valid Inputs.');
  //     return;
  //   }
  //   let search =
  //   {
  //     query,
  //   }
  //   this.refresh()

  //   // this.isLoading = true;
  //   this.allstocksTbl = [];
  //   this.isFilterBtnSearch = true;
  //   this.filter.allStocksSerch(search).subscribe({
  //     next: (res: any) => {
  //       this.totalDatalength = res.length
  //       this.allstocksTbl = res;
  //       this.isLoading = true
  //       this.isFilterBtnSearch = false;

  //     },
  //     error: (err: any) => {
  //       this.allstocksTbl = [];
  //       this.AllStocksTable()
  //       this.searchValuequery = ""
  //       this.isFilterBtnSearch = false;
  //       this.toaster.error(err.error.message);
  //     },
  //   });
  // }

  Avgpricefilterclick() {
    this.showAvgpriceDropDown = true;
  }

  refresh() {
    this.allstocksTbl = [];
    this.lockedArray = [];
    this.isLoading = false;
    this.loadData = false;
    this.currentPage = 1;
    this.arrowrank = 'fa-arrow-up';
    this.arrowavg_price = 'fa-arrow-up';
    this.arrowshare_held = 'fa-arrow-up';
    this.arrowmarket_cap = 'fa-arrow-up';
  }

  resetFilters() {
    this.isFilterBtnResert = true;
    this.isFilter = false;
    this.isSort = false;
    this.refresh();
    this.apitype = 'defualt';
    this.AllStocksTable();
    this.clearSelectedMarket();
    this.clearSelectedPrice();
    this.clearmarketcap();
    // if (this.forResetfilters) {
    //   this.clearSelectedMarket();
    //   this.clearSelectedPrice();
    //   this.clearmarketcap();
    // }
  }

  updateAvgPriceCustomRange() {
    if (this.minCustomAvg && this.maxCustomAvg) {
      this.selectedAvgPriceRange = `${this.minCustomAvg}$ to ${this.maxCustomAvg}$`;
      this.selectedvaluecolor = 'selected-button';
    } else {
      this.selectedAvgPriceRange = null;
      this.selectedvaluecolor = '';
    }
  }

  saveFilters() {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
    const filtered = this.filterArray.filter(
      (el) => Object.keys(el).length > 0
    );

    if (this.marketCapVal) {
      // Add the object with 'market_value' key and 'mid-cap' value
      filtered.push({ market_value: this.marketCapVal });
    }

    if (filtered.length === 0) {
      this.toaster.warning('Enter Valid Inputs.');
      return;
    }
    this.refresh();
    this.isFilterBtn = true;
    this.isFilter = true;
    this.forResetfilters = true;

    this.apitype = 'filter';
    this.FilterObj = filtered.reduce((result, currentObject) => {
      for (var key in currentObject) {
        if (currentObject.hasOwnProperty(key)) {
          result[key] = currentObject[key];
        }
      }
      return result;
    }, {});

    this.filter.allStockscolumn(this.FilterObj, this.currentPage).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.totalDatalength = ParseDecryptedData[0].total[0].count;
        this.allstocksTbl = ParseDecryptedData[0].data;
        this.isFilteron = true;
        this.isFilterBtn = false;
        this.currentPage = 2;
      },
      error: (err) => {
        this.isLoading = false;
        this.isFilterBtn = false;
        this.resetFilters();

        this.toaster.error(err.error.message);
      },
    });
  }

  shareholdfilterclick() {
    this.showShareholdDropDown = true;
  }

  updateMarketSizeCustomRange() {
    if (this.minCustomMarket && this.maxCustomMarket) {
      this.selectedMarketRange = `$${this.minCustomMarket}m to $${this.maxCustomMarket}m`;
      this.selectedmarketcolor = 'selected-button';
    } else {
      this.selectedMarketRange = null;
      this.selectedmarketcolor = '';
    }
  }

  clearSelectedsharehold() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].start_share_price;
      delete this.filterArray[key].end_share_price;
    }

    this.selectedAvgPriceRange = null;
    this.selectedShareholdRange = null;
    this.minCustomValue = undefined;
    this.maxCustomValue = undefined;
    this.showShareholdDropDown = false;
  }

  pushFilterChange(data: any) {
    this.filterArray.push({ start_share_price: Number(data.target.value) });
  }

  EndpushFilterChange(data: any) {
    this.filterArray.push({ end_share_price: Number(data.target.value) });
  }

  Marketfilterclick() {
    this.isMarketDropdownOpen = true;
  }

  updateMarketCustomRange() {
    if (
      this.minCustomValue !== undefined &&
      this.maxCustomValue !== undefined
    ) {
      this.selectedMarketRange = `${this.minCustomValue}$ to ${this.maxCustomValue}$`;
    }
  }

  toggleDropdown(dropdown: any) {
    dropdown.showDropdown = !dropdown.showDropdown;
  }

  clearSelected(dropdown: any) {
    dropdown.selectedRange = null;
    dropdown.minValue = undefined;
    dropdown.maxValue = undefined;
    dropdown.filterArray = [];
    dropdown.showDropdown = false;
  }

  loadMoreData() {
    this.isLoading = true;
    this.loadData = true;

    if (this.apitype == 'filter') {
      this.filter.allStockscolumn(this.FilterObj, this.currentPage).subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);
          const ParseDecryptedData = JSON.parse(decryptData);
          ParseDecryptedData[0].data.forEach((element: any) => {
            this.allstocksTbl.push(element);
          });
          this.isLoading = false;
          this.loadData = false;
          this.currentPage++;
        },
        error: (err) => {
          this.loadData = false;
          this.isLoading = true;
          this.toaster.warning('All Record displayed');
          this.apitype = 'defualt';
        },
      });
    }

    if (this.apitype == 'defualt') {
      this.hedgeService.AllstocksData(this.currentPage).subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);
          const ParseDecryptedData = JSON.parse(decryptData);
          ParseDecryptedData[0].data.forEach((element: any) => {
            this.allstocksTbl.push(element);
          });
          this.isLoading = false;
          this.currentPage++;
        },
        error: (err) => {
          this.loadData = false;
          this.isLoading = true;
          this.toaster.warning('All Record displayed');
          this.apitype = 'defualt';
        },
      });
    }

    if (this.apitype == 'sort') {
      this.filter
        .allStocksSort(this.sortKeyVal, this.sortNumber, this.currentPage)
        .subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res.result);
            const ParseDecryptedData = JSON.parse(decryptData);
            ParseDecryptedData[0].data.forEach((element: any) => {
              this.allstocksTbl.push(element);
            });
            this.loadData = false;
            this.isLoading = false;
            this.currentPage++;
          },
          error: (err) => {
            this.loadData = false;
            this.isLoading = true;
            this.toaster.warning('All Record displayed');
            this.apitype = 'defualt';
          },
        });
    }

    if (this.isSort) {
      this.filter
        .allstockssortwithFilter(
          this.sortKeyVal,
          this.sortNumber,
          this.currentPage,
          this.FilterObj
        )
        .subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res.result);
            const ParseDecryptedData = JSON.parse(decryptData);
            this.totalDatalength = ParseDecryptedData[0].total[0].count;

            this.loadData = false;
            this.isLoading = false;
            this.currentPage++;
            ParseDecryptedData[0].data.forEach((element: any) => {
              this.allstocksTbl.push(element);
            });
          },
          error: (err) => {
            this.loadData = false;
            this.isLoading = true;
            this.toaster.warning('All Record displayed');
            this.apitype = 'defualt';
          },
        });
    }
  }

  assending(sortKey: string) {
    if (!this.productId) {
      this.modalDisplayStyle = true;
    } else {
      this.apitype = 'sort';

      this.sortNumber = this.sortKeyVal === sortKey ? this.sortNumber * -1 : 1;
      this.sortKeyVal = sortKey;
      this.refresh();

      switch (sortKey) {
        case 'rank':
          this.arrowrank =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'hedgeFunds':
          this.arrowavg_price =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'current_shares':
          this.arrowshare_held =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'current_mv':
          this.arrowmarket_cap =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        default:
          break;
      }

      if (this.isFilter === true) {
        this.apitype = 'filtersort';
        this.isSort = true;
        this.filter
          .allstockssortwithFilter(
            this.sortKeyVal,
            this.sortNumber,
            this.currentPage,
            this.FilterObj
          )
          .subscribe({
            next: (res: any) => {
              const decryptData = this.decryptionService.decrypt(res.result);
              const ParseDecryptedData = JSON.parse(decryptData);
              this.totalDatalength = ParseDecryptedData[0].total[0].count;

              ParseDecryptedData[0].data.forEach((element: any) => {
                this.allstocksTbl.push(element);
                this.currentPage = 2;
              });
            },
            error: (err) => {
              this.isLoading = false;
              this.isFilterBtn = false;
              this.refresh();
              this.AllStocksTable();
              this.toaster.error(err.error.message);
            },
          });
      } else {
        this.filter
          .allStocksSort(this.sortKeyVal, this.sortNumber, this.currentPage)
          .subscribe({
            next: (res: any) => {
              const decryptData = this.decryptionService.decrypt(res.result);
              const ParseDecryptedData = JSON.parse(decryptData);
              this.totalDatalength = ParseDecryptedData[0].total[0].count;

              ParseDecryptedData[0].data.forEach((element: any) => {
                this.allstocksTbl.push(element);
                this.currentPage = 2;
              });
            },
            error: (err) => {
              this.isLoading = false;
              this.isFilterBtn = false;
              this.refresh();
              this.AllStocksTable();
              this.toaster.error(err.error.message);
            },
          });
      }
    }
  }

  companyDetails(ticker: any) {
    this.compamyDetails.getCompanyTable(ticker).subscribe({
      next: (res: any) => {
        this.router.navigate(['company', ticker]);
      },
    });
    this.compamyDetails.getCompanyTicker(ticker);
  }

  GetChildData(data: any) {
    this.modalDisplayStyle = data;
  }

  onScroll(event: any) {
    const element = event.target as HTMLElement;
    const scrollPosition = element.scrollTop + element.clientHeight;
    const totalHeight = element.scrollHeight;
    if (this.productId) {
      if (scrollPosition >= totalHeight - 1 && !this.isLoading) {
        if (this.currentPage != 1) {
          this.loadMoreData();
        }
      }
    } else {
      let href = this.router.url;
      localStorage.setItem('route', href);
      this.modalDisplayStyle = true;
    }
  }

  pushFilterminPrice(event: any) {
    // this.selectedvaluecolor = 'selected-button';

    const minValue = Number(event.target.value);
    if (!isNaN(minValue)) {
      this.filterArray.push({ min_avg_price: minValue });
    }
  }

  pushFiltermaxPrice(event: any) {
    // this.selectedvaluecolor = 'selected-button';

    const maxValue = Number(event.target.value);
    if (!isNaN(maxValue)) {
      this.filterArray.push({ max_avg_price: maxValue });
    }
  }

  // refreshbtn() {
  //   this.refresh();
  //   this.apitype = 'defualt';
  //   this.isFilter = false
  //   this.isSort = false
  //   this.AllStocksTable();
  // }

  clearSelectedPrice() {
    this.selectedvaluecolor = '';

    for (let key in this.filterArray) {
      delete this.filterArray[key].max_avg_price;
      delete this.filterArray[key].min_avg_price;
    }

    this.selectedAvgPriceRange = null;
    this.minCustomAvg = 0;
    this.maxCustomAvg = 0;

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedMarket() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].start_mv;
      delete this.filterArray[key].end_mv;
    }

    this.selectedMarketRange = null;
    this.maxCustomMarket = 0;
    this.minCustomMarket = 0;
    this.selectedmarketcolor = '';

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearmarketcap() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].market_value;
    }
    this.marketCapVal = '';

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
  }

  pushFilterMarketStart(data: any) {
    // this.selectedmarketcolor = 'selected-button';
    this.filterArray.push({ start_mv: Number(data.target.value) * 1000000 });
  }

  pushFilterMarketEnd(data: any) {
    // this.selectedmarketcolor = 'selected-button';
    this.filterArray.push({ end_mv: Number(data.target.value) * 1000000 });
  }

  searchFilters(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;

    if (event.key === 'Escape' && !inputElement.value) {
      this.suggection = [];
      return;
    }

    if (!inputElement.value || !this.searchValuequery) {
      this.suggection = [];
      return;
    }

    this.compamyDetails.searchsuggection(inputElement.value).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.suggection = ParseDecryptedData;

        // this.symbol = res[0].symbol
      },
    });
  }

  onSearchInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;

    if (inputValue === '') {
      this.suggection = [];
    }
  }

  searchFiltersonclick(query: any) {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
    if (query == '') {
      this.toaster.warning('Enter Valid Inputs.');
      return;
    }

    let search = {
      query,
    };
    this.suggection = [];
    this.searchValuequery = '';

    this.isLoading = true;
    this.loadData = false;
    this.isFilterBtnSearch = true;
    this.forResetfilters = true;
    this.isFilter = true;

    this.allstocksTbl = [];

    this.filter.allStocksSerch(search).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);
        const ParseDecryptedData = JSON.parse(decryptData);

        this.isFilterBtnSearch = false;
        this.forResetfilters = false;
        this.totalDatalength = ParseDecryptedData[0].data.length;
        this.allstocksTbl = ParseDecryptedData[0].data;
        this.suggection = [];
        this.isLoading = true;
      },
      error: (err) => {
        this.isFilterBtnSearch = false;
        this.forResetfilters = false;
        this.searchValuequery = '';
        this.allstocksTbl = [];
        this.AllStocksTable();
        this.toaster.error(err.error.message);
      },
    });
  }
  
  setDownloadExcelTooltip(){
    if(this.userToken){
      if(this.credits! > 0){
        this.downloadExcelTooltipValue = 'Available Credits: ' + this.credits;
      } else {
        this.downloadExcelTooltipValue = 'No Credits Available';
      }
    } else {
      this.downloadExcelTooltipValue = 'Please signin to download Excel data';
    }
  }
}
