import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { LoginComponent } from '../login/login.component';
import { ProfileService } from '../../services/profile.service';
import { profile } from '../../model/profile';
import { LoginService } from '../../services/login.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  phoneNumber!: string;
  profileform!: FormGroup;
  planstatus!: string;
  planname!: string;
  credits!: number;
  resertpassword!: FormGroup;
  profiledata!: profile[];
  reserpassworddata!: any;
  selectedId!: number;
  loginId!: number;
  password!: string;
  showPassword: boolean = false;
  shownewpassword: boolean = false;
  showcancelOtp: boolean = false;
  profileId: any;
  fname: string = '';
  displayStyle = 'none';
  displayStyleForget = 'none';
  formData: any;
  id: any;
  storedPassword: string = '';
  isPasswordDisabled: boolean = true;
  formDataSubscription!: Subscription;
  isEditMode: boolean = false;
  inputshow: boolean = false;
  isEmail: boolean = false;
  requiredMode: boolean = false;
  public IsShow = false;
  fName: string = '';
  lName: string = '';
  emailValue!: string;
  showprofile: boolean = true;
  Hidepassword: boolean = false;
  showsubscription: boolean = false;
  reason: any = null;
  customereason: any;
  otpforcancelplan: any;
  cancelreasonbtn: boolean = false;
  forgetmail = '';

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private toaster: ToastrService,
    private loginData: LoginService
  ) {}

  ngOnInit() {
    this.profileform = this.fb.group({
      fname: ['', [Validators.required, this.combineNameValidator]],
      lname: ['', [Validators.required, this.combineNameValidator]],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', Validators.required],
      city: ['', Validators.required],
      stat: ['', Validators.required],
      postalcode: ['', Validators.required],
      country: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.profileform.valueChanges.subscribe((value) => {
      this.fName = value.fname;
      this.lName = value.lname;
      this.emailValue = value.email;
    });

    this.storedPassword = localStorage.getItem('password') ?? '';

    this.resertpassword = this.fb.group({
      password: ['', Validators.required],
      oldpassword: ['', Validators.required],
    });

    this.isEditMode = false;
    this.id = JSON.parse(localStorage.getItem('UserId') || '');
    if (this.id) {
      this.getloginData(this.id);
    }
  }

  combineNameValidator(control: FormControl) {
    const value = control.value;
    const isValid = value && value.split(' ').length === 2;
    return isValid ? null : { invalidName: true };
  }

  getFullName(): string {
    const fname = this.profileform.controls['fname'].value;
    const lname = this.profileform.controls['lname'].value;
    return fname && lname ? `${fname} ${lname}` : '';
  }

  // getprofileData() {
  //   let profilesId = localStorage.getItem('profileId');
  //   const profileId = JSON.parse(profilesId!);
  //   this.loginService.getId().subscribe((id) => {
  //     this.id = profileId;
  //     this.getloginData(this.id);
  //   });
  // }

  getloginData(id: string): void {
    this.profileService.getProfile(id).subscribe({
      next: (res: any) => {
        this.planstatus = res.data.status;
        this.planname = res.data.product_name;
        this.credits = res.data.credits;
        this.profileform.patchValue(res.data);
      },
    });
  }

  updateProfileData(): void {
    this.inputshow = false;
    this.isPasswordDisabled = true;
    this.isEditMode = !this.isEditMode;
    const localId = localStorage.getItem('UserId');
    const profileId = JSON.parse(localId!);
    this.profiledata = this.profileform.value;

    this.profileService.postProfile(this.profiledata, profileId).subscribe({
      next: (res: any) => {
        localStorage.setItem('profileData', JSON.stringify(this.profiledata));
        localStorage.setItem('profileId', JSON.stringify(res.data._id));

        this.toaster.success(res.msg);
        setTimeout(() => {}, 3000);
      },
      error: (error) => {
        setTimeout(() => {}, 3000);
      },
    });
  }

  resetPasswordVisibility() {
    this.showPassword = false;
    this.shownewpassword = false;
  }

  openPopup() {
    this.profileService.cancelotpsent().subscribe((res) => {
      this.toaster.success(res.message);
      this.displayStyle = 'block';
    });
  }

  closePopup() {
    this.displayStyle = 'none';
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  toggleNewPasswordVisibility() {
    this.shownewpassword = !this.shownewpassword;
  }
  togglecancelPasswordVisibility() {
    this.showcancelOtp = !this.showcancelOtp;
  }

  savePopup() {
    this.reserpassworddata = this.resertpassword.value;

    if (this.resertpassword.valid) {
      this.profileService
        .resetpassword(this.reserpassworddata, this.id)
        .subscribe({
          next: (res: any) => {
            this.toaster.success(res.message);
          },
          error: (error) => {
            this.toaster.error(error);
          },
        });
    }

    this.closePopup();
  }

  cancelplan() {
    var cancelreason = '';

    this.reason == 'Other'
      ? (cancelreason = this.customereason)
      : (cancelreason = this.reason);

    const data = {
      reasonForCancellation: cancelreason,
      cancelSubOtp: this.otpforcancelplan,
    };

    if (
      data.cancelSubOtp == '' ||
      (data.cancelSubOtp == null && data.reasonForCancellation == '') ||
      data.reasonForCancellation == null
    ) {
      this.toaster.warning('Fill the required fields');
      return;
    }

    this.cancelreasonbtn = true;

    this.profileService.cancelplan(data).subscribe({
      next: (res: any) => {
        this.toaster.success(res.message);
        this.cancelreasonbtn = false;
        this.otpforcancelplan = '';
        this.reason = null;
        this.customereason = '';
        this.getloginData(this.id);

        this.displayStyle = 'none';
      },
      error: (error) => {
        this.cancelreasonbtn = false;
        this.otpforcancelplan = '';
        this.reason = null;
        this.customereason = '';
        this.toaster.error(error);
      },
    });
  }

  enableEditMode() {
    this.isEditMode = true;
    this.inputshow = true;
  }

  cancelData() {
    this.profileform.reset();
    this.isEditMode = false;
    this.inputshow = false;
    this.getloginData(this.id);
  }

  resetPassword() {
    this.showprofile = false;
    this.showsubscription = false;
    this.Hidepassword = true;
    this.isEditMode = false;
    this.inputshow = false;
    this.resertpassword.reset();
  }

  personaldata() {
    this.showprofile = true;
    this.Hidepassword = false;
    this.showsubscription = false;
  }

  showsub() {
    this.showprofile = false;
    this.Hidepassword = false;
    this.isEditMode = false;
    this.inputshow = false;
    this.showsubscription = true;
  }

  selectcancelplan(event: any) {
    this.reason = event.target.value;
  }

  ForgetPass() {
    if (!this.forgetmail) {
      this.toaster.warning('Email is required!');
      return;
    }

    let Email = {
      email: this.forgetmail,
    };

    this.loginData.forgetpassword(Email).subscribe({
      next: (response) => {
        this.toaster.success(response.message);
        this.displayStyleForget = 'none';
        this.forgetmail = '';
      },
      error: (error) => {
        this.toaster.error(error);
        this.forgetmail = '';
      },
    });
    this.closePopup();
  }

  openforgetpass() {
    this.displayStyleForget = 'block';
  }
  closeforgetPop() {
    this.displayStyleForget = 'none';
    return;
  }
}
