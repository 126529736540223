<div class="container-fluid d-flex">
  <div
    class="offcanvas-lg offcanvas-end"
    tabindex="-1"
    id="offcanvasResponsive"
    aria-labelledby="offcanvasResponsiveLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasResponsiveLabel">
        Responsive offcanvas
      </h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        data-bs-target="#offcanvasResponsive"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <div class="sidebarDiv">
        <button class="sidebutton">
          <i class="fa-solid fa-users pe-2"></i>Users
        </button>
        <button class="sidebutton">Feedbacks</button>
      </div>
    </div>
  </div>

  <div class="main_section">
    <form>
      <button
        class="d-lg-none"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasResponsive"
        aria-controls="offcanvasResponsive"
        style="
          border: 1px solid rgb(196, 193, 193);
          border-radius: 100%;
          padding: 0px;
          align-items: center;
          margin-right: 10px;
        "
      >
        <i
          style="
            opacity: unset;
            font-size: 20px;
            color: var(--clr-primary);
            margin: 6px;
          "
          class="fa-solid fa-bars"
        ></i>
      </button>
      <input
        autocomplete="off"
        (keyup)="searchFilters($event)"
        (input)="onSearchInput($event)"
        [(ngModel)]="searchValuequery"
        #searchVal
        type="search"
        class="input_box"
        placeholder="Enter email,first or last name"
        name="search2"
      />
      <button
        (click)="performSearch(searchValuequery)"
        class="searchquerybtn"
        type="button"
      >
        <i class="fa-solid fa-magnifying-glass"></i>
      </button>
    </form>

    <ng-template #tooltipTemplateBuyer let-data="data">
      <div class="m-1">
        <i class="table-font-italic"
          >{{ data.fname || "" }} {{ data.lname || "" }}</i
        ><br />
        @if(data.mobile){
        <i class="table-font-italic">{{ data.mobile }}</i
        ><br />} Since -
        <i class="table-font-italic">{{ data.date | date : "d MMM yy" }}</i>
      </div>
    </ng-template>

    <div
      id="dataContainer"
      style="max-height: 70vh"
      class="Short-table table-responsive all-table-scroll"
      (scroll)="onScroll($event)"
    >
      <table class="table table-fixed table-lock-height mb-0">
        <thead class="sticky-header head-z-index">
          <tr class="text-uppercase table-header">
            <th
              class="px-1 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
            >
              index
            </th>
            <th class="px-3 text-center py-3 sticky-ticker head-z-index">
              email
            </th>
            <th
              class="px-4 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
            >
              fname
            </th>
            <th
              class="px-2 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
            >
              lname
            </th>
            <th
              class="px-3 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
            >
              p plane
            </th>
          </tr>
        </thead>

        <tbody style="font-size: medium; overflow-y: auto">
          @for (data of users; track $index) {

          <tr>
            <td style="vertical-align: middle">
              <div class="text-center">
                <p class="mb-0 text-center py-1">
                  {{ $index + 1 }}
                </p>
              </div>
            </td>
            <td
              style="vertical-align: middle"
              [ngbTooltip]="tooltipTemplateBuyer"
              [tooltipContext]="{ data: data }"
              container="body"
            >
              <div class="text-center">
                <p class="mb-0 text-center py-1">
                  {{ data.email }}@if(data.isverified===0){<sup>*</sup>}
                </p>
              </div>
            </td>
            <td class="" style="vertical-align: middle">
              <div class="text-center">
                <p class="mb-0 text-center">
                  {{ data.fname }}
                </p>
              </div>
            </td>
            <td class="" style="vertical-align: middle">
              <div class="text-center">
                <p class="mb-0 text-center">
                  {{ data.lname }}
                </p>
              </div>
            </td>
            <td class="" style="vertical-align: middle">
              @if(data.productPlan){
              <p class="m-0 text-nowrap text-center">
                {{ data.productPlan?.product_name || "-" }}
              </p>
              <p class="m-0 text-nowrap text-center">
                {{ data.productPlan?.status || "-" }}
              </p>
              <p class="m-0 text-nowrap text-center">
                {{ data.productPlan?.created_at | date : "d MMM yy" }} -
                {{
                  data.productPlan.trial_ends_at || data.productPlan.renews_at
                    | date : "d MMM yy"
                }}
              </p>
              }@else {
              <p class="mb-0 text-center">-</p>
              }
            </td>

            <!-- <td class="" style="vertical-align: middle">
              <div
                class="px-2"
                style="
                  align-items: center;
                  display: flex;
                  justify-content: center;
                "
              >
                <div class="table-description-text text-center">
                  @if (data.title) {
                  <a class="text-dark" style="text-decoration: none">
                    {{ data.title.substring(0, 25) }}...
                  </a>
                  } @else {
                  <span>-</span>
                  } @if (data.title && data.title.length > 25) {
                  <p
                    style="cursor: pointer"
                    class="text-danger text-center m-0"
                    placement="left"
                    container="body"
                    ngbTooltip="{{ data.title }}"
                    triggers="click:blur"
                  >
                    Read More+
                  </p>
                  }
                </div>
              </div>
            </td> -->
          </tr>

          }@empty {
          <tr style="box-shadow: none">
            <td colspan="17">
              <ngx-skeleton-loader
                [theme]="{ height: '50px',  'border-radius': '4px',  }"
                count="9"
              >
              </ngx-skeleton-loader>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>
