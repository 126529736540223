<nav
  class="p-1 navbar bg-light navbar-expand-lg"
  (activesearchincompanypage)="getData($event)"
>
  <div class="container-fluid main">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <img
        routerLink=""
        src="assets/images/company-layout2-logo.svg"
        alt=""
        class="logo-width me-4 cursorpointer"
      />
      <div class="justify-content-center align-items-center d-flex">
        @if (loggedin()) {

        <div class="dropdown">
          <button
            data-bs-toggle="dropdown"
            class="navbar-toggler rounded-circle p-0 me-2"
          >
            <i
              style="
                opacity: unset;
                margin: 8px 10px 8px 10px;
                font-size: 20px;
                color: var(--clr-primary);
              "
              class="fa-solid fa-user"
            ></i>
          </button>
          <ul class="dropdown-menu forprofile">
            <li class="text-center">
              <a class="dropdown-item py-1" routerLink="profile">Profile</a>
            </li>

            <li class="text-center">
              <a (click)="onLogout()" class="dropdown-item py-1">Log Out</a>
            </li>
          </ul>
        </div>
        <a
          routerLink="myportfolio"
          class="bg-light navbar-toggler rounded-circle rounded-circle p-0 px-1 me-2"
          style="border: 1.5px solid gainsboro"
        >
          <i
            style="
              opacity: unset;
              color: var(--clr-primary);
              margin: 8px 6px 8px 6px;
              font-size: 20px;
            "
            class="fa-solid fa-briefcase"
          ></i>
        </a>
        <a
          (click)="this.getNotificationlenUpdate()"
          data-bs-toggle="offcanvas"
          href="#notificationBar"
          class="bg-light navbar-toggler rounded-circle rounded-circle p-0 px-1 me-2"
          style="border: 1.5px solid gainsboro; padding: 0 4px 0 3px"
        >
          <i
            style="
              opacity: unset;
              color: var(--clr-primary);
              margin: 8px 6px 8px 6px;
              font-size: 20px;
            "
            class="fa-solid fa-bell"
          >
            @if(notificationLen > 0) {
            <span
              class="py-1 px-2"
              style="
                font-size: 13px;
                position: absolute;
                background-color: #f23645;
                border-radius: 50%;
                top: 5px;
                color: white;
                font-weight: 400;
              "
              >{{ notificationLen }}</span
            >}</i
          >
        </a>
        }
        <button
          class="navbar-toggler rounded-circle p-0"
          data-bs-toggle="offcanvas"
          href="#offcanvasExample"
          role="button"
          aria-controls="offcanvasExample"
        >
          <i
            style="
              opacity: unset;
              margin: 8px 10px 8px 10px;
              font-size: 20px;
              color: var(--clr-primary);
            "
            class="fa-solid fa-bars"
          ></i>
        </button>
      </div>
    </div>

    <div class="m-discod-icon">
      <div style="display: grid" class="position-relative">
        <form class="example d-flex add_m_infilter justify-content-center">
          <input
            autocomplete="off"
            (keyup)="searchFilters($event)"
            (input)="onSearchInput($event)"
            [(ngModel)]="searchValuequery"
            #searchVal
            type="search"
            style="width: 100% !important"
            class="input-box"
            placeholder="Search by Comapny name or Ticker"
            name="search2"
          />
          <button
            (click)="companyDetails(this.symbol)"
            class="searchquerybtn text-nowrap"
            type="button"
          >
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </form>
        <div *ngIf="suggection?.length > 0" class="">
          <ul class="list-unstyled m-0">
            <li
              class="suggestion-item p-0 cursorpointer"
              *ngFor="let suggestion of suggection"
            >
              <div
                (click)="companyDetails(suggestion.symbol)"
                class="d-flex filter-bottom justify-content-between px-2"
              >
                <span>
                  @if (suggestion.logoid !== '') {
                  <img
                    width="25px"
                    [src]="basePath + suggestion.logoid + '.svg'"
                  />
                  }@else {
                  <span
                    class="avatarcss"
                    style="
                      width: 25px !important;
                      height: 25px !important;
                      margin: 0 !important;
                    "
                    >{{ suggestion.name | initialLetter }}</span
                  >
                  }
                </span>
                <p class="text-start filter-font-text mb-1 border-1 me-4">
                  {{ suggestion.name }}
                </p>
                <p class="text-end filter-font-text mb-1">
                  {{ suggestion.symbol }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="btn-right">
      <div
        class="collapse navbar-collapse justify-content-between"
        id="navbarColor03"
        [ngbCollapse]="isCollapsed"
      >
        <ul
          class="navbar-nav mr-auto menu-top-set"
          [class.collapsed]="isCollapsed"
        >
          <li class="nav-item text-nowrap dropdown">
            <div
              class="hover-eff"
              role="button"
              data-bs-toggle="dropdown-Catalyst"
              aria-expanded="false"
            >
              <img
                [src]="activeRouteCalendar"
                alt=""
                height="18"
                class="mb-1"
              />
              <a
                [ngClass]="{ 'active-text': CatalystActive }"
                class="nav-link text-nowrap main-header dropdown-toggle"
                >Catalyst Calendar</a
              >
              <ul class="dropdown-menu">
                <div class="d-flex">
                  <div class="mx-2">
                    <div class="dropdown-item" routerLink="fda-calendar">
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                          class="text-dark img-fluid"
                          style="width: 20px"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                          ></path>
                        </svg>
                        FDA Calendar
                      </a>
                      <p class="p-2 text-start m-0">
                        Track key readouts and FDA decision dates in biotech and
                        pharma.
                      </p>
                    </div>
                    <div class="dropdown-item" routerLink="biotechearnings">
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img
                          width="20px"
                          src="assets/images/biotechearning.svg"
                          alt=""
                        />
                        Biotech Earning Calendar
                      </a>
                      <p class="p-2 text-start m-0">
                        Stay updated on biotech earnings announcements.
                      </p>
                    </div>
                    <div class="dropdown-item" routerLink="PDUFA-calendar">
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img
                          width="20px"
                          src="assets/images/puda_calender.svg"
                          alt=""
                        />
                        PDUFA Calendar
                      </a>
                      <p class="p-2 text-start m-0">
                        Monitor upcoming PDUFA dates and FDA approval dates.
                      </p>
                    </div>
                  </div>

                  <div class="mx-2">
                    <div class="dropdown-item" routerLink="market-movers">
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img
                          width="20px"
                          src="assets/images/market-movers.svg"
                          alt=""
                        />
                        Market Movers</a
                      >
                      <p class="p-2 text-start m-0">
                        Highlights top stocks, volume, and news movers.
                      </p>
                    </div>
                    <div
                      class="dropdown-item"
                      routerLink="historical-pdufa-calender"
                    >
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img
                          width="20px"
                          src="assets/images/historical_dates.svg"
                          alt=""
                        />
                        Historical PDUFA Dates</a
                      >
                      <p class="p-2 text-start m-0">
                        Reference past PDUFA decisions.
                      </p>
                    </div>
                    <div class="dropdown-item" routerLink="conference-calendar">
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img
                          width="20px"
                          src="assets/images/Conference_Calendar.svg"
                          alt=""
                        />
                        Conference Calendar</a
                      >
                      <p class="p-2 text-start m-0">
                        Keep up with biotech and pharma conferences.
                      </p>
                    </div>
                  </div>
                  <div class="mx-2">
                    <div class="dropdown-item" routerLink="ipo-sec">
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img
                          width="20px"
                          src="assets/images/ipo_5166059.svg"
                          alt=""
                        />
                        IPO</a
                      >
                      <p class="p-2 text-start m-0">Upcoming and Past IPOs.</p>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </li>

          <li class="nav-item text-nowrap dropdown">
            <div
              class="hover-eff"
              role="button"
              data-bs-toggle="dropdown-Company"
              aria-expanded="false"
            >
              <img
                [src]="activeRouteScreener"
                alt=""
                height="18"
                class="mb-1"
              />
              <a
                [ngClass]="{ 'active-text': ScreenerActive }"
                class="nav-link text-nowrap main-header dropdown-toggle"
                >Bio/Pharma Screener</a
              >
              <ul class="dropdown-menu">
                <div class="d-flex">
                  <div class="mx-2">
                    <div class="dropdown-item" routerLink="catalyst-sync">
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img
                          width="20px"
                          src="assets/images/catalyst_sync.svg"
                          alt=""
                        />
                        Catalyst Sync™</a
                      >
                      <p class="p-2 text-start m-0">
                        Track biotech catalysts ahead of the competition.
                      </p>
                    </div>
                    <div class="dropdown-item" routerLink="company-screener">
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img
                          width="20px"
                          src="assets/images/company_screener.svg"
                          alt=""
                        />
                        Company Screener</a
                      >
                      <p class="p-2 text-start m-0">
                        Holistic company health and drug pipeline analysis.
                      </p>
                    </div>
                    <div class="dropdown-item" routerLink="inside-trades">
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img
                          width="20px"
                          src="assets/images/inside_trades.svg"
                          alt=""
                        />
                        Inside Trades
                      </a>
                      <p class="p-2 text-start m-0">
                        Monitor insider trading in biopharma stocks.
                      </p>
                    </div>
                  </div>
                  <div class="mx-2">
                    <div class="dropdown-item" routerLink="hedgefunds">
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img
                          width="20px"
                          src="assets/images/hedge_fund.svg"
                          alt=""
                        />
                        Hedge Fund Screener</a
                      >
                      <p class="p-2 text-start m-0">
                        Analyze hedge fund stock holdings in biotech and pharma.
                      </p>
                    </div>
                    <div class="dropdown-item" routerLink="allstockshold">
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img
                          width="20px"
                          src="assets/images/all_stocks.svg"
                          alt=""
                        />
                        All Stocks Holdings</a
                      >
                      <p class="p-2 text-start m-0">
                        Explore comprehensive hedge fund stock data, <br />
                        focusing on biotech and pharma.
                      </p>
                    </div>
                    <div class="dropdown-item" routerLink="heatmap">
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img
                          width="20px"
                          src="assets/images/heat_map.svg"
                          alt=""
                        />
                        Heat-Map
                      </a>
                      <p class="p-2 text-start m-0">
                        Heat-Map according to Market Size and Sector.
                      </p>
                    </div>
                  </div>
                  <div class="mx-2">
                    <div
                      class="dropdown-item"
                      routerLink="bio-hedge-fund-shots"
                    >
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img
                          width="20px"
                          src="assets/images/bpw-portfolio.svg"
                          alt=""
                        />
                        BPW Portfolio</a
                      >

                      <p class="p-2 text-start m-0">
                        Bio Hedge Fund Shots and more...
                      </p>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </li>

          <li class="nav-item text-nowrap dropdown">
            <div
              class="hover-eff"
              role="button"
              data-bs-toggle="dropdown-Company"
              aria-expanded="false"
            >
              <img [src]="activeRoutehedge" alt="" height="18" class="mb-1" />
              <a
                [ngClass]="{ 'active-text': hedgeActive }"
                class="nav-link text-nowrap main-header dropdown-toggle"
                >Resources</a
              >
              <ul class="dropdown-menu">
                <div class="d-flex">
                  <div class="mx-2">
                    <div class="dropdown-item" routerLink="news">
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img width="20px" src="assets/images/news.svg" alt="" />
                        News</a
                      >
                      <p class="p-2 text-start m-0">
                        Latest News by event and clinical trials.
                      </p>
                    </div>
                    <!-- <div class="dropdown-item" routerLink="daily-updates">
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img
                          width="20px"
                          src="assets/images/dailyUpdates.svg"
                          alt="daily updates icon"
                        />
                        Daily Updates</a
                      >
                      <p class="p-2 text-start m-0">
                        Daily updates about Unusual volumes, Top insiders, News
                        volatility.
                      </p>
                    </div> -->
                    <div class="dropdown-item" routerLink="blog">
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img
                          width="20px"
                          src="assets/images/blog_icon.svg"
                          alt=""
                        />
                        Blogs</a
                      >
                      <p class="p-2 text-start m-0">
                        Read expert analyses and opinions on market trends.
                      </p>
                    </div>
                    <div class="dropdown-item">
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                        href="https://discord.gg/vbr3w4nwx5"
                      >
                        <img
                          width="20px"
                          src="assets/images/discord_3670325.svg"
                          alt=""
                        />
                        Discord
                        <p class="p-2 text-start m-0">
                          Connect with other Biotech Traders and Investor.
                        </p>
                      </a>
                    </div>
                  </div>
                  <div class="mx-2">
                    <div class="dropdown-item" routerLink="website-user">
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img
                          width="20px"
                          src="assets/images/userguied non-hover icon.svg"
                          alt=""
                        />
                        User Guide</a
                      >
                      <p class="p-2 text-start m-0">
                        Step by Step guide to use the platform.
                      </p>
                    </div>
                    <div
                      class="dropdown-item"
                      routerLink="investing-masterclass"
                    >
                      <a
                        class="p-2 text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img
                          width="20px"
                          src="assets/images/money_10243595.svg"
                          alt=""
                        />
                        Biotech Investing 101</a
                      >
                      <p class="p-2 text-start m-0">
                        Learn step by step Biotech Investing.
                      </p>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </li>
          <li class="nav-item text-nowrap">
            <div class="hover-eff" routerLink="subscription">
              <img [src]="activeRoutenews" alt="" height="18" class="mb-1" />
              <a
                [ngClass]="{ 'active-text': heatActive }"
                class="nav-link text-nowrap main-header"
                routerLinkActive="active"
                >Pricing</a
              >
            </div>
          </li>
          @if(role === "admin"){
          <li class="nav-item text-nowrap dropdown">
            <div
              class="hover-eff"
              role="button"
              data-bs-toggle="dropdown-Company"
              aria-expanded="false"
            >
              <img
                [src]="profileSue"
                alt=""
                height="18"
                class="mb-1"
                routerLink="admin"
              />
              <a
                [ngClass]="{ 'active-text': adminActive }"
                class="nav-link text-nowrap main-header dropdown-toggle"
                >Dashboard</a
              >
              <ul class="dropdown-menu">
                <div class="d-flex">
                  <div class="mx-2">
                    <div class="dropdown-item" routerLink="fda-calender-admin">
                      <a
                        class="text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                          class="text-dark img-fluid"
                          style="width: 20px"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                          ></path>
                        </svg>
                        FDA calender admin
                        <p class="p-2 text-start m-0">
                          Insert, update and delete fda calender data.
                        </p>
                      </a>
                    </div>
                    <div class="dropdown-item" routerLink="blog-admin">
                      <a
                        class="text-start dropdown-text"
                        routerLinkActive="active-link"
                      >
                        <img
                          width="20px"
                          src="assets/images/blog_icon.svg"
                          alt=""
                        />
                        Insert blog</a
                      >
                      <p class="p-2 text-start m-0">Insert new blogs.</p>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </li>
          }
        </ul>

        <div
          class="text-end button-set d-flex justify-content-center align-items-center column-gap-2"
        >
          <div style="display: grid" class="position-relative hide_l_btns me-2">
            <div class="d-flex from_div align-items-center">
              <form class="example d-flex add_m_infilter">
                <input
                  autocomplete="off"
                  (input)="onSearchInput($event)"
                  (keyup)="searchFilters($event)"
                  [(ngModel)]="searchValuequery"
                  #searchVal
                  type="search"
                  [ngClass]="{
                    'input-box': loggedin(),
                    'input-box_wLogin': !loggedin()
                  }"
                  class="input-box"
                  placeholder="Search by Comapny name or Ticker"
                  name="search2"
                />
                <button
                  (click)="companyDetails(this.symbol)"
                  class="searchquerybtn"
                  type="button"
                >
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button>
              </form>
            </div>
            <div *ngIf="suggection?.length > 0" class="">
              <ul class="list-unstyled m-0">
                <li
                  class="suggestion-item p-0 cursorpointer"
                  *ngFor="let suggestion of suggection"
                >
                  <div
                    (click)="companyDetails(suggestion.symbol)"
                    class="d-flex filter-bottom justify-content-between px-2"
                  >
                    <span>
                      @if (suggestion.logoid !== '') {
                      <img
                        width="25px"
                        [src]="basePath + suggestion.logoid + '.svg'"
                      />
                      }@else {
                      <span
                        class="avatarcss"
                        style="
                          width: 25px !important;
                          height: 25px !important;
                          margin: 0 !important;
                        "
                        >{{ suggestion.name | initialLetter }}</span
                      >
                      }
                    </span>
                    <p class="text-start filter-font-text mb-1 border-1 me-4">
                      {{ suggestion.name }}
                    </p>
                    <p class="text-end filter-font-text mb-1">
                      {{ suggestion.symbol }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          @if(loggedin()){
          <!-- profile -->
          <div
            class="dropdown hide_l_btns d-flex flex-column align-items-center"
            style="height: 56px"
          >
            <button
              class="rounded-circle bg-light d-flex justify-content-center align-items-center"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style="border: 1.5px solid gainsboro; height: 40px; width: 40px"
            >
              <i
                style="
                  opacity: unset;
                  font-size: 20px;
                  color: var(--clr-primary);
                "
                class="fa-solid fa-user"
              ></i>
            </button>
            <p style="font-size: 12px" class="m-0 text-center">Profile</p>

            <ul class="dropdown-menu forprofile">
              <li class="text-center">
                <a class="dropdown-item py-1" routerLink="profile">Profile</a>
              </li>

              <li class="text-center">
                <a (click)="onLogout()" class="dropdown-item cursorpointer py-1"
                  >Log Out</a
                >
              </li>
            </ul>
          </div>
          <!-- my portfolio -->
          <div
            class="d-flex flex-column align-items-center"
            style="height: 56px"
          >
            <a
              routerLink="myportfolio"
              class="bg-light hide_l_btns rounded-circle d-flex justify-content-center align-items-center"
              style="
                border: 1.5px solid gainsboro;
                height: 40px;
                width: 40px;
                text-decoration: none;
              "
            >
              <i
                style="
                  opacity: unset;
                  color: var(--clr-primary);
                  font-size: 20px;
                "
                class="fa-solid fa-briefcase"
              ></i>
            </a>
            <p style="font-size: 12px" class="m-0 text-center">My Portfolio</p>
          </div>
          <!-- notification -->
          <div
            (click)="this.getNotificationlenUpdate()"
            class="d-flex flex-column align-items-center"
            style="height: 56px"
          >
            <a
              data-bs-toggle="offcanvas"
              href="#notificationBar"
              class="bg-light hide_l_btns rounded-circle d-flex justify-content-center align-items-center"
              style="
                border: 1.5px solid gainsboro;
                width: 40px;
                height: 40px;
                text-decoration: none;
              "
            >
              <i
                style="
                  opacity: unset;
                  color: var(--clr-primary);
                  font-size: 20px;
                "
                class="fa-solid fa-bell"
              >
                @if (notificationLen > 0) {
                <span
                  class="py-1 px-2"
                  style="
                    font-size: 13px;
                    position: absolute;
                    margin-left: 13px;
                    background-color: #f23645;
                    border-radius: 50%;
                    top: 3px;
                    right: 35px;
                    color: white;
                    font-weight: 400;
                  "
                  >{{ notificationLen }}</span
                >}
              </i>
            </a>
            <p style="font-size: 12px" class="m-0 text-center">Notification</p>
          </div>

          } @if(!loggedin()){
          <button
            routerLink="login"
            type="button"
            class="btn-primary-white mx-1"
          >
            Sign In
          </button>

          <button
            routerLink="signup"
            type="button"
            class="btn-primary-green mx-1"
          >
            Start Free Trial
          </button>

          }@else { }
        </div>
      </div>
    </div>
  </div>
</nav>
<div
  class="offcanvas offcanvas-end"
  tabindex="-1"
  id="offcanvasExample"
  aria-labelledby="offcanvasExampleLabel"
>
  <div class="offcanvas-header">
    <img
      src="assets/images/company-layout2-logo.svg"
      alt=""
      width="140px"
      class="me-4 cursorpointer"
    />
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
  </div>
  <div class="offcanvas-body p-0">
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            <b>Catalyst Calendar</b>
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body p-0">
            <div>
              <div
                routerLink="fda-calendar"
                data-bs-toggle="offcanvas"
                class="dropdown-item text-wrap"
              >
                <a class="p-1 text-start accordion-menu-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="text-dark img-fluid"
                    style="width: 20px"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                    ></path>
                  </svg>
                  FDA Calendar
                </a>
                <p class="p-1 text-start m-0">
                  Track key readouts and FDA decision dates in biotech and
                  pharma.
                </p>
              </div>
              <div
                data-bs-toggle="offcanvas"
                routerLink="biotechearnings"
                class="dropdown-item text-wrap"
              >
                <a class="p-1 text-start accordion-menu-text">
                  <img
                    width="20px"
                    src="assets/images/biotechearning.svg"
                    alt=""
                  />
                  Biotech Earning Calendar
                </a>
                <p class="p-1 text-start m-0">
                  Stay updated on biotech earnings announcements.
                </p>
              </div>
              <div
                data-bs-toggle="offcanvas"
                routerLink="PDUFA-calendar"
                class="dropdown-item text-wrap"
              >
                <a class="p-1 text-start accordion-menu-text">
                  <img
                    width="20px"
                    src="assets/images/puda_calender.svg"
                    alt=""
                  />
                  PDUFA Calendar
                </a>
                <p class="p-1 text-start m-0">
                  Monitor upcoming PDUFA dates and FDA approval dates.
                </p>
              </div>
              <div
                data-bs-toggle="offcanvas"
                routerLink="market-movers"
                class="dropdown-item text-wrap"
              >
                <a
                  class="p-1 text-start accordion-menu-text"
                  routerLinkActive="active-link"
                >
                  <img
                    width="20px"
                    src="assets/images/market-movers.svg"
                    alt=""
                  />
                  Market Movers</a
                >
                <p class="p-1 text-start m-0">
                  Highlights top stocks, volume, and news movers.
                </p>
              </div>
              <div
                data-bs-toggle="offcanvas"
                routerLink="historical-pdufa-calender"
                class="dropdown-item text-wrap"
              >
                <a class="p-1 text-start accordion-menu-text">
                  <img
                    width="20px"
                    src="assets/images/historical_dates.svg"
                    alt=""
                  />
                  Historical PDUFA Dates</a
                >
                <p class="p-1 text-start m-0">
                  Reference past PDUFA decisions.
                </p>
              </div>
              <div
                data-bs-toggle="offcanvas"
                routerLink="conference-calendar"
                class="dropdown-item text-wrap"
              >
                <a
                  class="p-1 text-start accordion-menu-text"
                  routerLinkActive="active-link"
                >
                  <img
                    width="20px"
                    src="assets/images/Conference_Calendar.svg"
                    alt=""
                  />
                  Conference Calendar</a
                >
                <p class="p-1 text-start m-0">
                  Keep up with biotech and pharma conferences.
                </p>
              </div>
              <div
                data-bs-toggle="offcanvas"
                routerLink="ipo-sec"
                class="dropdown-item text-wrap"
              >
                <a
                  class="p-1 text-start accordion-menu-text"
                  routerLinkActive="active-link"
                >
                  <img
                    width="20px"
                    src="assets/images/ipo_5166059.svg"
                    alt=""
                  />
                  IPO</a
                >
                <p class="p-1 text-start m-0">Upcoming and Past IPOs.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseTwo"
            aria-expanded="false"
            aria-controls="flush-collapseTwo"
          >
            <b>Bio/Pharma Screener</b>
          </button>
        </h2>
        <div
          id="flush-collapseTwo"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body p-0">
            <div>
              <div
                data-bs-toggle="offcanvas"
                routerLink="catalyst-sync"
                class="dropdown-item text-wrap"
              >
                <a class="p-1 text-start accordion-menu-text">
                  <img
                    width="20px"
                    src="assets/images/catalyst_sync.svg"
                    alt=""
                  />
                  Catalyst Sync™
                </a>
                <p class="p-1 text-start m-0">
                  Track biotech catalysts ahead of the competition.
                </p>
              </div>
              <div
                data-bs-toggle="offcanvas"
                routerLink="company-screener"
                class="dropdown-item text-wrap"
              >
                <a
                  class="p-1 text-start accordion-menu-text"
                  routerLinkActive="active-link"
                >
                  <img
                    width="20px"
                    src="assets/images/company_screener.svg"
                    alt=""
                  />
                  Company Screener
                </a>
                <p class="p-1 text-start m-0">
                  Holistic company health and drug pipeline analysis.
                </p>
              </div>
              <div
                data-bs-toggle="offcanvas"
                routerLink="inside-trades"
                class="dropdown-item text-wrap"
              >
                <a
                  class="p-1 text-start accordion-menu-text"
                  routerLinkActive="active-link"
                >
                  <img
                    width="20px"
                    src="assets/images/inside_trades.svg"
                    alt=""
                  />
                  Inside Trades
                </a>
                <p class="p-1 text-start m-0">
                  Monitor insider trading in biopharma stocks.
                </p>
              </div>
              <div
                routerLink="hedgefunds"
                data-bs-toggle="offcanvas"
                class="dropdown-item text-wrap"
              >
                <a
                  class="p-1 text-start accordion-menu-text"
                  routerLinkActive="active-link"
                >
                  <img width="20px" src="assets/images/hedge_fund.svg" alt="" />
                  Hedge Fund Screener</a
                >
                <p class="p-1 text-start m-0">
                  Analyze hedge fund stock holdings in biotech and pharma.
                </p>
              </div>
              <div
                data-bs-toggle="offcanvas"
                routerLink="allstockshold"
                class="dropdown-item text-wrap"
              >
                <a
                  class="p-1 text-start accordion-menu-text"
                  routerLinkActive="active-link"
                >
                  <img width="20px" src="assets/images/all_stocks.svg" alt="" />
                  All Stocks Holdings</a
                >
                <p class="p-1 text-start m-0">
                  Explore comprehensive hedge fund stock data, focusing on
                  biotech and pharma.
                </p>
              </div>
              <div
                routerLink="heatmap"
                data-bs-toggle="offcanvas"
                class="dropdown-item text-wrap"
              >
                <a
                  class="p-1 text-start accordion-menu-text"
                  routerLinkActive="active-link"
                >
                  <img width="20px" src="assets/images/heat_map.svg" alt="" />
                  Heat-Map</a
                >
                <p class="p-1 text-start m-0">
                  Heat-Map according to Market Size and Sector.
                </p>
              </div>
              <div
                routerLink="bio-hedge-fund-shots"
                data-bs-toggle="offcanvas"
                class="dropdown-item text-wrap"
              >
                <a
                  class="p-1 text-start accordion-menu-text"
                  routerLinkActive="active-link"
                >
                  <!-- <img width="20px" src="assets/images/heat_map.svg" alt="" /> -->
                  Bio Hedge Fund Shots</a
                >
                <p class="p-1 text-start m-0">
                  Bio Hedge Fund Shots and more...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseThree"
            aria-expanded="false"
            aria-controls="flush-collapseThree"
          >
            <b>Resources</b>
          </button>
        </h2>
        <div
          id="flush-collapseThree"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body p-0">
            <div
              data-bs-toggle="offcanvas"
              routerLink="news"
              class="dropdown-item text-wrap"
            >
              <a
                class="p-1 text-start accordion-menu-text"
                routerLinkActive="active-link"
              >
                <img width="20px" src="assets/images/news.svg" alt="" />
                News
              </a>
              <p class="p-1 text-start m-0">
                Latest News by event and clinical trials.
              </p>
            </div>
            <!-- <div
              data-bs-toggle="offcanvas"
              routerLink="daily-updates"
              class="dropdown-item text-wrap"
            >
              <a
                class="p-1 text-start accordion-menu-text"
                routerLinkActive="active-link"
              >
                <img
                  width="20px"
                  src="assets/images/dailyUpdates.svg"
                  alt="daily-updates icon"
                />
                Daily updates
              </a>
              <p class="p-1 text-start m-0">
                Daily updates about Unusual volumes, Top insiders, News
                volatility.
              </p>
            </div> -->
            <div
              data-bs-toggle="offcanvas"
              routerLink="blog"
              class="dropdown-item text-wrap"
            >
              <a
                class="p-1 text-start accordion-menu-text"
                routerLinkActive="active-link"
              >
                <img width="20px" src="assets/images/blog_icon.svg" alt="" />
                Blogs
              </a>
              <p class="p-1 text-start m-0">
                Read expert analyses and opinions on market trends.
              </p>
            </div>
            <div
              data-bs-toggle="offcanvas"
              routerLink="website-user"
              class="dropdown-item text-wrap"
            >
              <a
                class="p-1 text-start accordion-menu-text"
                routerLinkActive="active-link"
              >
                <img
                  width="20px"
                  src="assets/images/userguied non-hover icon.svg"
                  alt=""
                />
                User Guide
              </a>
              <p class="p-1 text-start m-0">
                Setp by Step guide to use the platform.
              </p>
            </div>
            <div
              data-bs-toggle="offcanvas"
              routerLink="investing-masterclass"
              class="dropdown-item text-wrap"
            >
              <a
                class="p-1 text-start accordion-menu-text"
                routerLinkActive="active-link"
              >
                <img
                  width="20px"
                  src="assets/images/money_10243595.svg"
                  alt=""
                />
                Biotech Investing 101
              </a>
              <p class="p-1 text-start m-0">
                Learn step by step Biotech Investing.
              </p>
            </div>
            <div class="dropdown-item text-wrap">
              <a
                href="https://discord.gg/vbr3w4nwx5"
                class="p-1 text-start accordion-menu-text"
                routerLinkActive="active-link"
              >
                <img
                  width="20px"
                  src="assets/images/discord_3670325.svg"
                  alt=""
                />
                Discord
              </a>
              <p class="p-1 text-start m-0">
                Connect with other Biotech Traders and Investor.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            routerLink="subscription"
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="offcanvas"
            aria-expanded="false"
          >
            <b>Pricing</b>
          </button>
        </h2>
      </div>
      @if(!loggedin()){
      <div class="m-2 my-3">
        <button
          data-bs-toggle="offcanvas"
          routerLink="login"
          style="font-size: 16px"
          type="button"
          class="btn-primary-white w-100"
        >
          <b>Sign In</b>
        </button>

        <button
          data-bs-toggle="offcanvas"
          routerLink="signup"
          style="font-size: 16px"
          type="button"
          class="btn-primary-green w-100 m-0 mt-3"
        >
          <b>Start Free Trial</b>
        </button>
      </div>
      }
    </div>
  </div>
</div>

<div
  class="offcanvas offcanvas-end"
  tabindex="-1"
  id="notificationBar"
  aria-labelledby="notificationBarLabel"
>
  <div class="offcanvas-header">
    <h5 class="offcanvas-title main-heading" id="notificationBarLabel">
      Recent Updates
    </h5>
    <button
      type="button"
      class="btn-close text-reset"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
  </div>
  <div class="offcanvas-body">
    <div>
      <h6 class="mb-1"><strong>Recently added Catalysts</strong></h6>
    </div>
    <div class="mt-3">
      <ul class="ps-2">
        @if(isProductId && notificationRecords.length > 0){ @for (item of
        notificationRecords; track $index) {
        <li
          style="
            border-bottom: 1px solid rgba(128, 128, 128, 0.692);
            position: relative;
            display: inline-block;
          "
          class="my-1"
        >
          <div class="py-2">
            <a
              href="{{ clientUrl }}/company/{{ item.ticker | uppercase }}"
              class="link-ticker table-astec-font text-white"
              >{{ item.ticker }}</a
            >
            | <b>Catalyst Date </b> : {{ item.catdate }} | <b>Event Type </b> :
            {{ item.catalyst }} | <b>Drug :</b> <span> {{ item.drug }}</span> |
            <b>Phase :</b> <span> {{ item.stage }}</span> | <b>Treatment :</b>
            <span> {{ item.treatment }}</span>
          </div>
        </li>
        }}@else {
        <li
          style="
            border-bottom: 1px solid rgba(128, 128, 128, 0.692);
            position: relative;
            display: inline-block;
          "
          class="my-1"
        >
          <div class="py-2">
            <a
              href="{{ clientUrl }}/company/{{
                notificationRecords.ticker | uppercase
              }}"
              class="link-ticker table-astec-font text-white"
              >{{ notificationRecords.ticker }}</a
            >
            | <b>Catalyst Date </b> : {{ notificationRecords.catdate }} |
            <b>Event Type </b> : {{ notificationRecords.catalyst }} |
            <b>Drug :</b> <span> {{ notificationRecords.drug }}</span> |
            <b>Phase :</b> <span> {{ notificationRecords.stage }}</span> |
            <b>Treatment :</b>
            <span> {{ notificationRecords.treatment }}</span>
          </div>
        </li>
        <li
          style="
            border-bottom: 1px solid rgba(128, 128, 128, 0.692);
            position: relative;
            display: inline-block;
          "
          class="my-1 blurred"
        >
          <div class="py-2">
            <a
              href="{{ clientUrl }}/company/{{
                notificationRecords.ticker | uppercase
              }}"
              class="link-ticker table-astec-font text-white"
              >{{ notificationRecords.ticker }}</a
            >
            | <b>Catalyst Date </b> : {{ notificationRecords.catdate }} |
            <b>Event Type </b> : {{ notificationRecords.catalyst }} |
            <b>Drug :</b> <span> {{ notificationRecords.drug }}</span> |
            <b>Phase :</b> <span> {{ notificationRecords.stage }}</span> |
            <b>Treatment :</b>
            <span> {{ notificationRecords.treatment }}</span>
          </div>
        </li>
        } @if(!isProductId && notificationRecords){
        <div
          style="
            background-color: #6767eb26;
            border-radius: 10px;
            padding: 26px;
          "
        >
          <p class="h5">
            Stay updated with real-time Catalysts updates. Click below for free
            access!
          </p>
          <button
            routerLink="/subscription"
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            class="form-save-button p-3 mb-2 mt-2"
          >
            Start Free Trial
          </button>
        </div>
        }
      </ul>
    </div>
  </div>
</div>
