import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { AdminService } from 'src/app/modules/shared/service/admin.service';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss',
})
export class AdminComponent implements OnInit {
  private page = 1;
  search: string = '';
  productPlan: string = '';
  sort: string = '';
  selected: string = 'Users';
  users: any;
  searchValuequery: any;
  private searchSubject = new Subject<string>();
  isEndOfData: boolean = false;
  lastScrollTop: number = 0;

  constructor(
    private adminservice: AdminService,
    private decryptionService: DecryptionService,
    private router: Router
  ) {
    // Subscribe to the Subject to handle debounce
    this.searchSubject
      .pipe(
        debounceTime(500), // Adjust debounce time as needed
        distinctUntilChanged() // Ignore duplicate values
      )
      .subscribe((query) => {
        this.performSearch(query);
      });
  }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    this.search = '';
    this.page = 1;
    this.adminservice
      .getUsers(this.search, this.page, this.productPlan, this.sort)
      .subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);

          this.page++;
          this.users = JSON.parse(decryptData);
        },
      });
  }

  searchFilters(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;

    if (event.key === 'Escape') {
      this.getUsers();
      return;
    }

    // Emit the search query to the Subject for debouncing
    this.searchSubject.next(inputElement.value);
  }
  performSearch(query: string = '') {
    this.adminservice
      .getUsers(query, this.page, this.productPlan, this.sort)
      .subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);
          this.users.push(...JSON.parse(decryptData));
          this.page++;
          if (!this.users.length) {
            this.users = [{ email: 'No user found' }];
          }
        },
        error: (err) => {
          console.error('Error fetching suggestions:', err);
        },
      });
  }

  onSearchInput(event: Event): void {
    this.page = 1;
    this.users = [];
    const inputValue = (event.target as HTMLInputElement).value;

    if (inputValue === '') {
      this.getUsers();
    }
  }

  onScroll(event: any) {
    const target = event.target;

    // Calculate scroll positions
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    // Determine if the user is scrolling down and close to the bottom
    const isScrollingDown = scrollTop > this.lastScrollTop;
    this.lastScrollTop = scrollTop;

    if (isScrollingDown && scrollHeight - scrollTop <= clientHeight) {
      // Trigger API call only when scrolling near the bottom
      this.performSearch(this.searchValuequery);
    }
  }
}
