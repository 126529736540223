<div class="container profile-set-center mb-5 mt-5">
  <div class="row shadow profile-box">
    <div class="col-md-3 profile-bg p-0">
      <div class="profile-section-first pt-5 ps-5 pb-5">
        <div class="mb-4 profile-Information" (click)="personaldata()">
          <div class="img-center">
            <img
              src="assets/images/profile-information.svg"
              style="width: 30px"
              alt=""
            />
          </div>
          <button
            class="profile-personal"
            [ngClass]="{ 'profile-active-link': showprofile }"
          >
            Personal Information
          </button>
        </div>
        <div class="profile-Information" (click)="resetPassword()">
          <div class="img-center">
            <img
              src="assets/images/password-reset.svg"
              style="width: 30px"
              alt=""
            />
          </div>
          <button
            class="profile-personal"
            [ngClass]="{ 'profile-active-link': Hidepassword }"
          >
            Reset Password
          </button>
        </div>
        <div class="profile-Information mt-4" (click)="showsub()">
          <div class="img-center">
            <img src="assets/images/price-tag.svg" style="width: 30px" alt="" />
          </div>
          <button
            class="profile-personal"
            [ngClass]="{ 'profile-active-link': showsubscription }"
          >
            Subscription
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-9 p-5">
      <div class="">
        <form [formGroup]="profileform" (ngSubmit)="updateProfileData()">
          <div class="d-flex profile-form profile-form-name">
            @if (!inputshow) {
            <div class="d-flex mb-3 input-width align-items-center">
              <img
                class="profile-img-size me-2"
                style="width: 20px"
                src="assets/images/profile-user.svg"
                alt=""
              />
              <label class="label-font" for=""
                >@if(fName == null){ } @else{
                {{ fName }}
                }@if(lName == null){ } @else{
                {{ lName }}
                }
              </label>
            </div>
            }

            <div
              class="d-flex profile-email-example mb-3 input-width align-items-center"
            >
              <img
                class="profile-img-size me-2"
                style="width: 30px"
                src="assets/images/profile-email.svg"
                alt=""
              />
              <label class="label-font" for="">{{ emailValue }}</label>
            </div>
          </div>

          @if (inputshow) {
          <div class="d-flex justify-content-between profile-form">
            <div class="profile-input">
              <label for="fname" class="label-font-head">First Name</label>
              <input
                type="text"
                [ngClass]="{ 'input-color': isEditMode }"
                class="form-control input-set"
                id="fname"
                placeholder=""
                formControlName="fname"
                [readonly]="!isEditMode"
              />
              @if ( profileform.controls['fname'].invalid &&
              (profileform.controls['fname'].dirty ||
              profileform.controls['fname'].touched)) { }
            </div>

            <div class="profile-input">
              <label for="lname" class="label-font-head">Last Name</label>
              <input
                type="text"
                [ngClass]="{ 'input-color': isEditMode }"
                class="form-control"
                id="lname"
                placeholder=""
                formControlName="lname"
                [readonly]="!isEditMode"
              />
              @if ( profileform.controls['lname'].invalid &&
              (profileform.controls['lname'].dirty ||
              profileform.controls['lname'].touched)) { }
            </div>
          </div>
          } @if (showprofile) {
          <div class="d-flex justify-content-between profile-form">
            <div class="profile-input">
              <label for="mobile" class="label-font-head">Phone Number</label>
              <input
                type="text"
                class="form-control"
                id="mobile"
                placeholder=""
                formControlName="mobile"
                [ngClass]="{ 'input-color': isEditMode }"
                [readonly]="!isEditMode"
              />

              @if ( profileform.controls['mobile'].invalid &&
              (profileform.controls['mobile'].dirty ||
              profileform.controls['mobile'].touched)) {
              <div class="form-error">
                @if (isEditMode) {
                <span class="">phone Numbar is required.</span>
                }
              </div>
              }
            </div>

            <div class="profile-input">
              <label for="city" class="label-font-head">City</label>
              <input
                type="text"
                class="form-control"
                id="city"
                placeholder=""
                formControlName="city"
                [ngClass]="{ 'input-color': isEditMode }"
                [readonly]="!isEditMode"
              />

              @if ( profileform.controls['city'].invalid &&
              (profileform.controls['city'].dirty ||
              profileform.controls['city'].touched)) {
              <div class="form-error">
                @if (isEditMode) {
                <span class="">City Name is required.</span>
                }
              </div>
              }
            </div>
          </div>

          <div class="d-flex justify-content-between profile-form">
            <div class="profile-input">
              <label class="label-font-head" for="country">Country</label>
              <input
                type="text"
                class="form-control"
                id="country"
                placeholder="Country"
                formControlName="country"
                [ngClass]="{ 'input-color': isEditMode }"
                [readonly]="!isEditMode"
              />

              @if ( profileform.controls['country'].invalid &&
              (profileform.controls['country'].dirty ||
              profileform.controls['country'].touched)) {
              <div class="">
                @if (isEditMode) {
                <span class="form-error">Country Name is required.</span>
                }
              </div>
              }
            </div>
          </div>
          } @if (showsubscription) { @if (this.planname && this.planstatus) {
          <div class="container">
            <div class="row mb-3 align-items-center">
              <div class="col-12 col-md-auto p-0">
                <h5 class="m-0">{{ planname | titlecase }}</h5>
              </div>
              <div class="col-12 col-md-auto mt-2 mt-md-0">
                @if(planstatus == 'on_trial') {
                <span class="badge bg-success">On Trial</span>
                } @else if(planstatus == 'active') {
                <span class="badge bg-success">Active</span>
                } @else if(planstatus == 'cancelled') {
                <span class="badge bg-danger">Cancelled</span>
                } @else if(planstatus == 'expired') {
                <span class="badge bg-danger">Expired</span>
                }
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row justify-content-center justify-content-md-start">
              <div class="col-12 col-md-auto">
                @if (planstatus == 'on_trial') {
                <button
                  type="button"
                  routerLink="/subscription"
                  class="btn btn-light fw-bold shadow profile-edit-button m-1"
                >
                  Upgrade Plan
                </button>
                } @if (planstatus == 'cancelled') {
                <button
                  type="button"
                  routerLink="/subscription"
                  class="btn btn-light fw-bold shadow profile-edit-button m-1"
                >
                  Get Started
                </button>
                } @if (planstatus == 'expired') {
                <button
                  type="button"
                  routerLink="/subscription"
                  class="btn btn-light fw-bold shadow profile-edit-button m-1"
                >
                  Renew Plan
                </button>
                } @else { @if (planstatus !== 'cancelled') {
                <button
                  type="button"
                  (click)="openPopup()"
                  class="btn btn-light fw-bold shadow profile-edit-button m-1 cancel-plan-btn btn-light"
                >
                  Stop Renewal
                </button>
                } }
              </div>
            </div>
          </div>

          <div class="container mt-4">
            <div class="row mb-3 align-items-center">
              <div class="col-12 col-md-auto p-0">
                @if (credits > 0) {

                <h5 class="m-0">Remaining Download Credits</h5>
                }@else {
                <h5 class="m-0">No Download Credits</h5>
                }
              </div>
              <div class="col-12 col-md-auto mt-2 mt-md-0">
                <span class="badge bg-success">{{ credits }}</span>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row justify-content-center justify-content-md-start">
              <div class="col-12 col-md-auto">
                <button
                  type="button"
                  routerLink="/subscription"
                  class="btn btn-light fw-bold shadow profile-edit-button m-1"
                >
                  Get Credits
                </button>
              </div>
            </div>
          </div>

          }@else {
          <div class="mb-2">
            <span class="m-0">Less than 2 Coffees a Month ☕☕</span>
          </div>

          <div>
            <button
              type="button"
              routerLink="/subscription"
              class="btn profile-edit-button fw-bold btn-light shadow"
            >
              Get Started
            </button>
          </div>
          } } @if (Hidepassword) {
          <div>
            <form
              [formGroup]="resertpassword"
              (ngSubmit)="savePopup()"
              action=""
            >
              <div class="d-flex justify-content-between profile-form">
                <div class="profile-input">
                  <div class="input-with-icon">
                    <input
                      [type]="showPassword ? 'text' : 'password'"
                      class="form-control input-set"
                      id="oldpassword"
                      formControlName="oldpassword"
                      placeholder="Old Password"
                    />
                    <i
                      class="fa"
                      [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                      (click)="togglePasswordVisibility()"
                    ></i>
                  </div>
                  @if (resertpassword.controls['oldpassword'].invalid
                  &&(resertpassword.controls['oldpassword'].dirty ||
                  resertpassword.controls['oldpassword'].touched)) {
                  <div class="">
                    @if
                    (resertpassword.controls['oldpassword'].errors?.['required'])
                    {
                    <span class="form-error">Old Password is required. </span>
                    }
                  </div>
                  }
                </div>

                <div class="profile-input">
                  <div class="input-with-icon">
                    <input
                      [type]="shownewpassword ? 'text' : 'password'"
                      class="form-control input-set"
                      id="password"
                      placeholder="New Password"
                      formControlName="password"
                    />
                    <i
                      class="fa"
                      [ngClass]="shownewpassword ? 'fa-eye-slash' : 'fa-eye'"
                      (click)="toggleNewPasswordVisibility()"
                    ></i>
                  </div>

                  @if ( resertpassword.controls['password'].invalid &&
                  (resertpassword.controls['password'].dirty ||
                  resertpassword.controls['password'].touched)) {
                  <div class="">
                    @if
                    (resertpassword.controls['password'].errors?.['required']) {
                    <span class="form-error">Password is required.</span>
                    }
                  </div>
                  }
                </div>
              </div>
              <p
                style="font-size: 14px"
                (click)="openforgetpass()"
                class="mb-2 cursorpointer"
              >
                Forget Password?
              </p>
            </form>
          </div>
          } @if (Hidepassword) {
          <div class="reset-save-btn">
            <button
              type="button"
              class="btn profile-edit-button fw-bold btn-light shadow password-input-save"
              (click)="savePopup()"
            >
              Save
            </button>
          </div>
          } @if (showprofile) {
          <div class="d-flex reset-save-btn">
            @if (isEditMode) {
            <button
              class="btn profile-edit-button fw-bold btn-light shadow"
              type="submit"
            >
              Save Change
            </button>
            <button
              class="btn profile-edit-button fw-bold btn-light shadow mx-2"
              (click)="cancelData()"
              type="button"
            >
              Cancel
            </button>
            }@else {
            <button
              class="btn profile-edit-button fw-bold btn-light shadow"
              type="button"
              (click)="enableEditMode()"
            >
              Edit
            </button>
            }
          </div>
          }
        </form>
      </div>
    </div>
  </div>
</div>

<!-- modal -->

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{ display: displayStyle }"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div style="background-color: #1d0fa0" class="modal-header">
        <h4 class="modal-title text-white text-center">Cancel Plan</h4>
      </div>
      <div class="modal-body">
        <p class="text-start">Are you sure you want to cancel your plan?</p>
        <div>
          <div class="my-2">
            <label for="reason" class="label-font-head">Reason &nbsp;</label>
            @if(this.reason == 'Other') {
            <textarea
              class="form-control text-dark"
              id="reason"
              placeholder="Reason"
              rows="3"
              [(ngModel)]="customereason"
            ></textarea>
            }@else {
            <select
              (change)="selectcancelplan($event)"
              [(ngModel)]="reason"
              class="form-select"
              name=""
              id=""
            >
              <option selected disabled value="null">Select</option>
              <option value="Not using the service enough">
                Not using the service enough
              </option>
              <option value="Found a better alternative">
                Found a better alternative
              </option>
              <option value="Content or features not valuable">
                Content or features not valuable
              </option>
              <option value="Technical issues or bugs">
                Technical issues or bugs
              </option>
              <option value="I only needed it for a short period">
                I only needed it for a short period
              </option>
              <option value="Other">Other (Please specify)</option>
            </select>
            }
          </div>
          <div class="my-2">
            <label for="password" class="label-font-head">OTP</label>
            <div>
              <input
                type="text"
                class="form-control text-dark"
                [(ngModel)]="otpforcancelplan"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button
            type="button"
            (click)="closePopup()"
            class="btn profile-edit-button-pop fw-bold text-uppercase btn-light shadow rounded bg-white password-input-reset"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="cancelplan()"
            class="btn profile-edit-button-pop fw-bold text-uppercase btn-light shadow rounded bg-white password-input-save"
          >
            @if (!cancelreasonbtn) {
            <p class="mb-0">Confirm</p>
            }@else {
            <i class="fa fa-spin fa-spinner"></i>
            }
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{ display: displayStyleForget }"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-center">Password Reset Mail</h4>
      </div>
      <div class="modal-body">
        <div>
          <input
            [(ngModel)]="forgetmail"
            type="text"
            class="form-control mb-3"
            placeholder="Email"
          />
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <button
          type="button"
          (click)="closeforgetPop()"
          class="btn text-dark profile-edit-button fw-bold text-uppercase btn-light shadow rounded bg-white password-input-reset"
        >
          Cancel
        </button>
        <button
          type="button"
          (click)="ForgetPass()"
          class="btn text-dark profile-edit-button fw-bold text-uppercase btn-light shadow rounded bg-white password-input-save"
        >
          Send
        </button>
      </div>
    </div>
  </div>
</div>
