import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { catalystSync } from '../../../shared/models/CatalystSync';
import { CatalystSyncService } from '../../../shared/service/catalyst-sync.service';
import { Router } from '@angular/router';
import { companyDetail } from '../../../shared/models/company-details';
import { CompanyDetailsService } from '../../../shared/service/company-details.service';
import { FilterdataService } from '../../../shared/service/filterdata.service';
import { ToastrService } from 'ngx-toastr';
import { FdaCalenarService } from 'src/app/modules/shared/service/fda-calendar.service';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { SubscriptionService } from 'src/app/modules/shared/service/subscription.service';

@Component({
  selector: 'app-catalyst-sync',
  templateUrl: './catalyst-sync.component.html',
  styleUrls: ['./catalyst-sync.component.scss'],
})
export class CatalystSyncComponent implements OnInit {
  @ViewChild('dataContainer') dataContainer!: ElementRef;

  isCollapsed: boolean = true;
  showButtons = true;
  catalysTable: catalystSync[] = [];
  basePath: string = 'https://s3-symbol-logo.tradingview.com/';
  private currentPage = 1;
  isLoading = false;
  resdata: any;
  list!: any[];
  searchValuequery: string = '';

  scrollend = true;
  filterOpened = false;
  loadData!: boolean;
  ticker = true;
  price = true;
  thirtydaypricechange = true;
  title = true;
  CompletionDate = true;
  industry = true;
  Condition = true;
  Pipeline = true;
  Phase = true;
  marketcap = true;
  Drugname = true;
  runway = true;
  burn_rate = true;
  volume = true;
  live_cash = true;
  modalDisplayStyle!: boolean;
  cssClass = '';
  selectedCompany: any;
  isCompanyDetailsTriggered: boolean = false;
  searchingOrFiltering: boolean = true;
  filterArray: any[] = [];
  select_all = true;
  showPrice = false;
  showDate = false;
  Market_cap = false;
  ChangeinPrice = false;
  showPhase = false;
  selectlist: any[] = [];
  dateEnd!: any;
  dateStart!: any;
  displayStyle: string = 'none';
  displayStyleCondition: string = 'none';
  brieftitle: any;
  ReadMore: any;
  ConditionString: any;
  stageArr: any[] = [];
  isFilterBtn = false;
  isFilterBtnResert = false;
  isFilterBtnSearch = false;
  showloginWarning = false;
  toggle: boolean = true;
  selectedPriceRange: string | null = null;
  selectedMarketCapRange: string | null = null;
  selectedIndustryRange: string | null = null;
  selectedBurnRateRange: string | null = null;
  selectedRunwayRange: string | null = null;
  selectedLiveCashRange: string | null = null;
  selectedVolumeRange: string | null = null;
  isDropdownOpen: { [key: string]: boolean } = {};
  selectedChangePriceRange: string | null = null;
  selectedDateRange: any = null;
  disableDeselect: boolean = false;
  selectedDiseases: any = [];
  selectedTherapeutic: any = [];
  selectedStages: any = [];

  isCustomChangePriceRangeSelected: boolean = false;
  isCustommarketcapRangeSelected: boolean = false;
  isCustomRangeSelected: boolean = false;
  isCustomDateRangeSelected: boolean = false;

  minCustomValue: any;
  maxCustomValue: any;
  minCustomChange: any;
  maxCustomChange: any;
  minCustomDate: any;
  maxCustomDate: any;
  minCustomMarket: any;
  maxCustomMaket: any;
  minCustomburn_rate: any;
  maxCustomburn_rate: any;
  minCustomrunway: any;
  maxCustomrunway: any;
  minCustomrvolume: any;
  maxCustomrvolume: any;
  minCustomlive_cash: any;
  maxCustomlive_cash: any;

  showpriceDropDown!: boolean;
  showchangepriceDropDown!: boolean;
  isdateDropdownOpen!: boolean;
  isstageDropdownOpen!: boolean;
  isMarketcapDropdownOpen!: boolean;
  isIndustrydownOpen!: boolean;
  apitype = 'defualt';
  BiotechnologyData!: any[];
  MedicalDeviceData!: any[];
  DiagnosticsData!: any[];
  DrugData!: any[];
  sortKeyVal = '';
  sortNumber = 1;
  FilterObj: any;
  arrowclass = 'fa-arrow-up';
  isLoadTableDataLoader!: boolean;
  totalDatalength = 0;
  selectedDataColor!: string;
  selectedchangeColor!: string;
  selectedDateColor!: string;
  selectedmarketColor!: string;
  selectedbutn_rateColor!: string;
  selectedrunwayColor!: string;
  selectedStageColor!: string;
  selectedlive_cashColor!: string;
  selectedindustryColor!: string;
  selectedvolumeColor!: string;
  selectedValue: string | null = null;
  selectedValueinchange: string | null = null;
  selectedValueinDate: string | null = null;
  selectedValueinmarket_cap: string | null = null;
  selectedValueinindustry: string | null = null;
  selectedValueinburn_rate: string | null = null;
  selectedValueinrunway: string | null = null;
  selectedValueinvolume: string | null = null;
  selectedValueinlive_cash: string | null = null;

  isFilter = false;
  isSort = false;
  forResetfilters = false;
  arrowname = 'fa-arrow-up';
  arrowticker = 'fa-arrow-up';
  arrowprice = 'fa-arrow-up';
  arrowtitle = 'fa-arrow-up';
  arrowcompletionDate = 'fa-arrow-up';
  arrowcondition = 'fa-arrow-up';
  arrowpipeline = 'fa-arrow-up';
  arrowphase = 'fa-arrow-up';
  arrowmarket_cap = 'fa-arrow-up';
  arrowlive_cash = 'fa-arrow-up';
  arrowvolume = 'fa-arrow-up';
  arrowburn_rate = 'fa-arrow-up';
  arrowrunway = 'fa-arrow-up';
  suggection: any;
  portfoliomatchTicker: any[] = [];
  portfolioTicker: any;
  portfoliocost: number | undefined;
  portfolioquntity: number | undefined;
  portfoliototal: any;
  portfolioPrice: any;

  selectedDiseasesColor!: string;
  diseasesList!: any[];
  disablediseasesList: boolean = false;

  selectedTherapeuticColor!: string;
  therapeuticList!: any[];
  disableTheraputics: boolean = false;
  lockedArray: any[] = [];
  productId: number | undefined;
  clientUrl = environment.clientUrl;
  btnLoading: any;
  downloadExcelTooltipValue: string = '';
  userToken: any = localStorage.getItem('accessToken');
  credits: number | undefined = 0;

  nm: any = {
    TICKER: 'ticker',
    PRICE: 'close',
    TITLE: 'BriefTitle',
    'COMPLETION DATE': 'CompletionDate',
    CONDITION: 'Condition',
    PIPELINE: 'InterventionName',
    PHASE: 'Phase',
    'MARKET CAP': 'market_cap_basic',
    'LIVE-CASH': 'Cash_and_Cash_Equivalents',
    VOLUME: 'volume',
    'BURN-RATE': 'Monthly_Burn_Rate',
    RUNWAY: 'Runway_Months',
    INDUSTRY: 'Industry',
    NAME: 'name',
    CHANGE: 'change',
  };

  constructor(
    private CatalystSyncService: CatalystSyncService,
    private router: Router,
    private compamyDetails: CompanyDetailsService,
    private filter: FilterdataService,
    private toaster: ToastrService,
    private fdacalendarservice: FdaCalenarService,
    private decryptionService: DecryptionService, //  decryption service added
    private subscriptionService: SubscriptionService
  ) {
    this.list = [
      { name: 'TICKER', checked: true },
      { name: 'PRICE', checked: true },
      { name: '30 DAYS PRICE CHANGE', checked: true },
      { name: 'TITLE', checked: true },
      { name: 'COMPLETION DATE', checked: true },
      { name: 'CONDITION', checked: true },
      { name: 'PIPELINE', checked: true },
      { name: 'PHASE', checked: true },
      { name: 'MARKET CAP', checked: true },
      { name: 'LIVE-CASH', checked: true },
      { name: 'VOLUME', checked: true },
      { name: 'BURN-RATE', checked: true },
      { name: 'RUNWAY', checked: true },
      { name: 'INDUSTRY', checked: true },
    ];

    // this.selectlist = [
    //   { name: 'EARLY_PHASE1', checked: false },
    //   { name: 'NA', checked: false },
    //   { name: 'PHASE1', checked: false },
    //   { name: 'Phase 1/a', checked: false },
    //   { name: 'Phase 1/b', checked: false },
    //   { name: 'Phase 1/2', checked: false },
    //   { name: 'Phase 2a', checked: false },
    //   { name: 'PHASE2', checked: false },
    //   { name: 'Phase 2b', checked: false },
    //   { name: 'Phase 2/3', checked: false },
    //   { name: 'PHASE3', checked: false },
    //   { name: 'PHASE4', checked: false },
    //   { name: 'NDA Filing', checked: false },
    //   { name: 'sNDA Filing', checked: false },
    //   { name: 'BLA Filing', checked: false },
    //   { name: 'PDUFA', checked: false },
    //   { name: 'Approved', checked: false },
    //   { name: 'CRL', checked: false },
    //   { name: 'Phase 1a/1b', checked: false },
    //   { name: 'Orphan Drug Designation', checked: false },
    //   { name: 'Investigational New Drug(IND)', checked: false },
    //   { name: 'NDA Submission', checked: false },
    //   { name: 'PDUFA Adcomm', checked: false },
    // ];

    this.BiotechnologyData = [
      'Biotechnology',
      'Tobacco',
      'Real Estate Services',
      'Chemicals',
      ' N/A',
      'Farm Products',
      'Food Distribution',
      'Packaged Foods',
    ];

    this.MedicalDeviceData = [
      'Medical Devices',
      'Medical Instruments & Supplies',
      'Electronic Components',
      'Scientific & Technical Instruments',
      'Health Information Services',
      'Medical Care Facilities',
      'Furnishings',
      'Fixtures & Appliances',
      'Medical Distribution',
    ];

    this.DiagnosticsData = ['Diagnostics & Research'];

    this.DrugData = [
      'Drug Manufacturers—General',
      'Drug Manufacturers—Specialty & Generic',
      'Household & Personal Products,Pharmaceutical Retailers',
    ];

    this.diseasesList = [
      { name: 'Multiple Sclerosis', checked: false },
      { name: 'Autoimmune Diseases', checked: false },
      { name: 'Genetic Disorders', checked: false },
      { name: 'Lymphoma', checked: false },
      { name: "Parkinson's Disease", checked: false },
      { name: 'Hepatitis', checked: false },
      { name: 'Heart Disease', checked: false },
      { name: "Alzheimer's Disease", checked: false },
      { name: 'Diabetes', checked: false },
      { name: 'HIV/AIDS', checked: false },
      { name: 'Breast Cancer', checked: false },
      { name: 'Lung Cancer', checked: false },
      { name: 'Leukemia', checked: false },
    ];

    this.therapeuticList = [
      { name: 'Cardiovascular Disease', checked: false },
      { name: 'Dermatology', checked: false },
      { name: 'Endocrinology/Metabolism/Bone', checked: false },
      { name: 'Gastroenterology', checked: false },
      { name: 'Infectious Disease (viral)', checked: false },
      { name: 'Neurology', checked: false },
      { name: 'Oncology', checked: false },
      { name: 'Ophthalmology', checked: false },
      { name: 'Renal Disease', checked: false },
      { name: 'Analgesia/Anesthesiology/Anti-inflammatory', checked: false },
      { name: 'Psychiatry', checked: false },
      { name: 'Addiction', checked: false },
      { name: 'Infectious Disease (non viral)', checked: false },
      { name: 'Hematology/Coagulation', checked: false },
      { name: 'Pulmonary', checked: false },
      { name: 'Medical Imaging', checked: false },
      { name: 'Rheumatology', checked: false },
      { name: 'Allergy', checked: false },
      { name: 'Immunomodulators', checked: false },
    ];
  }

  ngOnInit() {
    this.csTableData();
    this.stagelist();
    const element = document.getElementById('dataContainer');
    // if (element) {
    //   element.addEventListener('scroll', this.onScroll.bind(this));
    // }
    this.returnLogin();
  }

  stagelist() {
    this.CatalystSyncService.getStages().subscribe({
      next: (res: any) => {
        let list = JSON.parse(this.decryptionService.decrypt(res.result));

        list.forEach((item: any) => {
          let items = {
            name: item,
            cheked: false,
          };
          this.selectlist.push(items);
        });
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  sde() {
    let sl = [
      'name',
      'change',
      ...this.list
        .filter((item) => item.checked)
        .map((item) => this.nm[item.name] || item.name.toLowerCase()),
    ];

    function formatValue(value: any) {
      const isNegative = value < 0;
      const av = Math.abs(value);
      let fv: any;
      if (av >= 1_000_000_000) {
        fv = (av / 1_000_000_000).toFixed(2) + ' B';
      } else if (av >= 1_000_000) {
        fv = (av / 1_000_000).toFixed(2) + ' M';
      } else if (av >= 1_000) {
        fv = (av / 1_000).toFixed(2) + ' K';
      } else {
        fv = av || '-';
      }
      return isNegative ? `-${fv}` : fv;
    }

    function formatValue2(value: any) {
      if (
        (typeof value === 'number' || Number.isFinite(value)) &&
        value !== 0
      ) {
        return `${parseFloat(value.toFixed(2))} %`;
      }
      return '-';
    }
    function formatValue3(value: any) {
      if (typeof value === 'number' && value !== 0) {
        return parseFloat(value.toFixed(2));
      }
      return '-';
    }

    return () => {
      return this.catalysTable.map((item: any) =>
        sl.reduce((acc: any, key: any) => {
          const mappedKey =
            Object.keys(this.nm).find((k) => this.nm[k] === key) || key;
          if (key in item) {
            let value = item[key];

            if (
              key === 'market_cap_basic' ||
              key === 'volume' ||
              key === 'Cash_and_Cash_Equivalents' ||
              key === 'Monthly_Burn_Rate'
            )
              value = formatValue(value);

            if (key === 'change') value = formatValue2(value);

            if (key === 'Runway_Months') value = formatValue3(value);
            acc[mappedKey] = value;
          }
          return acc;
        }, {})
      );
    };
  }

  exportToExcel() {
    this.btnLoading = true;

    if(this.userToken && this.productId) {
      const dataLength = {
        datalength: this.catalysTable.length,
      }
      this.subscriptionService.checkAvailableCredits(dataLength).subscribe({
        next: (res: any) => {
          this.setExportToExcel();
          this.toaster.success("Excel file downloaded successfully");
          this.btnLoading = false;
          this.credits = this.credits! - this.catalysTable.length;
          this.downloadExcelTooltipValue = this.credits > 0 ? 'Available credits: ' + this.credits : 'No credits available';
        },
        error: (error) => {
          this.toaster.error(error.error.message);
          this.btnLoading = false;
        }
      })
    } else if (this.userToken && !this.productId) {
      this.router.navigate(['subscription']);
    }
    else {
      this.router.navigate(['login']);
    }
  }

  setExportToExcel() {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

    const metadata = [
      ['Title', 'CatalystSync Records'],
      [
        'Description',
        'This report provides a comprehensive overview of CatalystSync data, capturing key metrics, trends, and insights for analytical and operational purposes. It includes detailed tabular information, summarized statistics, and visual elements where applicable. This data is curated to support decision-making, performance evaluation, and strategic planning.',
      ],
      ['Source', 'Biopharmawatch.com'],
      ['URL', 'https://biopharmawatch.com/catalyst-sync'],
      ['Generated on', new Date().toLocaleDateString()],
      [],
    ];
    XLSX.utils.sheet_add_aoa(worksheet, metadata, { origin: 'A1' });

    XLSX.utils.sheet_add_json(worksheet, this.sde()(), {
      origin: 'A8',
      skipHeader: false,
    });

    const workbook: XLSX.WorkBook = {
      Sheets: { Data: worksheet },
      SheetNames: ['Data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, 'BPW_CatalystSync');
    setTimeout(() => {
      this.btnLoading = false;
    }, 800);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(data, `${fileName}${new Date()}.xlsx`);
  }

  scrollLeft() {
    this.dataContainer.nativeElement.scrollLeft -= 300;
  }

  scrollRight() {
    this.dataContainer.nativeElement.scrollLeft += 300;
  }

  checkScrollable() {
    const container = this.dataContainer.nativeElement;
    if (container.scrollWidth > container.clientWidth) {
      this.showButtons = true;
    } else {
      this.showButtons = false;
    }
  }

  openFilter() {
    this.filterOpened = true;
  }

  csTableData() {
    this.isLoadTableDataLoader = true;
    this.CatalystSyncService.getCatalystSync(this.currentPage).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);

        const ParseDecryptedData = JSON.parse(decryptData);

        this.totalDatalength = ParseDecryptedData[0].total[0].count;

        this.catalysTable = ParseDecryptedData[0].data;

        ParseDecryptedData.find((x: any) => x.portfolio)?.portfolio.forEach(
          (element: any) => {
            this.portfoliomatchTicker.push(element.ticker);
          }
        );

        this.productId = ParseDecryptedData.find(
          (x: any) => x.productId
        )?.productId;

        this.credits = ParseDecryptedData.find(
          (x: any) => x.credits
        )?.credits;

        this.setDownloadExcelTooltip();
        
        this.isLoading = false;
        this.isLoadTableDataLoader = false;
        this.isFilterBtn = false;
        this.currentPage = 2;

        this.isFilterBtnResert = false;

        // for (let i = 0; i < 2; i++) {
        //   this.lockedArray.push(this.catalysTable);
        //   this.lockedArray = this.lockedArray.flat();
        // }

        // if (!this.productId) {
        //   this.catalysTable = this.lockedArray;
        // }

        setTimeout(() => {
          this.checkScrollable();
        });
      },
    });
  }

  loadMoreData() {
    this.isLoading = true;
    this.loadData = true;

    if (this.apitype == 'filter') {
      this.filter.syncfilterColum(this.currentPage, this.FilterObj).subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);

          const ParseDecryptedData = JSON.parse(decryptData);
          this.loadData = false;

          ParseDecryptedData[0].data.forEach((element: any) => {
            this.catalysTable.push(element);
          });
          this.isLoading = false;
          this.currentPage++;
        },
        error: (err) => {
          this.loadData = false;
          this.isLoading = true;
          this.toaster.warning('All Record displayed');
          this.apitype = 'defualt';
        },
      });
    }

    if (this.apitype == 'defualt') {
      this.CatalystSyncService.getCatalystSync(this.currentPage).subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);

          const ParseDecryptedData = JSON.parse(decryptData);
          this.loadData = false;
          this.totalDatalength = ParseDecryptedData[0].total[0].count;

          ParseDecryptedData[1]?.portfolio &&
            ParseDecryptedData[1]?.portfolio.forEach((element: any) => {
              this.portfoliomatchTicker.push(element.ticker);
            });

          ParseDecryptedData[0].data.forEach((element: any) => {
            this.catalysTable.push(element);
          });

          this.isLoading = false;
          this.currentPage++;
        },
        error: (err) => {
          this.loadData = false;
          this.isLoading = true;
          this.toaster.warning('All Record displayed');
          this.apitype = 'defualt';
        },
      });
    }

    if (this.apitype == 'sort') {
      this.filter
        .syncsort(this.sortKeyVal, this.sortNumber, this.currentPage)
        .subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res.result);

            const ParseDecryptedData = JSON.parse(decryptData);
            this.loadData = false;
            this.totalDatalength = ParseDecryptedData[0].total[0].count;

            ParseDecryptedData[0].data.forEach((element: any) => {
              this.catalysTable.push(element);
            });
            this.isLoading = false;
            this.currentPage++;
          },
          error: (err) => {
            this.loadData = false;
            this.isLoading = true;
            this.toaster.warning('All Record displayed');
            this.apitype = 'defualt';
          },
        });
    }

    if (this.isSort) {
      this.filter
        .syncsortwithFilter(
          this.sortKeyVal,
          this.sortNumber,
          this.currentPage,
          this.FilterObj
        )
        .subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res.result);

            const ParseDecryptedData = JSON.parse(decryptData);
            this.loadData = false;
            this.totalDatalength = ParseDecryptedData[0].total[0].count;

            this.isLoading = false;
            this.currentPage++;
            ParseDecryptedData[0].data.forEach((element: any) => {
              this.catalysTable.push(element);
            });
          },
          error: (err) => {
            this.loadData = false;
            this.isLoading = true;
            this.toaster.warning('All Record displayed');
            this.apitype = 'defualt';
          },
        });
    }
  }

  onScroll(event: any) {
    const element = event.target as HTMLElement;
    const scrollPosition = element.scrollTop + element.clientHeight;
    const totalHeight = element.scrollHeight;
    if (this.productId) {
      if (scrollPosition >= totalHeight - 1 && !this.isLoading) {
        if (this.currentPage != 1) {
          this.loadMoreData();
        }
      }
    } else {
      let href = this.router.url;
      localStorage.setItem('route', href);
      this.modalDisplayStyle = true;
    }
  }

  refresh() {
    this.catalysTable = [];
    this.lockedArray = [];
    this.isLoading = false;
    this.loadData = false;

    this.currentPage = 1;
    this.arrowname = 'fa-arrow-up';
    this.arrowticker = 'fa-arrow-up';
    this.arrowprice = 'fa-arrow-up';
    this.arrowtitle = 'fa-arrow-up';
    this.arrowcompletionDate = 'fa-arrow-up';
    this.arrowcondition = 'fa-arrow-up';
    this.arrowpipeline = 'fa-arrow-up';
    this.arrowphase = 'fa-arrow-up';
    this.arrowmarket_cap = 'fa-arrow-up';
    this.arrowlive_cash = 'fa-arrow-up';
    this.arrowvolume = 'fa-arrow-up';
    this.arrowburn_rate = 'fa-arrow-up';
    this.arrowrunway = 'fa-arrow-up';
  }

  companyTrackBy(index: any, item: any) {
    return item.ticker;
  }

  companyDetails(ticker: any) {
    this.compamyDetails.getCompanyTable(ticker).subscribe({
      next: (res: any) => {
        this.router.navigate(['company', ticker]);
      },
    });
    this.compamyDetails.getCompanyTicker(ticker);
  }

  GetChildData(data: any) {
    this.modalDisplayStyle = data;
  }

  // searchFilters(query: any) {
  //   if (query == '') {
  //     this.toaster.warning('Enter Valid Inputs.');
  //     return;
  //   }
  //   this.searchingOrFiltering = false;
  //   let search =
  //   {
  //     query,
  //   }

  //   this.isLoadTableDataLoader = true;
  //   this.catalysTable = [];
  //   this.isFilterBtnSearch = true;
  //   this.filter.syncfilterData(search).subscribe({
  //     next: (res: any) => {
  //       this.totalDatalength = res.length

  //       this.isLoading = false;
  //       this.isLoadTableDataLoader = false;
  //       this.isFilterBtnSearch = false;
  //       this.catalysTable = res as catalystSync[];
  //       this.isLoading = true
  //     },
  //     error: (err: any) => {
  //       this.isLoading = false;
  //       this.catalysTable = [];
  //       this.csTableData();
  //       this.searchValuequery = ""
  //       this.isFilterBtnSearch = false;
  //       this.toaster.error(err.error.message);
  //     },
  //   });
  // }

  // pushFilterminChange(data: any) {
  //   this.filterArray.push({ start_varprice: Number(data.target.value) })
  // }

  // pushFiltermaxChange(data: any) {
  //   this.filterArray.push({ end_varprice: Number(data.target.value) })
  // }

  // pushFilterDateStart(data: any) {
  //   this.filterArray.push({ startDate: data.target.value })
  // }

  // pushFilterDateEnd(data: any) {
  //   this.filterArray.push({ endDate: data.target.value })
  // }

  // shareIndividualChecked(item: {}) {
  //   let value = Object.values(item)[1]
  //   this.stageArr.push(value)
  //   const Phase = this.stageArr
  //   const obj = {
  //     Phase
  //   }
  //   this.filterArray.push(obj)
  // }

  // popFilterStage() {
  //   this.showPhase = false
  // }

  // popFilterPrice() {
  //   for (let key in this.filterArray) {
  //     delete this.filterArray[key].startPrice;
  //     delete this.filterArray[key].endPrice;
  //   }
  //   this.showPrice = false;
  // }

  // popFilterChange() {
  //   for (let key in this.filterArray) {
  //     delete this.filterArray[key].start_varprice;
  //     delete this.filterArray[key].end_varprice;
  //   }
  //   this.ChangeinPrice = false;
  // }

  // popFilterMarket_cap() {
  //   this.Market_cap = false;
  // }

  // popFilterDate() {
  //   for (let key in this.filterArray) {
  //     delete this.filterArray[key].startDate;
  //     delete this.filterArray[key].endDate;
  //   }
  //   this.showDate = false

  // }

  saveFilters() {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
    let filtered = this.filterArray.filter((el) => Object.keys(el).length);
    if (filtered.length <= 0) {
      this.toaster.warning('Enter Valid Inputs.');
      return;
    }
    this.isFilter = true;
    this.forResetfilters = true;

    var newObject = filtered.reduce((result, currentObject) => {
      for (var key in currentObject) {
        if (currentObject.hasOwnProperty(key)) {
          result[key] = currentObject[key];
        }
      }
      return result;
    }, {});

    this.apitype = 'filter';
    this.refresh();
    this.isFilter = true;
    this.forResetfilters = true;
    this.FilterObj = newObject;

    this.isFilterBtn = true;

    this.filter.syncfilterColum(this.currentPage, this.FilterObj).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);

        const ParseDecryptedData = JSON.parse(decryptData);

        this.totalDatalength = ParseDecryptedData[0].total[0].count;
        this.isFilterBtn = false;

        this.catalysTable = ParseDecryptedData[0].data;
        this.currentPage = 2;
      },
      error: (err) => {
        this.isLoading = false;
        this.isFilterBtn = false;
        this.resetFilters();

        this.toaster.error(err.error.message);
      },
    });
  }

  assending(sortKey: string) {
    if (!this.productId) {
      this.modalDisplayStyle = true;
    } else {
      this.apitype = 'sort';

      this.sortNumber = this.sortKeyVal === sortKey ? this.sortNumber * -1 : 1;
      this.sortKeyVal = sortKey;

      // this.arrowname = "fa-arrow-up"
      // this.arrowticker = "fa-arrow-up"
      // this.arrowprice = "fa-arrow-up"
      // this.arrowtitle = "fa-arrow-up"
      // this.arrowcompletionDate = "fa-arrow-up"
      // this.arrowcondition = "fa-arrow-up"
      // this.arrowpipeline = "fa-arrow-up"
      // this.arrowphase = "fa-arrow-up"
      // this.arrowmarket_cap = "fa-arrow-up"
      // this.arrowlive_cash = "fa-arrow-up"
      // this.arrowvolume = "fa-arrow-up"
      // this.arrowburn_rate = "fa-arrow-up"
      // this.arrowrunway = "fa-arrow-up"
      this.refresh();

      switch (sortKey) {
        case 'LeadSponsorName':
          this.arrowname =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'ticker':
          this.arrowticker =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'company.close':
          this.arrowprice =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'BriefTitle':
          this.arrowtitle =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'parsedCompletionDate':
          this.arrowcompletionDate =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'Condition':
          this.arrowcondition =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'InterventionName':
          this.arrowpipeline =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'Phase':
          this.arrowphase =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'company.market_cap_basic':
          this.arrowmarket_cap =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'company.Cash_and_Cash_Equivalents':
          this.arrowlive_cash =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'company.volume':
          this.arrowvolume =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'company.Monthly_Burn_Rate':
          this.arrowburn_rate =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'company.Runway_(Months)':
          this.arrowrunway =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        default:
          break;
      }

      if (this.isFilter === true) {
        this.apitype = 'filtersort';
        this.isSort = true;
        this.filter
          .syncsortwithFilter(
            this.sortKeyVal,
            this.sortNumber,
            this.currentPage,
            this.FilterObj
          )
          .subscribe({
            next: (res: any) => {
              const decryptData = this.decryptionService.decrypt(res.result);

              const ParseDecryptedData = JSON.parse(decryptData);

              this.totalDatalength = ParseDecryptedData[0].total[0].count;

              ParseDecryptedData[0].data.forEach((element: any) => {
                this.catalysTable.push(element);
                this.currentPage = 2;
              });
            },
            error: (err) => {
              this.isLoading = false;
              this.isFilterBtn = false;
              this.refresh();
              this.csTableData();
              this.toaster.error(err.error.message);
            },
          });
      } else {
        this.filter
          .syncsort(this.sortKeyVal, this.sortNumber, this.currentPage)
          .subscribe({
            next: (res: any) => {
              const decryptData = this.decryptionService.decrypt(res.result);

              const ParseDecryptedData = JSON.parse(decryptData);

              this.totalDatalength = ParseDecryptedData[0].total[0].count;

              ParseDecryptedData[0].data.forEach((element: any) => {
                this.catalysTable.push(element);
                this.currentPage = 2;
              });
            },
            error: (err) => {
              this.isLoading = false;
              this.isFilterBtn = false;
              this.refresh();
              this.csTableData();
              this.toaster.error(err.error.message);
            },
          });
      }
    }
  }

  resetFilters() {
    this.searchingOrFiltering = true;
    this.isFilterBtnResert = true;
    this.isFilter = false;
    this.isSort = false;
    this.catalysTable = [];
    this.refresh();
    this.apitype = 'defualt';
    this.csTableData();

    this.clearSelectedPrice();
    this.clearSelectedChangePrice();
    this.clearSelectedMarketCap();
    this.clearSelectedDate();
    this.clearSelectedCheckbox();
    this.clearSelectedIndustry();

    this.clearDiseasesCheckbox();
    this.clearTherapeuticCheckbox();

    this.clearSelectedBurn_rate();
    this.clearSelectedrunway();
    this.clearSelectedLive_cash();
    this.clearSelectedvolume();
    // if (this.forResetfilters) {
    //   this.clearSelectedPrice();
    //   this.clearSelectedChangePrice();
    //   this.clearSelectedMarketCap();
    //   this.clearSelectedDate();
    //   this.clearSelectedCheckbox();
    //   this.clearSelectedIndustry();

    //   this.clearDiseasesCheckbox();
    //   this.clearTherapeuticCheckbox();

    //   this.clearSelectedBurn_rate();
    //   this.clearSelectedrunway();
    //   this.clearSelectedLive_cash();
    //   this.clearSelectedvolume();
    // }
  }

  selectedFilter(val: any) {
    if (val.target.value == 'Price') {
      this.showPrice = true;
    }
    if (val.target.value == 'TransactionDate') {
      this.showDate = true;
    }
    if (val.target.value == 'Market_cap') {
      this.Market_cap = true;
    }
    if (val.target.value == 'ChangeinPrice') {
      this.ChangeinPrice = true;
    }
    if (val.target.value == 'showPhase') {
      this.showPhase = true;
    }
    this.filterOpened = false;
  }

  // Select_All_checkbox() {
  //   this.ticker = this.select_all;
  //   this.list.forEach((item) => (item.checked = this.select_all));
  //   this.price = this.select_all;
  //   this.thirtydaypricechange = this.select_all;
  //   this.title = this.select_all;
  //   this.CompletionDate = this.select_all;
  //   this.Condition = this.select_all;
  //   this.Pipeline = this.select_all;
  //   this.Phase = this.select_all;
  //   this.marketcap = this.select_all;
  //   this.NetCash = this.select_all;
  //   this.BurnRate = this.select_all;
  //   this.Runway = this.select_all;
  //   this.Drugname = this.select_all;
  // }

  shareCheckedList(item: any[]) {}

  shareIndividualCheckedList(item: {}) {
    let value = Object.values(item)[1];

    switch (value) {
      case 'TICKER':
        this.ticker = !this.ticker;
        break;
      case 'PRICE':
        this.price = !this.price;
        break;
      case '30 DAYS PRICE CHANGE':
        this.thirtydaypricechange = !this.thirtydaypricechange;
        break;
      case 'TITLE':
        this.title = !this.title;
        break;
      case 'COMPLETION DATE':
        this.CompletionDate = !this.CompletionDate;
        break;
      case 'CONDITION':
        this.Condition = !this.Condition;
        break;
      case 'PIPELINE':
        this.Pipeline = !this.Pipeline;
        break;
      case 'PHASE':
        this.Phase = !this.Phase;
        break;
      case 'MARKET CAP':
        this.marketcap = !this.marketcap;
        break;
      case 'LIVE-CASH':
        this.live_cash = !this.live_cash;
        break;
      case 'VOLUME':
        this.volume = !this.volume;
        break;
      case 'BURN-RATE':
        this.burn_rate = !this.burn_rate;
        break;
      case 'RUNWAY':
        this.runway = !this.runway;
        break;
      case 'INDUSTRY':
        this.industry = !this.industry;
        break;
      default:
        break;
    }
  }

  readDes(data: any) {
    this.displayStyle = 'block';
    this.brieftitle = data;
  }
  readCondition(data: any) {
    this.displayStyleCondition = 'block';
    this.ConditionString = data;
  }
  closedes() {
    this.displayStyle = 'none';
  }
  closeCondition() {
    this.displayStyleCondition = 'none';
  }
  iconchange(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  returnLogin() {
    let loign = localStorage.getItem('loggedInUser');
    if (!loign) {
      this.showloginWarning = true;
    }
  }

  pushFilterminPriceless(data: any) {
    this.filterArray.push({ close: 'less_than_1' });
    this.selectPriceRange(data);
  }

  pushFilterminPrice1t02(data: any) {
    this.filterArray.push({ startPrice: 1 }, { endPrice: 2 });
    this.selectPriceRange(data);
  }

  pushFilterminPrice2t05(data: any) {
    this.filterArray.push({ startPrice: 2 }, { endPrice: 5 });
    this.selectPriceRange(data);
  }
  pushFilterminPrice5t50(data: any) {
    this.filterArray.push({ startPrice: 5 }, { endPrice: 50 });
    this.selectPriceRange(data);
  }
  pushFilterminPrice50t100(data: any) {
    this.filterArray.push({ startPrice: 50 }, { endPrice: 100 });
    this.selectPriceRange(data);
  }

  selectPriceRange(selectedRange: any) {
    if (selectedRange === 'customRange') {
      this.selectedPriceRange = selectedRange;
      this.isCustomRangeSelected = true;

      this.showpriceDropDown = false;
      this.isDropdownOpen['customRange'] = true;
    } else {
      if (this.selectedPriceRange === selectedRange) {
        this.selectedPriceRange = null;
      } else {
        this.selectedPriceRange = selectedRange;
      }
      this.isCustomRangeSelected = false;
    }
  }

  updateCustomRange() {
    if (this.minCustomValue && this.maxCustomValue) {
      this.selectedPriceRange = `${this.minCustomValue}$ to ${this.maxCustomValue}$`;
      this.selectedDataColor = 'selected-button';
    } else {
      this.selectedPriceRange = null;
      this.selectedDataColor = '';
    }
  }

  isSelected(selectedRange: any) {
    return this.selectedPriceRange === selectedRange;
  }

  isSelectedAnyCheckbox(): boolean {
    return this.selectlist.some((item) => item.checked);
  }

  toggleDropdown(key: string): void {
    this.isDropdownOpen[key] = !this.isDropdownOpen[key];
  }

  selectAll() {
    if (!this.disableDeselect) {
      this.selectlist.forEach((item) => (item.checked = true));
    }
  }

  deselectAll() {
    if (!this.disableDeselect) {
      this.selectlist.forEach((item) => (item.checked = false));
    }
  }

  //Pricechange filter

  pushFilterminChangePriceless(data: any) {
    this.filterArray.push({ chnage: 'less_than_1' });
    this.selectChangePriceRange(data);
  }

  pushFilterminChangePrice1t02(data: any) {
    this.filterArray.push({ startChange: 1 }, { endChange: 2 });
    this.selectChangePriceRange(data);
  }

  pushFilterminChangePrice2t05(data: any) {
    this.filterArray.push({ startChange: 2 }, { endChange: 5 });
    this.selectChangePriceRange(data);
  }
  pushFilterminChangePrice5t50(data: any) {
    this.filterArray.push({ startChange: 5 }, { endChange: 50 });
    this.selectChangePriceRange(data);
  }
  pushFilterminChangePrice50t100(data: any) {
    this.filterArray.push({ startChange: 50 }, { endChange: 100 });
    this.selectChangePriceRange(data);
  }

  updateChangePriceCustomRange() {
    if (this.minCustomChange && this.maxCustomChange) {
      this.selectedChangePriceRange = `${this.minCustomChange}% to ${this.maxCustomChange}%`;
      this.selectedchangeColor = 'selected-button';
    } else {
      this.selectedChangePriceRange = null;
      this.selectedchangeColor = '';
    }
  }

  ChangeSelected(selectedRange: any) {
    return this.selectedChangePriceRange === selectedRange;
  }

  selectChangePriceRange(selectedRange: string) {
    if (selectedRange === 'customRangechange') {
      this.selectedChangePriceRange = selectedRange;
      this.isCustomChangePriceRangeSelected = true;

      this.showchangepriceDropDown = false;
      this.isDropdownOpen['customRangechange'] = true;
    } else {
      if (this.selectedChangePriceRange === selectedRange) {
        this.selectedChangePriceRange = null;
      } else {
        this.selectedChangePriceRange = selectedRange;
      }
    }
  }

  DateSelected(selectedRange: any) {
    return this.selectedDateRange === selectedRange;
  }

  Datefilterthisweek(data: any) {
    this.filterArray.push({ week: 'thisWeek' });
    this.selectDateRange(data);
  }

  Datefilterthismonth(data: any) {
    this.filterArray.push({ month: 'thisMonth' });
    this.selectDateRange(data);
  }

  Datefilternextweek(data: any) {
    this.filterArray.push({ week: 'nextWeek' });
    this.selectDateRange(data);
  }
  Datefilternextmonth(data: any) {
    this.filterArray.push({ month: 'nextMonth' });
    this.selectDateRange(data);
  }

  selectDateRange(selectedRange: string) {
    if (selectedRange === 'customRangeDate') {
      this.selectedDateRange = selectedRange;
      this.isCustomDateRangeSelected = true;

      this.isdateDropdownOpen = false;
      this.isDropdownOpen['customRangeDate'] = true;
    } else {
      if (this.selectedDateRange === selectedRange) {
        this.selectedDateRange = null;
      } else {
        this.selectedDateRange = selectedRange;
      }
      this.toggleDropdown('Date');
    }
  }

  updateDateCustomRange() {
    if (this.minCustomDate !== undefined && this.maxCustomDate !== undefined) {
      this.selectedDateRange = `${this.minCustomDate} to ${this.maxCustomDate}`;
    }
  }

  updateBurnRateCustomRange() {
    if (this.minCustomburn_rate && this.maxCustomburn_rate) {
      this.selectedBurnRateRange = `$${this.minCustomburn_rate}m to $${this.maxCustomburn_rate}m`;
      this.selectedbutn_rateColor = 'selected-button';
    } else {
      this.selectedBurnRateRange = null;
      this.selectedbutn_rateColor = '';
    }
  }

  updateVolumeCustomRange() {
    if (this.minCustomrvolume && this.maxCustomrvolume) {
      this.selectedVolumeRange = `$${this.minCustomrvolume}m to $${this.maxCustomrvolume}m`;
      this.selectedvolumeColor = 'selected-button';
    } else {
      this.selectedVolumeRange = null;
      this.selectedvolumeColor = '';
    }
  }
  Select_All_checkbox() {
    this.toggle = !this.toggle;
    this.list.forEach((item) => {
      if (this.toggle) {
        this.ticker = true;
        this.price = true;
        this.thirtydaypricechange = true;
        this.title = true;
        this.CompletionDate = true;
        this.Condition = true;
        this.Pipeline = true;
        this.Phase = true;
        this.marketcap = true;
        this.live_cash = true;
        this.volume = true;
        this.burn_rate = true;
        this.runway = true;
        this.industry = true;
        this.list.forEach((item) => (item.checked = true));
      } else {
        this.ticker = false;
        this.price = false;
        this.thirtydaypricechange = false;
        this.title = false;
        this.CompletionDate = false;
        this.Condition = false;
        this.Pipeline = false;
        this.Phase = false;
        this.marketcap = false;
        this.live_cash = false;
        this.volume = false;
        this.burn_rate = false;
        this.runway = false;
        this.industry = false;
        this.list.forEach((item) => (item.checked = false));
      }
    });
  }

  toggleSelectAll(event: any) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.selectAll();
    } else {
      this.deselectAll();
    }
    this.toggleDropdown('Stage');
    this.pushFilterstage();
  }

  pushFilterstage() {
    this.selectedStages = this.selectlist
      .filter((item) => item.checked)
      .map((item) => item.name);
    if (this.selectedStages.length > 0) {
      this.selectedStageColor = 'selected-button';
    } else {
      this.selectedStageColor = '';
    }
    this.filterArray.push({ Phase: this.selectedStages });
    this.saveFilters();
  }

  //marketcap filter

  marcketcap200more(data: any) {
    this.filterArray.push({ market_cap: '200b_or_more' });
    this.selectMarketCapRange(data);
  }

  marcketcap10t0200(data: any) {
    this.filterArray.push({ market_cap: '10b_to_200b' });
    this.selectMarketCapRange(data);
  }

  marcketcap2t010(data: any) {
    this.filterArray.push({ market_cap: '2b_to_10b' });
    this.selectMarketCapRange(data);
  }
  marcketcap250to2(data: any) {
    this.filterArray.push({ market_cap: '250m_to_2b' });
    this.selectMarketCapRange(data);
  }
  marcketcap250less(data: any) {
    this.filterArray.push({ market_cap: '250m_or_less' });
    this.selectMarketCapRange(data);
  }

  selectMarketCapRange(selectedRange: string) {
    if (selectedRange === 'customRangemarketcap') {
      this.selectedMarketCapRange = selectedRange;
      this.isCustommarketcapRangeSelected = true;

      this.isMarketcapDropdownOpen = false;
      this.isDropdownOpen['customRangemarketcap'] = true;
    } else {
      if (this.selectedMarketCapRange === selectedRange) {
        this.selectedMarketCapRange = null;
      } else {
        this.selectedMarketCapRange = selectedRange;
      }
    }
  }

  updateMarketCapCustomRange() {
    if (this.minCustomMarket && this.maxCustomMaket) {
      this.selectedMarketCapRange = `$${this.minCustomMarket}m to $${this.maxCustomMaket}m`;
      this.selectedmarketColor = 'selected-button';
    } else {
      this.selectedMarketCapRange = null;
      this.selectedmarketColor = '';
    }
  }

  updateRunwayCustomRange() {
    if (this.minCustomrunway && this.maxCustomrunway) {
      this.selectedRunwayRange = `${this.minCustomrunway} Months to ${this.maxCustomrunway} Months`;
      this.selectedrunwayColor = 'selected-button';
    } else {
      this.selectedRunwayRange = null;
      this.selectedrunwayColor = '';
    }
  }

  updateLiveCashCustomRange() {
    if (this.minCustomlive_cash && this.maxCustomlive_cash) {
      this.selectedLiveCashRange = `$${this.minCustomlive_cash}m to $${this.maxCustomlive_cash}m`;
      this.selectedlive_cashColor = 'selected-button';
    } else {
      this.selectedLiveCashRange = null;
      this.selectedlive_cashColor = '';
    }
  }

  MarketCapSelected(selectedRange: any) {
    return this.selectedMarketCapRange === selectedRange;
  }

  pricefilterclick() {
    if (this.isDropdownOpen['customRange'] === true) {
      this.showpriceDropDown = true;
      this.isCustomRangeSelected = false;
    } else {
      this.showpriceDropDown = true;
    }
    this.isCustomDateRangeSelected = false;
    this.isCustomChangePriceRangeSelected = false;
    this.isCustommarketcapRangeSelected = false;
    this.isstageDropdownOpen = false;
  }

  changefilterclick() {
    if (this.isDropdownOpen['customRangechange'] === true) {
      this.showchangepriceDropDown = true;
      this.isCustomChangePriceRangeSelected = false;
    } else {
      this.showchangepriceDropDown = true;
    }
    this.isCustomDateRangeSelected = false;
    this.isCustomRangeSelected = false;
    this.isCustommarketcapRangeSelected = false;
    this.isstageDropdownOpen = false;
  }

  marketcapfilterclick() {
    if (this.isDropdownOpen['customRangemarketcap'] === true) {
      this.isMarketcapDropdownOpen = true;
      this.isCustommarketcapRangeSelected = false;
    } else {
      this.isMarketcapDropdownOpen = true;
    }
    this.isCustomDateRangeSelected = false;
    this.isCustomRangeSelected = false;
    this.isCustomChangePriceRangeSelected = false;
    this.isstageDropdownOpen = false;
  }

  datefilterclick() {
    if (this.isDropdownOpen['customRangeDate'] === true) {
      this.isdateDropdownOpen = true;
      this.isCustomDateRangeSelected = false;
    } else {
      this.isdateDropdownOpen = true;
    }
    this.isCustomRangeSelected = false;
    this.isCustomChangePriceRangeSelected = false;
    this.isCustommarketcapRangeSelected = false;
    this.isstageDropdownOpen = false;
  }

  clickphasefilter() {
    this.isstageDropdownOpen = true;
    this.isCustomRangeSelected = false;
    this.isCustomChangePriceRangeSelected = false;
    this.isCustommarketcapRangeSelected = false;
    this.isCustomDateRangeSelected = false;
  }

  Biotechnology(data: any) {
    this.filterArray.push({ Industry: this.BiotechnologyData });
    this.selectIndustryRange(data);
  }

  MedicalDevice(data: any) {
    this.filterArray.push({ Industry: this.MedicalDeviceData });
    this.selectIndustryRange(data);
  }

  Diagnostics(data: any) {
    this.filterArray.push({ Industry: this.DiagnosticsData });
    this.selectIndustryRange(data);
  }
  DrugManufacturers(data: any) {
    this.filterArray.push({ Industry: this.DrugData });
    this.selectIndustryRange(data);
  }

  selectIndustryRange(selectedRange: string) {
    if (this.selectedIndustryRange === selectedRange) {
      this.selectedIndustryRange = null;
    } else {
      this.selectedIndustryRange = selectedRange;
    }
  }

  Industryfilterclick() {
    this.isIndustrydownOpen = true;
    this.isMarketcapDropdownOpen = false;
    this.isCustomDateRangeSelected = false;
    this.isCustomRangeSelected = false;
    this.isCustomChangePriceRangeSelected = false;
    this.isstageDropdownOpen = false;
  }

  IndustrySelected(selectedRange: any) {
    return this.selectedIndustryRange === selectedRange;
  }

  selectedData(data: string) {
    switch (data) {
      case 'less_than_1 $': {
        this.selectedPriceRange = null;
        this.selectedValue = 'less_than_1 $';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ close: 'less_than_1' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].startPrice;
          delete this.filterArray[key].endPrice;
        }
        this.saveFilters();
        break;
      }
      case '1$ to 2$': {
        this.selectedPriceRange = null;
        this.selectedValue = '1$ to 2$';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ startPrice: 1 }, { endPrice: 2 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].close;
        }
        this.saveFilters();
        break;
      }
      case '2$ to 5$': {
        this.selectedPriceRange = null;
        this.selectedValue = '2$ to 5$';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ startPrice: 2 }, { endPrice: 5 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].close;
        }
        this.saveFilters();
        break;
      }
      case '5$ to 50$': {
        this.selectedPriceRange = null;
        this.selectedValue = '5$ to 50$';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ startPrice: 5 }, { endPrice: 50 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].close;
        }
        this.saveFilters();
        break;
      }
      case '50$ to 100$': {
        this.selectedPriceRange = null;
        this.selectedValue = '50$ to 100$';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ startPrice: 50 }, { endPrice: 100 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].close;
        }
        this.saveFilters();
        break;
      }
      case 'less_than_1 %': {
        this.selectedChangePriceRange = null;
        this.selectedValueinchange = 'less_than_1 %';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ change: 'less_than_1' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].startChange;
          delete this.filterArray[key].endChange;
        }
        this.saveFilters();
        break;
      }
      case '1% to 2%': {
        this.selectedChangePriceRange = null;
        this.selectedValueinchange = '1% to 2%';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ startChange: 1 }, { endChange: 2 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].change;
        }
        this.saveFilters();
        break;
      }
      case '2% to 5%': {
        this.selectedChangePriceRange = null;
        this.selectedValueinchange = '2% to 5%';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ startChange: 2 }, { endChange: 5 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].change;
        }
        this.saveFilters();
        break;
      }
      case '5% to 10%': {
        this.selectedChangePriceRange = null;
        this.selectedValueinchange = '5% to 10%';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ startChange: 5 }, { endChange: 10 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].change;
        }
        this.saveFilters();
        break;
      }
      case '10% to 50%': {
        this.selectedChangePriceRange = null;
        this.selectedValueinchange = '10% to 50%';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ startChange: 10 }, { endChange: 50 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].change;
        }
        this.saveFilters();
        break;
      }
      case 'This Week': {
        this.selectedDateRange = null;
        this.selectedValueinDate = 'This Week';
        this.selectedDateColor = 'selected-button';
        this.filterArray.push({ week: 'thisWeek' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].month;
          delete this.filterArray[key].start_CompletionDate;
          delete this.filterArray[key].end_CompletionDate;
        }
        this.saveFilters();
        break;
      }
      case 'This Month': {
        this.selectedDateRange = null;
        this.selectedValueinDate = 'This Month';
        this.selectedDateColor = 'selected-button';
        this.filterArray.push({ month: 'thisMonth' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].week;
          delete this.filterArray[key].start_CompletionDate;
          delete this.filterArray[key].end_CompletionDate;
        }
        this.saveFilters();
        break;
      }
      case 'Next Month': {
        this.selectedDateRange = null;
        this.selectedValueinDate = 'Next Month';
        this.selectedDateColor = 'selected-button';
        this.filterArray.push({ month: 'nextMonth' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].week;
          delete this.filterArray[key].start_CompletionDate;
          delete this.filterArray[key].end_CompletionDate;
        }
        this.saveFilters();
        break;
      }
      case 'Next Week': {
        this.selectedDateRange = null;
        this.selectedValueinDate = 'Next Week';
        this.selectedDateColor = 'selected-button';
        this.filterArray.push({ week: 'nextWeek' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].month;
          delete this.filterArray[key].start_CompletionDate;
          delete this.filterArray[key].end_CompletionDate;
        }

        this.saveFilters();
        break;
      }
      case '200b_or_more': {
        this.selectedMarketCapRange = null;
        this.selectedValueinmarket_cap = '200b_or_more';
        this.selectedmarketColor = 'selected-button';
        this.filterArray.push({ market_cap: '200b_or_more' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_market_cap;
          delete this.filterArray[key].end_market_cap;
        }
        this.saveFilters();
        break;
      }
      case '10b_to_200b': {
        this.selectedMarketCapRange = null;
        this.selectedValueinmarket_cap = '10b_to_200b';
        this.selectedmarketColor = 'selected-button';
        this.filterArray.push({ market_cap: '10b_to_200b' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_market_cap;
          delete this.filterArray[key].end_market_cap;
        }
        this.saveFilters();
        break;
      }
      case '2b_to_10b': {
        this.selectedMarketCapRange = null;
        this.selectedValueinmarket_cap = '2b_to_10b';
        this.selectedmarketColor = 'selected-button';
        this.filterArray.push({ market_cap: '2b_to_10b' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_market_cap;
          delete this.filterArray[key].end_market_cap;
        }
        this.saveFilters();
        break;
      }
      case '250m_to_2b': {
        this.selectedMarketCapRange = null;
        this.selectedValueinmarket_cap = '250m_to_2b';
        this.selectedmarketColor = 'selected-button';
        this.filterArray.push({ market_cap: '250m_to_2b' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_market_cap;
          delete this.filterArray[key].end_market_cap;
        }
        this.saveFilters();
        break;
      }
      case '250m_or_less': {
        this.selectedMarketCapRange = null;
        this.selectedValueinmarket_cap = '250m_or_less';
        this.selectedmarketColor = 'selected-button';
        this.filterArray.push({ market_cap: '250m_or_less' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_market_cap;
          delete this.filterArray[key].end_market_cap;
        }
        this.saveFilters();
        break;
      }

      case '-5b_negative': {
        this.selectedBurnRateRange = null;
        this.selectedValueinburn_rate = '-5b_negative';
        this.selectedbutn_rateColor = 'selected-button';
        this.filterArray.push({ end_br: -5000 * 1000000 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_br;
        }
        this.saveFilters();
        break;
      }
      case '-500m_-5b': {
        this.selectedBurnRateRange = null;
        this.selectedValueinburn_rate = '-500m_-5b';
        this.selectedbutn_rateColor = 'selected-button';
        this.filterArray.push(
          { start_br: -5000 * 1000000 },
          { end_br: -500 * 1000000 }
        );

        this.saveFilters();
        break;
      }
      case '-10m_-500m': {
        this.selectedBurnRateRange = null;
        this.selectedValueinburn_rate = '-10m_-500m';
        this.selectedbutn_rateColor = 'selected-button';
        this.filterArray.push(
          { start_br: -500 * 1000000 },
          { end_br: -10 * 1000000 }
        );

        this.saveFilters();
        break;
      }
      case '-10m_500m': {
        this.selectedBurnRateRange = null;
        this.selectedValueinburn_rate = '-10m_500m';
        this.selectedbutn_rateColor = 'selected-button';
        this.filterArray.push(
          { start_br: -10 * 1000000 },
          { end_br: 500 * 1000000 }
        );

        this.saveFilters();
        break;
      }
      case '500m_5b': {
        this.selectedBurnRateRange = null;
        this.selectedValueinburn_rate = '500m_5b';
        this.selectedbutn_rateColor = 'selected-button';
        this.filterArray.push(
          { start_br: 500 * 1000000 },
          { end_br: 5000 * 1000000 }
        );

        this.saveFilters();
        break;
      }
      case '5b_more': {
        this.selectedBurnRateRange = null;
        this.selectedValueinburn_rate = '5b_more';
        this.selectedbutn_rateColor = 'selected-button';
        this.filterArray.push({ start_br: 5000 * 1000000 });

        this.saveFilters();
        break;
      }

      //--------------------------------------------------      runway         -----------------------------------------------------------------

      case 'less_than_6m': {
        this.selectedRunwayRange = null;
        this.selectedValueinrunway = 'less_than_6m';
        this.selectedrunwayColor = 'selected-button';
        this.filterArray.push({ end_runway_month: 6 });

        for (let key in this.filterArray) {
          delete this.filterArray[key].start_runway_month;
        }

        this.saveFilters();
        break;
      }
      case '1y_to_2y': {
        this.selectedRunwayRange = null;
        this.selectedValueinrunway = '1y_to_2y';
        this.selectedrunwayColor = 'selected-button';
        this.filterArray.push(
          { start_runway_month: 12 },
          { end_runway_month: 24 }
        );

        this.saveFilters();
        break;
      }
      case '2_to_5y': {
        this.selectedRunwayRange = null;
        this.selectedValueinrunway = '2_to_5y';
        this.selectedrunwayColor = 'selected-button';
        this.filterArray.push(
          { start_runway_month: 24 },
          { end_runway_month: 60 }
        );

        this.saveFilters();
        break;
      }
      case '5_to_10y': {
        this.selectedRunwayRange = null;
        this.selectedValueinrunway = '5_to_10y';
        this.selectedrunwayColor = 'selected-button';
        this.filterArray.push(
          { start_runway_month: 60 },
          { end_runway_month: 120 }
        );

        this.saveFilters();
        break;
      }
      case 'morethan_10y': {
        this.selectedRunwayRange = null;
        this.selectedValueinrunway = 'morethan_10y';
        this.selectedrunwayColor = 'selected-button';
        this.filterArray.push({ start_runway_month: 120 });

        this.saveFilters();
        break;
      }

      //--------------------------------------------------      Live-Cash         -----------------------------------------------------------------

      case '200b_more L': {
        this.selectedLiveCashRange = null;
        this.selectedValueinlive_cash = '200b_more L';
        this.selectedlive_cashColor = 'selected-button';
        this.filterArray.push({ start_live_cash: 200000 * 1000000 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].end_live_cash;
        }
        this.saveFilters();
        break;
      }
      case '10b_200b L': {
        this.selectedLiveCashRange = null;
        this.selectedValueinlive_cash = '10b_200b L';
        this.selectedlive_cashColor = 'selected-button';
        this.filterArray.push(
          { start_live_cash: 10000 * 1000000 },
          { end_live_cash: 200000 * 1000000 }
        );

        this.saveFilters();
        break;
      }
      case '2b_10b L': {
        this.selectedLiveCashRange = null;
        this.selectedValueinlive_cash = '2b_10b L';
        this.selectedlive_cashColor = 'selected-button';
        this.filterArray.push(
          { start_live_cash: 10000 * 200000 },
          { end_live_cash: 10000 * 1000000 }
        );

        this.saveFilters();
        break;
      }
      case '250m_2b L': {
        this.selectedLiveCashRange = null;
        this.selectedValueinlive_cash = '250m_2b L';
        this.selectedlive_cashColor = 'selected-button';
        this.filterArray.push(
          { start_live_cash: 250 * 1000000 },
          { end_live_cash: 10000 * 200000 }
        );

        this.saveFilters();
        break;
      }
      case 'less_than_250m L': {
        this.selectedLiveCashRange = null;
        this.selectedValueinlive_cash = 'less_than_250m L';
        this.selectedlive_cashColor = 'selected-button';
        this.filterArray.push({ end_live_cash: 250 * 1000000 });

        this.saveFilters();
        break;
      }

      //--------------------------------------------------      volume         -----------------------------------------------------------------

      case 'less_than_0.1m': {
        this.selectedVolumeRange = null;
        this.selectedValueinvolume = 'less_than_0.1m';
        this.selectedvolumeColor = 'selected-button';
        this.filterArray.push({ end_volume: 10 * 10000 });

        for (let key in this.filterArray) {
          delete this.filterArray[key].start_volume;
        }

        this.saveFilters();
        break;
      }
      case '0.1m_10m': {
        this.selectedVolumeRange = null;
        this.selectedValueinvolume = '0.1m_10m';
        this.selectedvolumeColor = 'selected-button';
        this.filterArray.push(
          { start_volume: 10 * 10000 },
          { end_volume: 100 * 100000 }
        );

        this.saveFilters();
        break;
      }
      case '10m_100m': {
        this.selectedVolumeRange = null;
        this.selectedValueinvolume = '10m_100m';
        this.selectedvolumeColor = 'selected-button';
        this.filterArray.push(
          { start_volume: 100 * 100000 },
          { end_volume: 100 * 1000000 }
        );

        this.saveFilters();
        break;
      }
      case '100m_200m': {
        this.selectedVolumeRange = null;
        this.selectedValueinvolume = '100m_200m';
        this.selectedvolumeColor = 'selected-button';
        this.filterArray.push(
          { start_volume: 100 * 1000000 },
          { end_volume: 100 * 2000000 }
        );

        this.saveFilters();
        break;
      }
      case 'more_than_200m': {
        this.selectedVolumeRange = null;
        this.selectedValueinvolume = 'more_than_200m';
        this.selectedvolumeColor = 'selected-button';
        this.filterArray.push({ start_volume: 100 * 2000000 });

        for (let key in this.filterArray) {
          delete this.filterArray[key].end_volume;
        }

        this.saveFilters();
        break;
      }

      //--------------------------------------------------      industry         -----------------------------------------------------------------
      case 'Biotechnology': {
        this.selectedindustryColor = 'selected-button';
        this.selectedValueinindustry = 'Biotechnology';
        const Biotechnology = [
          'Biotechnology',
          'Tobacco',
          'Real Estate Services',
          'Chemicals',
          ' N/A',
          'Farm Products',
          'Food Distribution',
          'Packaged Foods',
        ];
        this.filterArray.push({ Industry: Biotechnology });
        this.saveFilters();
        break;
      }
      case 'Medical Device': {
        this.selectedindustryColor = 'selected-button';
        this.selectedValueinindustry = 'Medical Device';
        const Medical_Device = [
          'Medical Devices',
          'Medical Instruments & Supplies',
          'Electronic Components',
          'Scientific & Technical Instruments',
          'Health Information Services',
          'Medical Care Facilities',
          'Furnishings',
          'Fixtures & Appliances',
          'Medical Distribution',
        ];
        this.filterArray.push({ Industry: Medical_Device });
        this.saveFilters();
        break;
      }
      case 'Diagnostics & Research': {
        this.selectedindustryColor = 'selected-button';
        this.selectedValueinindustry = 'Diagnostics & Research';
        const Diagnostics = ['Diagnostics & Research'];
        this.filterArray.push({ Industry: Diagnostics });
        this.saveFilters();
        break;
      }
      case 'Drug Manufacturers': {
        this.selectedindustryColor = 'selected-button';
        this.selectedValueinindustry = 'Drug Manufacturers';

        const Drug_Manufacturers = [
          'Drug Manufacturers—General',
          'Drug Manufacturers—Specialty & Generic',
          'Household & Personal Products,Pharmaceutical Retailers',
        ];
        this.filterArray.push({ Industry: Drug_Manufacturers });
        this.saveFilters();
        break;
      }
    }
  }

  // refreshbtn() {
  //   this.refresh();
  //   this.isFilter = false
  //   this.isSort = false
  //   this.apitype = 'defualt';
  //   this.csTableData();
  // }

  clearSelectedPrice() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].close;
      delete this.filterArray[key].startPrice;
      delete this.filterArray[key].endPrice;
    }

    this.selectedPriceRange = null;
    this.minCustomValue = 0;
    this.maxCustomValue = 0;
    this.selectedDataColor = '';
    this.selectedValue = '';

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedChangePrice() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].change;
      delete this.filterArray[key].startChange;
      delete this.filterArray[key].endChange;
    }

    this.selectedChangePriceRange = null;
    this.minCustomChange = 0;
    this.maxCustomChange = 0;
    this.selectedchangeColor = '';
    this.selectedValueinchange = '';

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedDate() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].start_CompletionDate;
      delete this.filterArray[key].end_CompletionDate;
      delete this.filterArray[key].month;
      delete this.filterArray[key].week;
    }

    this.selectedDateRange = null;
    this.maxCustomDate = 0;
    this.minCustomDate = 0;
    this.selectedDateColor = '';
    this.selectedValueinDate = '';

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedCheckbox() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].Phase;
    }

    this.selectedStages = [];
    this.selectedStageColor = '';
    this.selectlist.forEach((item) => (item.checked = false));
    this.isDropdownOpen['Phase'] = false;

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedIndustry() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].Industry;
    }

    this.selectedindustryColor = '';
    this.selectedValueinindustry = '';

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedMarketCap() {
    for (let key in this.filterArray) {
      delete this.filterArray[key]?.market_cap;
      delete this.filterArray[key]?.start_market_cap;
      delete this.filterArray[key]?.end_market_cap;
    }

    this.selectedMarketCapRange = null;
    this.maxCustomMaket = 0;
    this.minCustomMarket = 0;
    this.selectedmarketColor = '';
    this.selectedValueinmarket_cap = '';

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedBurn_rate() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].start_br;
      delete this.filterArray[key].end_br;
    }

    this.selectedBurnRateRange = null;
    this.maxCustomburn_rate = 0;
    this.minCustomburn_rate = 0;
    this.selectedValueinburn_rate = '';
    this.selectedbutn_rateColor = '';

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedLive_cash() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].start_live_cash;
      delete this.filterArray[key].end_live_cash;
    }

    this.selectedLiveCashRange = null;
    this.maxCustomlive_cash = 0;
    this.minCustomlive_cash = 0;
    this.selectedValueinlive_cash = '';
    this.selectedlive_cashColor = '';

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedvolume() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].start_volume;
      delete this.filterArray[key].end_volume;
    }

    this.selectedVolumeRange = null;
    this.minCustomrvolume = 0;
    this.maxCustomrvolume = 0;
    this.selectedValueinvolume = '';
    this.selectedvolumeColor = '';

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedrunway() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].start_runway_month;
      delete this.filterArray[key].end_runway_month;
    }

    this.selectedRunwayRange = null;
    this.minCustomrunway = 0;
    this.maxCustomrunway = 0;
    this.selectedValueinrunway = '';
    this.selectedrunwayColor = '';

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  pushFilterminPrice(data: any) {
    // this.selectedDataColor = 'selected-button';
    this.filterArray.push({ startPrice: Number(data.target.value) });
  }

  pushFiltermaxPrice(data: any) {
    // this.selectedDataColor = 'selected-button';
    this.filterArray.push({ endPrice: Number(data.target.value) });
  }

  pushFilterChange(data: any) {
    // this.selectedchangeColor = 'selected-button';
    this.filterArray.push({ startChange: Number(data.target.value) });
  }

  EndpushFilterChange(data: any) {
    // this.selectedchangeColor = 'selected-button';
    this.filterArray.push({ endChange: Number(data.target.value) });
  }

  pushFilterlive_cash(data: any) {
    // this.selectedlive_cashColor = 'selected-button';
    this.filterArray.push({
      start_live_cash: this.minCustomlive_cash * 1000000,
    });
  }

  EndpushFilterlive_cash(data: any) {
    // this.selectedlive_cashColor = 'selected-button';
    this.filterArray.push({ end_live_cash: this.maxCustomlive_cash * 1000000 });
  }

  pushFilterDateStart(data: any) {
    for (let key in this.filterArray) {
      delete this.filterArray[key].week;
      delete this.filterArray[key].month;
    }
    this.selectedDateColor = 'selected-button';
    this.filterArray.push({ start_CompletionDate: data.target.value });
  }

  pushFilterDateEnd(data: any) {
    for (let key in this.filterArray) {
      delete this.filterArray[key].week;
      delete this.filterArray[key].month;
    }
    this.selectedDateColor = 'selected-button';
    this.filterArray.push({ end_CompletionDate: data.target.value });
  }

  pushFilterMarket_cap(data: any) {
    for (let key in this.filterArray) {
      delete this.filterArray[key].market_cap;
    }
    // this.selectedmarketColor = 'selected-button';
    this.filterArray.push({
      start_market_cap: Number(data.target.value) * 1000000,
    });
  }

  EndpushFilterMarket_cap(data: any) {
    // this.selectedmarketColor = 'selected-button';
    this.filterArray.push({
      end_market_cap: Number(data.target.value) * 1000000,
    });
  }
  pushFilterBurn_rate(data: any) {
    // this.selectedbutn_rateColor = 'selected-button';
    this.filterArray.push({ start_br: this.minCustomburn_rate * 1000000 });
  }

  EndpushFilterBurn_rate(data: any) {
    // this.selectedbutn_rateColor = 'selected-button';
    this.filterArray.push({ end_br: this.maxCustomburn_rate * 1000000 });
  }
  pushFilterrunway(data: any) {
    // this.selectedrunwayColor = 'selected-button';
    this.filterArray.push({ start_runway_month: this.minCustomrunway });
  }

  EndpushFilterrunway(data: any) {
    // this.selectedrunwayColor = 'selected-button';
    this.filterArray.push({ end_runway_month: this.maxCustomrunway });
  }

  pushFiltervolume(data: any) {
    // this.selectedvolumeColor = 'selected-button';
    this.filterArray.push({ start_volume: this.minCustomrvolume * 1000000 });
  }

  EndpushFiltervolume(data: any) {
    // this.selectedvolumeColor = 'selected-button';
    this.filterArray.push({ end_volume: this.maxCustomrvolume * 1000000 });
  }

  searchFilters(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;

    if (event.key === 'Escape' && !inputElement.value) {
      this.suggection = [];
      return;
    }

    if (!inputElement.value || !this.searchValuequery) {
      this.suggection = [];
      return;
    }

    this.compamyDetails.searchsuggection(inputElement.value).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);

        const ParseDecryptedData = JSON.parse(decryptData);

        this.suggection = ParseDecryptedData;

        // this.symbol = res[0].symbol
      },
    });
  }

  onSearchInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;

    if (inputValue === '') {
      this.suggection = [];
    }
  }

  searchFiltersonclick(query: any) {
    let loginuser = localStorage.getItem('loggedInUser');

    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
    if (query == '') {
      this.toaster.warning('Enter Valid Inputs.');
      return;
    }

    let search = {
      query,
    };
    this.suggection = [];
    this.searchValuequery = '';

    this.isLoading = true;
    this.loadData = false;
    this.isFilterBtnSearch = true;
    this.forResetfilters = true;
    this.isFilter = true;

    this.catalysTable = [];

    this.filter.syncfilterData(search).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);

        const ParseDecryptedData = JSON.parse(decryptData);

        this.isFilterBtnSearch = false;
        this.forResetfilters = false;
        this.totalDatalength = ParseDecryptedData[0].data.length;
        this.catalysTable = ParseDecryptedData[0].data;
        this.suggection = [];
        this.isLoading = true;
      },
      error: (err) => {
        this.isFilterBtnSearch = false;
        this.forResetfilters = false;
        this.searchValuequery = '';
        this.catalysTable = [];
        this.csTableData();
        this.toaster.error(err.error.message);
      },
    });
  }

  // addSymboltoPotfoliyo(symbol: any) {
  //   let loginuser = JSON.parse(localStorage.getItem('loggedInUser') || '{}');

  //   let obj = {
  //     email: loginuser.email,
  //     ticker: symbol
  //   }

  //   if (loginuser.email) {
  //     this.portfoliomatchTicker = symbol

  //     if (obj) {
  //       this.fdacalendarservice.addSymboltoPotfoliyo(obj).subscribe({
  //         next: ((res: any) => {
  //           const decryptData = this.decryptionService.decrypt(res);

  //           const ParseDecryptedData = JSON.parse(decryptData);
  //           this.portfoliomatchTicker = ParseDecryptedData.portfolio
  //           this.toaster.success('Symbol added to your portfolio');
  //         })
  //       })
  //     }
  //   } else {
  //     this.modalDisplayStyle = true;
  //   }
  // }

  removeSymboltoPotfoliyo(symbol: any) {
    let loginuser = JSON.parse(localStorage.getItem('loggedInUser') || '{}');

    let obj = {
      email: loginuser.email,
      ticker: symbol,
    };

    if (obj) {
      this.fdacalendarservice.removeSymboltoPotfoliyo(obj).subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res);
          this.portfoliomatchTicker = [];
          const ParseDecryptedData = JSON.parse(decryptData);
          ParseDecryptedData.portfolio.forEach((element: any) => {
            this.portfoliomatchTicker.push(element.ticker);
          });

          this.toaster.success('Symbol removed from your portfolio');
        },
      });
    }
  }

  onAddSymbol(data: any) {
    this.portfoliomatchTicker.push(data.ticker);
    return;
  }

  clearDiseasesCheckbox() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].Specific_Diseases;
    }

    this.selectedDiseases = [];
    this.selectedDiseasesColor = '';
    this.diseasesList.forEach((item) => (item.checked = false));
    this.isDropdownOpen['Specific_Diseases'] = false;

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  toggleDiseasesSelect(event: any) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.selectDiseases();
    } else {
      this.deselectDiseases();
    }
    this.toggleDropdown('Specific_Diseases');
    this.pushFilterDiseases();
  }

  selectDiseases() {
    if (!this.disableDeselect) {
      this.diseasesList.forEach((item) => (item.checked = true));
    }
  }

  deselectDiseases() {
    if (!this.disablediseasesList) {
      this.diseasesList.forEach((item) => (item.checked = false));
    }
  }

  pushFilterDiseases() {
    this.selectedDiseases = this.diseasesList
      .filter((item) => item.checked)
      .map((item) => item.name);
    if (this.selectedDiseases.length > 0) {
      this.selectedDiseasesColor = 'selected-button';
    } else {
      this.selectedDiseasesColor = '';
    }
    this.filterArray.push({ Specific_Diseases: this.selectedDiseases });
    this.saveFilters();
  }

  clearTherapeuticCheckbox() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].Therapeutic_area;
    }

    this.selectedTherapeutic = [];
    this.selectedTherapeuticColor = '';
    this.therapeuticList.forEach((item) => (item.checked = false));
    this.isDropdownOpen['Therapeutic_area'] = false;

    if (this.isFilter) {
      if (this.filterArray.every((item) => Object.keys(item).length === 0)) {
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  toggleTheraputics(event: any) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.selectTheraputics();
    } else {
      this.deselectTheraputics();
    }
    this.toggleDropdown('Therapeutic_area');
    this.pushFilterTherapeutic();
  }

  selectTheraputics() {
    if (!this.disableTheraputics) {
      this.therapeuticList.forEach((item) => (item.checked = true));
    }
  }

  deselectTheraputics() {
    if (!this.disableTheraputics) {
      this.therapeuticList.forEach((item) => (item.checked = false));
    }
  }

  pushFilterTherapeutic() {
    this.selectedTherapeutic = this.therapeuticList
      .filter((item) => item.checked)
      .map((item) => item.name);
    if (this.selectedTherapeutic.length > 0) {
      this.selectedTherapeuticColor = 'selected-button';
    } else {
      this.selectedTherapeuticColor = '';
    }
    this.filterArray.push({ Therapeutic_area: this.selectedTherapeutic });
    this.saveFilters();
  }
  
  setDownloadExcelTooltip(){
    if(this.userToken){
      if(this.credits! > 0){
        this.downloadExcelTooltipValue = 'Available Credits: ' + this.credits;
      } else {
        this.downloadExcelTooltipValue = 'No Credits Available';
      }
    } else {
      this.downloadExcelTooltipValue = 'Please signin to download Excel data';
    }
  }
}
