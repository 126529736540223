import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FooterService } from 'src/app/modules/core/services/footer.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrl: './unsubscribe.component.scss',
})
export class UnsubscribeComponent {
  email: string = '';
  password: string = '';
  message: string = '';
  @ViewChild('triggerButton') triggerButton!: ElementRef;
  @ViewChild('closeButton') closeButton!: ElementRef;

  constructor(
    private subscribed: FooterService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.triggerButton.nativeElement.click();
    }, 0);
  }

  onUnsubscribe() {
    if (!this.email || !this.password) {
      this.toastr.error('Email and password are required!', 'Error');
      return;
    }

    this.subscribed
      .ubsubscribeApi({ email: this.email, password: this.password })
      .subscribe({
        next: () => {
          setTimeout(() => {
            this.closeButton.nativeElement.click();
          }, 0);

          this.router.navigate(['/']);
          setTimeout(() => {
            window.location.reload();
          }, 0);

          this.toastr.success('Unsubscribed successfully!', 'Success');
        },
        error: (err) => {
          const errorMessage =
            err?.error?.message || 'An error occurred. Please try again.';
          this.toastr.error(errorMessage, 'Error');
        },
      });
  }
}
