import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { checkCredits, createcheckout, createcheckoutcredits } from '../const';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {

  isSubscribed = new BehaviorSubject<boolean>(false);
  
  constructor(private http: HttpClient) {}

  createcheckoutsession(data: any) {
    return this.http.post(`${createcheckout}`, data);
  }

  createcheckoutsessioncredits(data: any){
    return this.http.post(`${createcheckoutcredits}`, data);
  }

  checkAvailableCredits(dataSet: any){
    return this.http.post(`${checkCredits}`, dataSet);
  }
}
