<!-- Trigger Button -->
<button
  #triggerButton
  style="display: none"
  type="button"
  class="btn btn-danger"
  data-bs-toggle="modal"
  data-bs-target="#unsubscribeModal"
>
  Unsubscribe
</button>

<!-- Unsubscribe Modal -->
<div
  class="modal fade"
  id="unsubscribeModal"
  tabindex="-1"
  aria-labelledby="unsubscribeModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="unsubscribeModalLabel">
          Confirm Unsubscription
        </h5>
        <button
          #closeButton
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <form (ngSubmit)="onUnsubscribe()" #unsubscribeForm="ngForm">
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input
              type="email"
              id="email"
              class="form-control"
              name="email"
              [(ngModel)]="email"
              required
              placeholder="Enter your email"
            />
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Password</label>
            <input
              type="password"
              id="password"
              class="form-control"
              name="password"
              [(ngModel)]="password"
              required
              placeholder="Enter your password"
            />
          </div>
          <button
            type="submit"
            class="btn btn-danger"
            [disabled]="unsubscribeForm.invalid"
          >
            Confirm Unsubscribe
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
