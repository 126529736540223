<div class="container-fluid p-2 fda-header-box">
  <div class="text-center fda-text">
    <h1 class="main-heading text-white m-0 me-3">BPW Porfolios</h1>

    <!-- <p class="sub-heading text-white m-0">
      Our Free FDA Calendar is a daily updated tool that tracks future
      catalysts and key dates across biotech and pharma companies, including
      clinical trials, regulatory events, and PDUFA dates, designed to inform
      on stock-moving events
    </p> -->
  </div>
</div>

<div class="container-xxl">
  <div class="row">
    <!-- Top 12 bio hedge -->
    <div class="bpw-section col py-3 justify-content-center h-100" style="min-width: 250px;">
      <div class="col-12">
        <div class="card shadow m-1">
          @for (item of this.bioHedgeFundShots; track $index) {
          <div class="card-body row justify-content-around align-items-center">
            <h4 class="text-center">{{ item.name }}</h4>
            <hr />
            <div class="container">
              <div class="row">
                <div
                  id="dataContainer"
                  style="max-height: 500px !important"
                  #dataContainer
                  class="Short-table table-responsive all-table-scroll col-12 col-lg d-flex align-items-center flex-column"
                >
                  <table class="table table-fixed table-lock-height mb-0">
                    <thead class="sticky-header head-z-index">
                      <tr class="text-uppercase table-header">
                        <th class="px-2 text-center py-0 sticky-ticker">
                          <div
                            class="dropdown d-flex justify-content-center"
                          >
                            <button
                              class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle fw-bold"
                              #priceinput2
                              role="button"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="outside"
                              aria-expanded="false"
                            >
                              {{
                                selectedQuarterData
                                  ? selectedQuarterData
                                  : "Quarter"
                              }}
                              <i class="fa fa-caret-down ms-2"></i>
                            </button>
                            <ul class="dropdown-menu games-menu">
                              <li>
                                <p
                                  class="dropdown-item filter-font-text text-center filter-bottom m-0"
                                  (click)="changeQuarter('Q2')"
                                >
                                  Q2 2024
                                </p>
                              </li>
                              <li>
                                <p
                                  class="dropdown-item filter-font-text text-center m-0"
                                  (click)="changeQuarter('Q3')"
                                >
                                  Q3 2024
                                </p>
                              </li>
                            </ul>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      @if(selectedQuarterData === 'Q2 2024') { @if
                      (isSubscribed) { @for (item of item.symbols; track $index)
                      {
                      <tr>
                        <td
                          style="cursor: pointer; vertical-align: middle"
                          class="sticky-ticker"
                        >
                          <div
                            class="text-center company-hover"
                            (click)="companyDetails(item)"
                          >
                            <p
                              class="table-font-size mb-0 text-center"
                            >
                              {{ item }}
                            </p>
                          </div>
                        </td>
                      </tr>
                      }} @else { @for (item of item.symbols.slice(0,3); track
                      $index) {
                      <tr>
                        <td
                          style="cursor: pointer; vertical-align: middle"
                          class="sticky-ticker"
                        >
                          <div
                            class="text-center company-hover"
                            (click)="companyDetails(item)"
                          >
                            <p
                              class="company-img-space table-font-size mb-0 text-center"
                            >
                              {{ item }}
                            </p>
                          </div>
                        </td>
                      </tr>
                      } @for (item of fakeSymbols; track $index) {
                      <tr>
                        <td
                          style="cursor: pointer; vertical-align: middle"
                          class="sticky-ticker position-relative"
                        >
                            <i
                              class="fas fa-lock position-absolute top-50 start-50 translate-middle z-3"
                            ></i>
                            <div
                              class="text-center company-hover blurred"
                              (click)="companyDetails(item)"
                            >
                              <p
                                class="company-img-space table-font-size mb-0 text-center"
                              >
                                {{ item }}
                              </p>
                            </div>
                        </td>
                      </tr>
                      } } } @else {
                        @if
                      (isSubscribed) { @for (item of item.symbols_b4_dec; track $index)
                      {
                      <tr>
                        <td
                          style="cursor: pointer; vertical-align: middle"
                          class="sticky-ticker"
                        >
                          <div
                            class="text-center company-hover"
                            (click)="companyDetails(item)"
                          >
                            <p
                              class="company-img-space table-font-size mb-0 text-center"
                            >
                              {{ item }}
                            </p>
                          </div>
                        </td>
                      </tr>
                      }} @else { @for (item of item.symbols_b4_dec.slice(0,3); track
                      $index) {
                      <tr>
                        <td
                          style="cursor: pointer; vertical-align: middle"
                          class="sticky-ticker"
                        >
                          <div
                            class="text-center company-hover"
                            (click)="companyDetails(item)"
                          >
                            <p
                              class="company-img-space table-font-size mb-0 text-center"
                            >
                              {{ item }}
                            </p>
                          </div>
                        </td>
                      </tr>
                      } @for (item of fakeSymbols; track $index) {
                      <tr>
                        <td
                          style="cursor: pointer; vertical-align: middle"
                          class="sticky-ticker position-relative"
                        >
                            <i
                              class="fas fa-lock position-absolute top-50 start-50 translate-middle z-3"
                            ></i>
                            <div
                              class="text-center company-hover blurred"
                              (click)="companyDetails(item)"
                            >
                              <p
                                class="company-img-space table-font-size mb-0 text-center"
                              >
                                {{ item }}
                              </p>
                            </div>
                        </td>
                      </tr>
                      } } 
                       }
                    </tbody>
                  </table>
                </div>
                <div class="col d-flex flex-column align-items-center d-lg-block text-center text-lg-start">
                  <div class="m-3">
                    <p class="m-0"><b>Return from Aug 15 2024</b></p>
                    <h2 class="text-success my-2">
                      <strong
                        >{{
                          (
                            item["Return_from_Aug_15_2024%"] +
                            item["Return_from_Aug_15_2024_to_Nov_15_2024%"]
                          ).toFixed(2)
                        }}
                        %</strong
                      >
                    </h2>
                    <!-- <h5 class="card-title">
                    {{ item.Return_from_Aug_15_2024.toFixed(2) }}
                  </h5> -->
                  </div>
                  <div class="m-3 mt-0">
                    <p class="m-0"><b>Return from YTD</b></p>
                    <h2 class="text-success my-2">
                      <strong
                        >{{
                          item["Return_for_Last_1_Year%"].toFixed(2)
                        }}
                        %</strong
                      >
                    </h2>
                    <!-- <h5 class="card-title my-2">
                    {{ item.Return_for_Last_1_Year.toFixed(2) }}
                  </h5> -->
                  </div>
                  @if(!isSubscribed){
                    <button
                      routerLink="/subscription"
                      class="form-save-button mb-2 mt-3 m-3"
                    >
                      Start Free Trial
                    </button>
                    }
                </div>
              </div>
              <!-- <div
                class="text-center d-flex flex-wrap justify-content-center align-items-center"
              >
                <span class="m-0 me-2"><b>Q3 2024</b></span>
                @if (isSubscribed) { @for (item of item.symbols; track $index) {
                <span
                  (click)="companyDetails(item)"
                  class="most-value-btn m-1"
                  style="font-size: 13px; cursor: pointer"
                >
                  {{ item }} </span
                >}}@else { @for (item of item.symbols.slice(0,3); track $index) {
                <span
                  (click)="companyDetails(item)"
                  class="most-value-btn m-1"
                  style="font-size: 13px; cursor: pointer"
                >
                  {{ item }}
                </span>
                } @for (item of fakeSymbols; track $index) {
                <span class="m-1 position-relative">
                  <i
                    class="fas fa-lock position-absolute top-50 start-50 translate-middle z-3"
                  ></i>
                  <span
                    class="most-value-btn blurred"
                    style="font-size: 13px; cursor: pointer"
                  >
                    {{ item }}
                  </span>
                </span>
                } }
              </div> -->
              <!-- <div
                class="text-center d-flex flex-wrap justify-content-center align-items-center mt-2"
              >
                <b class="me-2 stock-returns">
                  {{ item["Return_from_Aug_15_2024_to_Nov_15_2024%"].toFixed(2) }}%
                </b>
                <span class="m-0 me-2"><b>Q2 2024</b></span>
                @if (isSubscribed) { @for (item of item.symbols_b4_dec; track
                $index) {
                <span
                  (click)="companyDetails(item)"
                  class="most-value-btn m-1"
                  style="font-size: 13px; cursor: pointer"
                >
                  {{ item }} </span
                >}}@else { @for (item of item.symbols_b4_dec.slice(0,3); track
                $index) {
                <span
                  (click)="companyDetails(item)"
                  class="most-value-btn m-1"
                  style="font-size: 13px; cursor: pointer"
                >
                  {{ item }}
                </span>
                } @for (item of fakeSymbols; track $index) {
                <span class="position-relative m-1">
                  <i
                    class="fas fa-lock position-absolute top-50 start-50 translate-middle z-3"
                  ></i>
                  <span
                    class="most-value-btn blurred"
                    style="font-size: 13px; cursor: pointer"
                  >
                    {{ item }}
                  </span>
                </span>
                } }
                @for (item of item.symbols_b4_dec; track $index) {
                <span
                  (click)="companyDetails(item)"
                  class="most-value-btn me-1"
                  style="font-size: 13px; cursor: pointer"
                >
                  {{ item }} </span
                >}
              </div> -->
            </div>
          </div>
          }
        </div>
      </div>
    </div>

    <!-- oncology  -->
    <div class="bpw-section col py-3 justify-content-center" style="min-width: 250px;">
      <div class="col-12">
        <div class="card shadow m-1">
          @for (item of this.oncologyData; track $index) {
          <div class="card-body row justify-content-around align-items-center">
            <h4 class="text-center">{{ item.name }}</h4>
            <hr />
            <div class="container">
              <div class="row">
                <div
                  id="dataContainer"
                  style="max-height: 500px !important"
                  #dataContainer
                  class="Short-table table-responsive all-table-scroll col-12 col-lg d-flex align-items-center flex-column"
                >
                  <table class="table table-fixed table-lock-height mb-0">
                    <thead class="sticky-header head-z-index">
                      <tr class="text-uppercase table-header">
                        <th class="px-2 text-center py-2 sticky-ticker">
                          Ticker
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      @if
                      (isSubscribed) { @for (item of item.symbols; track $index)
                      {
                      <tr>
                        <td
                          style="cursor: pointer; vertical-align: middle"
                          class="sticky-ticker"
                        >
                          <div
                            class="text-center company-hover"
                            (click)="companyDetails(item)"
                          >
                            <p
                              class="table-font-size mb-0 text-center"
                            >
                              {{ item }}
                            </p>
                          </div>
                        </td>
                      </tr>
                      }} @else { @for (item of item.symbols.slice(0,3); track
                      $index) {
                      <tr>
                        <td
                          style="cursor: pointer; vertical-align: middle"
                          class="sticky-ticker"
                        >
                          <div
                            class="text-center company-hover"
                            (click)="companyDetails(item)"
                          >
                            <p
                              class="company-img-space table-font-size mb-0 text-center"
                            >
                              {{ item }}
                            </p>
                          </div>
                        </td>
                      </tr>
                      } @for (item of fakeSymbolsOncology; track $index) {
                      <tr>
                        <td
                          style="cursor: pointer; vertical-align: middle"
                          class="sticky-ticker position-relative"
                        >
                            <i
                              class="fas fa-lock position-absolute top-50 start-50 translate-middle z-3"
                            ></i>
                            <div
                              class="text-center company-hover blurred"
                              (click)="companyDetails(item)"
                            >
                              <p
                                class="company-img-space table-font-size mb-0 text-center"
                              >
                                {{ item }}
                              </p>
                            </div>
                        </td>
                      </tr>
                      } }
                    </tbody>
                  </table>
                </div>
                <div class="col d-flex flex-column align-items-center d-lg-block text-center text-lg-start">
                  <div class="m-3">
                    <p class="m-0"><b>Return from Last 1 Month</b></p>
                    <h2 class="text-success my-2">
                      <strong
                        >{{ item["Return_for_Last_1_Month%"].toFixed(2) }} %</strong
                      >
                    </h2>
                    <!-- <h5 class="card-title my-2">
                    {{ item.Return_for_Last_1_Month.toFixed(2) }}
                  </h5> -->
                  </div>
                  <div class="m-3 mt-0">
                    <p class="m-0"><b>Return from Last 1 Year</b></p>
                    <h2 class="text-success my-2">
                      <strong
                        >{{ item["Return_for_Last_1_Year%"].toFixed(2) }} %</strong
                      >
                    </h2>
                    <!-- <h5 class="card-title my-2">
                    {{ item.Return_for_Last_1_Year.toFixed(2) }}
                  </h5> -->
                  </div>
                  @if(!isSubscribed){
                    <button
                      routerLink="/subscription"
                      class="form-save-button mb-2 mt-3"
                    >
                      Start Free Trial
                    </button>
                    }
                </div>
              </div>
            </div>
            <!-- <div
              class="text-center d-flex flex-wrap justify-content-center align-items-center"
            >
              @if (isSubscribed) { @for (item of item.symbols; track $index) {
              <span
                (click)="companyDetails(item)"
                class="most-value-btn m-1"
                style="font-size: 13px; cursor: pointer"
              >
                {{ item }} </span
              >}}@else { @for (item of item.symbols.slice(0,3); track $index) {
              <span
                (click)="companyDetails(item)"
                class="most-value-btn m-1"
                style="font-size: 13px; cursor: pointer"
              >
                {{ item }}
              </span>
              } @for (item of fakeSymbolsOncology; track $index) {
              <span class="position-relative m-1">
                <i
                  class="fas fa-lock position-absolute top-50 start-50 translate-middle z-3"
                ></i>
                <span
                  class="most-value-btn blurred"
                  style="font-size: 13px; cursor: pointer"
                >
                  {{ item }}
                </span>
              </span>
              } }
            </div> -->
          </div>
          }
        </div>
      </div>
    </div>

    <!-- HedgefundFamous   -->
    <div class="bpw-section col py-3 justify-content-center" style="min-width: 250px;">
      <div class="col-12">
        <div class="card shadow m-1">
          @for (item of this.HedgefundFamousData; track $index) {
          <div class="card-body row justify-content-around align-items-center">
            <h4 class="text-center">{{ item.name }}</h4>
            <hr />
            <div class="container">
              <div class="row">
                <div
                  id="dataContainer"
                  style="max-height: 500px !important"
                  #dataContainer
                  class="Short-table table-responsive all-table-scroll col-12 col-lg d-flex align-items-center flex-column"
                >
                  <table class="table table-fixed table-lock-height mb-0">
                    <thead class="sticky-header head-z-index">
                      <tr class="text-uppercase table-header">
                        <th class="px-2 text-center py-2 sticky-ticker">
                          Ticker
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      @if
                      (isSubscribed) { @for (item of item.symbols; track $index)
                      {
                      <tr>
                        <td
                          style="cursor: pointer; vertical-align: middle"
                          class="sticky-ticker"
                        >
                          <div
                            class="text-center company-hover"
                            (click)="companyDetails(item)"
                          >
                            <p
                              class="table-font-size mb-0 text-center"
                            >
                              {{ item }}
                            </p>
                          </div>
                        </td>
                      </tr>
                      }} @else { @for (item of item.symbols.slice(0,3); track
                      $index) {
                      <tr>
                        <td
                          style="cursor: pointer; vertical-align: middle"
                          class="sticky-ticker"
                        >
                          <div
                            class="text-center company-hover"
                            (click)="companyDetails(item)"
                          >
                            <p
                              class="company-img-space table-font-size mb-0 text-center"
                            >
                              {{ item }}
                            </p>
                          </div>
                        </td>
                      </tr>
                      } @for (item of fakeSymbolsOncology; track $index) {
                      <tr>
                        <td
                          style="cursor: pointer; vertical-align: middle"
                          class="sticky-ticker position-relative"
                        >
                            <i
                              class="fas fa-lock position-absolute top-50 start-50 translate-middle z-3"
                            ></i>
                            <div
                              class="text-center company-hover blurred"
                              (click)="companyDetails(item)"
                            >
                              <p
                                class="company-img-space table-font-size mb-0 text-center"
                              >
                                {{ item }}
                              </p>
                            </div>
                        </td>
                      </tr>
                      } }
                    </tbody>
                  </table>
                </div>
                <div class="col d-flex flex-column align-items-center d-lg-block text-center text-lg-start">
                  <div class="m-3">
                    <p class="m-0"><b>Return from Aug 15 2024</b></p>
                    <h2 class="text-success my-2">
                      <strong
                        >{{ item["Return_from_Nov_15_2024%"].toFixed(2) }} %</strong
                      >
                    </h2>
                    <!-- <h5 class="card-title my-2">
                    {{ item.Return_for_Last_1_Month.toFixed(2) }}
                  </h5> -->
                  </div>
                  <div class="m-3 mt-0">
                    <p class="m-0"><b>Return from YTD</b></p>
                    <h2 class="text-success my-2">
                      <strong
                        >{{ item["Return_for_Last_1_Year%"].toFixed(2) }} %</strong
                      >
                    </h2>
                    <!-- <h5 class="card-title my-2">
                    {{ item.Return_for_Last_1_Year.toFixed(2) }}
                  </h5> -->
                  </div>
                  @if(!isSubscribed){
                    <button
                      routerLink="/subscription"
                      class="form-save-button mb-2 mt-3"
                    >
                      Start Free Trial
                    </button>
                    }
                </div>
              </div>
            </div>
            <!-- <div
              class="text-center d-flex flex-wrap justify-content-center align-items-center"
            >
              @if (isSubscribed) { @for (item of item.symbols; track $index) {
              <span
                (click)="companyDetails(item)"
                class="most-value-btn m-1"
                style="font-size: 13px; cursor: pointer"
              >
                {{ item }} </span
              >}}@else { @for (item of item.symbols.slice(0,3); track $index) {
              <span
                (click)="companyDetails(item)"
                class="most-value-btn m-1"
                style="font-size: 13px; cursor: pointer"
              >
                {{ item }}
              </span>
              } @for (item of fakeSymbols; track $index) {
              <span class="position-relative m-1">
                <i
                  class="fas fa-lock position-absolute top-50 start-50 translate-middle z-3"
                ></i>
                <span
                  class="most-value-btn blurred"
                  style="font-size: 13px; cursor: pointer"
                >
                  {{ item }}
                </span>
              </span>
              } }
            </div> -->
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
